import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Badge } from "@molecules/bedge/bedge";
import React from "react";
import { useTranslation } from "react-i18next";
import { Summary } from "../../approval-flow-details/summary/summary";
import { useFormSummary } from "../hooks/useFormSummary";
import { FormBox, RowContainer } from "../styled";

export function StepThree() {
  const data = useFormSummary();
  const { t } = useTranslation();
  const context = "components.approvalFlowSummary";
  return (
    <FormBox style={{ width: "50%" }}>
      <Summary title={t(`${context}.basicData.title`)}>
        {data.basicData.map((field, i) => (
          <div key={i}>
            <Typography variant="body4">{field.label}</Typography>
            <Typography variant="body3" weight={600}>
              {field.value}
            </Typography>
          </div>
        ))}
      </Summary>
      <Summary title={t(`${context}.criteria.title`)}>
        {data.criteria.map((field, i) => (
          <React.Fragment key={i}>
            {i > 0 && (
              <Typography variant="body3" weight={600}>
                e
              </Typography>
            )}
            <RowContainer>
              <Badge value={`${field.number}`} />
              <Typography variant="body3" weight={600}>
                {field.criteria}
              </Typography>
            </RowContainer>
          </React.Fragment>
        ))}
      </Summary>
      <Summary title={t(`${context}.condition.title`)}>
        <Typography variant="body3" weight={700}>
          {data.approvalType}
        </Typography>
        {data.conditions.map((field, i) => (
          <RowContainer key={i}>
            <Badge value={`${field.number}`} />
            <Typography variant="body3" weight={600}>
              {field.condition}
            </Typography>
          </RowContainer>
        ))}
      </Summary>
    </FormBox>
  );
}
