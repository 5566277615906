import { trpc } from "@client";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Radio } from "@mui/material";
import { useBillFormContext } from "@organisms/bill-form/hook/useBillFormContext";
import { PaymentMethod } from "@organisms/bill-form/hook/useBillSchema";
import { Transaction } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { PaymentMethodSelect } from "@organisms/payment-method-select";
import { TransactionItem } from "@organisms/transaction-item";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, Container, RadioBox, RadioContainer, WarningBox } from "./styled";

export function BillFormPaymentMethod() {
  const {
    methods: {
      watch,
      setValue,
      control,
      formState: { errors },
    },
  } = useBillFormContext();
  const vendor = watch("vendorId");
  const { data, refetch } = trpc.vendor.get.useQuery(vendor || "", {
    enabled: false,
  });
  const { t } = useTranslation();

  const context = "components.billFormPaymentMethod";

  const payment = watch("payment");
  const amount = watch("amount");
  const createdAt = watch("issuedAt");

  const [payed, setPayed] = useState(!!payment?.transactionId);

  useEffect(() => {
    refetch();
  }, [vendor, refetch]);

  const onSelect = (id: string) => {
    const payment = data?.bankData?.find((item) => item.id === id);
    if (payment && payment.id) {
      setValue("payment", payment, { shouldValidate: true });
      setValue("payment.cardNumberLastFour", payment.cardNumber);
    }
  };

  const isFlashCard = payment?.paymentMethod === PaymentMethod.FLASH_CARD;
  console.log(errors);

  const erro = errors.payment?.paymentMethod;

  return (
    <Container>
      <Box>
        <Typography variant="headline8">{t(`${context}.title`)}</Typography>
        <Typography variant="body4">{t(`${context}.subtitle`)}</Typography>
      </Box>
      <Box>
        <Typography variant="body3" weight={700}>
          {t(`${context}.wasPaid`)}
        </Typography>
        <RadioContainer>
          <RadioBox>
            <Radio
              checked={payed}
              onClick={() => setPayed(true)}
              disabled={isFlashCard}
            />
            <Typography variant="body3">{t(`${context}.yes`)}</Typography>
          </RadioBox>
          <RadioBox>
            <Radio
              checked={!payed}
              value={"PAID"}
              onClick={() => setPayed(false)}
              disabled={isFlashCard}
            />
            <Typography variant="body3">{t(`${context}.no`)}</Typography>
          </RadioBox>
        </RadioContainer>
      </Box>
      <Typography variant="body3" weight={700}>
        {t(`${context}.${payed ? "wasPaidBy" : "willBePaidBy"}`)}
      </Typography>
      {erro && <Typography variant="body4">Obrigatório!</Typography>}
      {!vendor && !isFlashCard && (
        <WarningBox>
          <ShapeIcon
            name="IconInfoCircle"
            variant="info"
            size={48}
            stroke="info"
          ></ShapeIcon>
          <Typography variant="body3">{t(`${context}.warning`)}</Typography>
        </WarningBox>
      )}
      {data && !isFlashCard && (
        <Controller
          control={control}
          name="payment.id"
          defaultValue=""
          render={({ field: { value } }) => (
            <PaymentMethodSelect
              onSelect={onSelect}
              paymentMethods={data.bankData}
              selected={value}
              payed={payed}
              isError={!!erro}
            />
          )}
        />
      )}

      {payment?.transactionId && (
        <TransactionItem
          isFlashCard={isFlashCard}
          transaction={
            {
              cardLastDigits: payment?.cardNumberLastFour,
              description: payment?.description,
              amount: parseInt(amount?.replace(/[,.]/, "")),
              createdAt: createdAt?.toString(),
            } as Transaction
          }
        />
      )}
    </Container>
  );
}
