import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { Navigate, Outlet } from "react-router-dom";
export function RoutesGuard({
  children,
  permission,
}: {
  children?: JSX.Element;
  permission: PayablesPermissions;
}) {
  const { checkPermission } = usePlatformPermissions();

  if (checkPermission(permission)) {
    return children ? children : <Outlet />;
  }

  return <Navigate to={"/home"} />;
}
