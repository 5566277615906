import { useTracking } from "@common/hooks/useTracking";
import { OnboardingBanner } from "@containers/onboarding/onboardAlert/onboardingBanner";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { BasePage } from "@organisms/BasePage/BasePage";
import { SelectedRowsProvider } from "@organisms/select-rows-context";
import { VendorActionModalProvider } from "@organisms/vendor-action-modal/vendorModalContext";
import { VendorGrid } from "@organisms/vendor-grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PageVendorList = () => {
  const { t } = useTranslation();
  const pageContext = "pages.vendorList";
  const navigate = useNavigate();
  const event = useTracking();

  event("vendor_screen");

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.title`),
        subTitle: t(`${pageContext}.subtitle`),
        action: (
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              event("vendor_add_clicked");
              navigate("form");
            }}
          >
            {t(`${pageContext}.headerAction`)}
          </Button>
        ),
      }}
      children={
        <VendorActionModalProvider>
          <OnboardingBanner />
          <SelectedRowsProvider>
            <VendorGrid />
          </SelectedRowsProvider>
        </VendorActionModalProvider>
      }
    />
  );
};

export default PageVendorList;
