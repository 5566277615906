import { trpc } from "@client";
import { useDebounce } from "@uidotdev/usehooks";
import { dispatchToast } from "@utils/dispatchToast";
import { useState } from "react";

export default function useSearchEmployee() {
  const [search, setSearch] = useState<string>("");

  const debouncedSearch = useDebounce(search, 1000);

  const { data, isFetching } = trpc.employee.search.useQuery(debouncedSearch, {
    retry: false,
    keepPreviousData: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: "all",
    enabled: !!debouncedSearch,
    onError: (error: any) => {
      dispatchToast({
        type: "error",
        content: error.message,
      });
    },
  });

  const options = data?.map((_) => ({
    value: _.id,
    label: _.name,
    caption: _.email || "",
  }));

  return {
    searchEmployee: setSearch,
    loading: isFetching,
    options,
  };
}
