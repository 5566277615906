import { useVendorData } from "@organisms/vendor-grid/hooks/useVendorData";
import { useDebounce } from "@uidotdev/usehooks";
import { formatDocumentNumber } from "@utils/maskData";
import { useState } from "react";

export default function useSearchVendor(getAll = false) {
  const [search, setSearch] = useState<string>();

  const debouncedSearch = useDebounce(search, 500);

  const { data, isFetching } = useVendorData(
    { text: debouncedSearch },
    getAll || !!debouncedSearch
  );

  const options = data?.map((_) => ({
    value: _.id,
    label: `${_.name || _.legalName}`,
    caption: formatDocumentNumber(_.registrationNumber),
  }));

  return {
    searchVendor: setSearch,
    loading: isFetching,
    options,
  };
}
