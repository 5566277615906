import { Button, LinkButton, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import {
  ButtonContainer,
  Container,
  ContentContainer,
  StyledDivider,
  StyledIconError,
  StyledTypography,
  SubTitle,
  TypographyWarning,
} from "./styled";

type AlertModalProps = {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  loading: boolean;
  confirmLabel: string;
  title: string;
  subtitle: string;
};

export function AlertModal({
  open,
  onClose,
  onConfirm,
  loading,
  confirmLabel,
  title,
  subtitle,
}: AlertModalProps) {
  const { t } = useTranslation();
  const context = "components.alertModal";

  return (
    <Modal.Root open={open} onClose={onClose}>
      <Container>
        <ContentContainer>
          <>
            <StyledIconError
              name="IconAlertCircle"
              variant="error"
              fill="transparent"
            />
            <TypographyWarning variant="body3">
              {t(`${context}.attention`)}
            </TypographyWarning>
          </>
          <StyledTypography variant="headline6">{title}</StyledTypography>
          <SubTitle variant="body3">{subtitle}</SubTitle>
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={onClose}>
              {t(`${context}.cancel`)}
            </LinkButton>
          </div>
          <Button
            loading={loading}
            size="large"
            buttonType="primary"
            variantType="error"
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal.Root>
  );
}
