import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useApprovalFlowActionModal } from "../../approval-flow-action-modal/approvalModalContext";

export function useDeleteApprovalFlow() {
  const { closeModal } = useApprovalFlowActionModal();
  const { t } = useTranslation();
  const context = "components.approvalFlowActionModal";
  const { mutate } = trpc.approvalFlow.deleteApprovalFlow.delete.useMutation({
    onSuccess: () => {
      closeModal();
      dispatchToast({
        type: "success",
        content: t(`${context}.delete.success`),
      });
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t(`${context}.delete.error`),
        description: t(`${context}.tryAgain`),
      });
      closeModal();
    },
  });

  return { deleteApprovalFlow: mutate };
}
