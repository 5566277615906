import { LinkButton, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useBillActionModal } from "./billModalContext";
import { ConfirmButton } from "./confirmButtons";
import { HeaderIcon } from "./headerIcon";
import { RejectReason } from "./rejectReason";
import {
  ButtonContainer,
  Container,
  ContentContainer,
  StyledDivider,
  StyledTypography,
  SubTitle,
} from "./styled";

export function BillActionModal() {
  const { t } = useTranslation();
  const { closeModal, billActionModal } = useBillActionModal();
  const context = "components.billActionModal";

  const { type, open, quantity } = billActionModal;

  const title =
    quantity && quantity > 1
      ? `${t(`${context}.${type}.batchTitle`).replace(
          "$",
          quantity.toString()
        )}`
      : t(`${context}.${type}.title`);

  return (
    <Modal.Root open={open} onClose={closeModal}>
      <Container>
        <ContentContainer>
          <HeaderIcon />
          <StyledTypography variant="headline6">{title}</StyledTypography>
          <SubTitle variant="body3">
            {t(`${context}.${type}.subtitle`)}
          </SubTitle>
          <RejectReason />
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={closeModal}>
              {t(`${context}.cancel`)}
            </LinkButton>
          </div>
          <ConfirmButton />
        </ButtonContainer>
      </Container>
    </Modal.Root>
  );
}
