import { useTranslation } from "react-i18next";
import { useBillActionModal } from "./billModalContext";
import {
  StyledIcon,
  StyledIconError,
  TypographyPrimary,
  TypographyWarning,
} from "./styled";

export function HeaderIcon() {
  const { t } = useTranslation();
  const { billActionModal } = useBillActionModal();
  const context = "components.billActionModal";

  const { type } = billActionModal;
  switch (type) {
    case "delete":
    case "reject":
    case "approvalReject":
    case "reclassification":
      return (
        <>
          <StyledIconError
            name="IconAlertCircle"
            variant="error"
            fill="transparent"
          />
          <TypographyWarning variant="body3">
            {t(`${context}.attention`)}
          </TypographyWarning>
        </>
      );
    case "account":
    case "approve":
      return (
        <>
          <StyledIcon name="IconThumbUp" variant="default" fill="transparent" />

          <TypographyPrimary variant="body3">
            {t(`${context}.attention`)}
          </TypographyPrimary>
        </>
      );
  }
}
