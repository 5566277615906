import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const IconDots = styled(IconButton).attrs({
  icon: "IconDots",
  size: "small",
  variant: "line",
})`
  transform: rotate(90deg);
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
