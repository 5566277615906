import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacings.xs2};
`;

export const HeaderContainer = styled(Container)`
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const StyledTag = styled(Tag)`
  cursor: auto;
  pointer-events: none;
`;
