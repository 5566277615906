import { Tag } from "@molecules/tag";
import { BillStatus } from "@organisms/bills-list/hooks/useBillsRows";

type StatusTagProps = {
  status: BillStatus;
};

export function StatusTag({ status }: StatusTagProps) {
  switch (status) {
    case "OPEN":
      return (
        <Tag hasLeftDotIcon={true} variant={"primary"}>
          {"Aberto"}
        </Tag>
      );
    case "PENDING":
      return (
        <Tag hasLeftDotIcon={true} variant={"blue"}>
          {"Contabilização pendente"}
        </Tag>
      );
    case "PAID":
      return (
        <Tag hasLeftDotIcon={true} variant={"success"}>
          {"Contabilizado"}
        </Tag>
      );
    case "REJECTED":
      return (
        <Tag hasLeftDotIcon={true} variant={"error"}>
          {"Reprovado"}
        </Tag>
      );
    case "ERROR_SUBMITTING_APPROVAL":
      return (
        <Tag hasLeftDotIcon={true} variant={"error"}>
          {"Erro ao submeter"}
        </Tag>
      );
    case "CANCELED":
      return (
        <Tag hasLeftDotIcon={true} variant={"error"}>
          {"Cancelado"}
        </Tag>
      );
    case "PROCESSING_APPROVAL":
      return (
        <Tag hasLeftDotIcon={true} variant={"blue"}>
          {"Processando aprovação"}
        </Tag>
      );
    case "AWAIT_APPROVAL":
      return (
        <Tag hasLeftDotIcon={true} variant={"blue"}>
          {"Aprovação pendente"}
        </Tag>
      );
    default:
      return (
        <Tag hasLeftDotIcon={true} variant={"primary"}>
          {"Aberto"}
        </Tag>
      );
  }
}
