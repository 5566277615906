import { ThemesType, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { BillStatus } from "@organisms/bills-list/hooks/useBillsRows";
import styled from "styled-components";

export const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
`;

export const StyledTableTextSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const BadgeVarianteColor: Record<string, ThemesType["colors"]> = {
  OPEN: "primary",
  PENDING: "info40",
  PAID: "success40",
  REJECTED: "error40",
  PROCESSING_APPROVAL: "info40",
  ERROR_SUBMITTING_APPROVAL: "error40",
  AWAIT_APPROVAL: "info40",
  CANCELED: "error40",
};

const BadgeSecondaryVarianteColor: Record<string, ThemesType["colors"]> = {
  OPEN: "secondary90",
  PENDING: "info90",
  PAID: "success90",
  REJECTED: "error90",
  PROCESSING_APPROVAL: "info90",
  ERROR_SUBMITTING_APPROVAL: "error90",
  AWAIT_APPROVAL: "info90",
  CANCELED: "error90",
};

type VariantColor = BillStatus;

export const StyledBadgeText = styled(Typography)<{
  variantColor?: VariantColor;
}>`
  color: ${({ theme, variantColor }) =>
    variantColor
      ? theme.colors[BadgeVarianteColor[variantColor]]
      : theme.colors.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
`;

export const StyledBadge = styled.div<{
  variantColor?: VariantColor;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacings.m};
  min-width: ${({ theme }) => theme.spacings.m};
  height: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme, variantColor }) =>
    variantColor
      ? theme.colors[BadgeSecondaryVarianteColor[variantColor]]
      : theme.palette.secondary.light};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  row-gap: ${({ theme }) => theme.spacings.s};
  min-width: 250px;
`;
