import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";

export default function useSearchEmployee() {
  const { data, isFetching } = trpc.approvalFlow.approvers.get.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        dispatchToast({
          type: "error",
          content: error.message,
        });
      },
    }
  );

  const options = data?.value.records.map((_) => ({
    value: _.id,
    label: _.name,
    caption: _.email || "",
  }));

  const loadOption = [
    {
      label: "Buscando aprovadores...",
    },
  ];

  return {
    options: isFetching ? loadOption : options,
  };
}
