import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: ${({ theme }) => `${theme.borders.width.xs} solid`};
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`;
export const BaseGrid = styled.div<{
  gridTemplate: string;
}>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const ApproverBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs2} 0;
`;

export const ApproverRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GridPadding = styled(BaseGrid)`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const StyledTypography = styled(Typography)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  border-bottom: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const DataBox = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const StyledIcon = styled(Icons)`
  background-color: transparent !important;
`;
