import { useTracking } from "@common/hooks/useTracking";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { NotificationCard } from "@molecules/notification-card/notification-card";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingModal } from "../onboarding-modal/onboardingModal";

export function OnboardingBanner() {
  const { t } = useTranslation();
  const context = "components.newsBanner";
  const [modalOpen, setModalOpen] = useState(false);
  const event = useTracking();

  useEffect(() => {
    event("Payables_news_banner_viewed");
  }, []);

  return (
    <>
      <OnboardingModal open={modalOpen} onClose={() => setModalOpen(false)} />
      <NotificationCard
        Title={t(`${context}.notification.title`)}
        content={
          <div style={{ display: "flex", gap: "6px", flexWrap: "wrap" }}>
            {`${t(`${context}.notification.content`)}`}
            <LinkButton
              variant="primary"
              onClick={() => {
                event("payables_onboard_clicked");
                setModalOpen(true);
              }}
            >
              {t(`${context}.clickHere`)}
            </LinkButton>
          </div>
        }
        iconName="IconFolder"
      />
    </>
  );
}
