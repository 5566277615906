import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { ActionProps } from "@organisms/grid-select-options/gridSelectOptions";
import { useSelectedRowsContext } from "@organisms/select-rows-context";
import { useTranslation } from "react-i18next";
import { useApprovalBatch } from "./useApprovalBatch";

export function useGridSelectItems() {
  const { approvalBatch } = useApprovalBatch();
  const { setBillActionModal } = useBillActionModal();
  const { selectedRows } = useSelectedRowsContext();
  const { t } = useTranslation();
  const context = "components.approvalsList";
  const gridSelectActions: ActionProps[] = [
    {
      iconName: "IconThumbUp",
      label: t(`${context}.actions.aprove`),
      onClick: () => {
        setBillActionModal({
          open: true,
          quantity: selectedRows.length,
          onConfirm: () => {
            approvalBatch(
              selectedRows.map((id) => {
                return { id, action: "APPROVED" };
              })
            );
          },
          type: "approve",
        });
      },
    },
    {
      iconName: "IconThumbDown",
      label: t(`${context}.actions.reject`),
      onClick: () => {
        setBillActionModal({
          open: true,
          quantity: selectedRows.length,
          onConfirm: (comment) => {
            approvalBatch(
              selectedRows.map((id) => {
                return { id, action: "REJECTED", comment };
              })
            );
          },
          type: "approvalReject",
        });
      },
    },
  ];

  return gridSelectActions;
}
