import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";
import { useDebounce } from "@uidotdev/usehooks";
import React from "react";

type Props = { disable: boolean };

export default function useSearchFilter(props: Props) {
  const [search, setAppliedFilter] = React.useState<string | undefined>(
    undefined
  );
  const debounceSearch = useDebounce(search, 500);

  const SearchFilterComponent = (
    <SearchField
      label="Buscar despesa"
      disabled={props.disable}
      onInputChange={(_, value) => setAppliedFilter(value)}
    />
  );

  return {
    SearchFilterComponent,
    search: debounceSearch,
  };
}
