import { trpc } from "@client";
import { ApprovalFlow } from "server/src/services/approval-flow-service/approval-flow.types";
import { ApprovalFlowActionModalProvider } from "../../approval-flow-action-modal/approvalModalContext";
import { ApprovalFlowActions } from "../../approval-flow-actions/approvalFlowActions";
import { ToggleApprovalFlow } from "../../toggle-approval-flow/toggleApprovalFlow";

export type ApprovalFlowGrid = ApprovalFlow & {
  actions: React.ReactNode;
  statusToggle: React.ReactNode;
};
export function useApprovalFlow() {
  const { data, isFetching } = trpc.approvalFlow.getApprovalFlows.get.useQuery(
    {
      limit: 100,
      page: 1,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const rows: ApprovalFlowGrid[] | undefined = data?.map((approvalFlow) => {
    return {
      ...approvalFlow,
      statusToggle: (
        <ApprovalFlowActionModalProvider>
          <ToggleApprovalFlow
            id={approvalFlow.id}
            status={approvalFlow.status}
          />
        </ApprovalFlowActionModalProvider>
      ),
      actions: (
        <ApprovalFlowActionModalProvider>
          <ApprovalFlowActions
            id={approvalFlow.id}
            status={approvalFlow.status}
            type="grid"
          />
        </ApprovalFlowActionModalProvider>
      ),
    };
  });

  return {
    rows,
    isLoading: isFetching,
  };
}
