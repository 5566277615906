import { GridSelectOptions } from "@organisms/grid-select-options/gridSelectOptions";
import { useGridSelectItems } from "./hooks/useGridSelectionItems";
type ApprovalsGridSelectOptionsProps = {
  ids: string[];
};
export function ApprovalsGridSelectOptions({
  ids,
}: ApprovalsGridSelectOptionsProps) {
  const actions = useGridSelectItems();
  return <GridSelectOptions ids={ids} actions={actions} />;
}
