import { trpc } from "@client";

export default function useUserCards() {
  const { data, isFetching } = trpc.expenseGateway.userCards.useQuery();

  const options = data?.map((_) => ({
    value: _._id,
    label: `—${_.cardNumber}`,
    caption: `Corporativo`,
    cardNumber: _.cardNumber,
  }));

  return {
    loading: isFetching,
    options,
  };
}
