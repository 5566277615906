import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";

export function useSubmitBill() {
  const { t } = useTranslation();
  const context = "components.billActionModal.toast.submit";
  const { mutate, isLoading } = trpc.bill.submit.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t(`${context}.success.title`),
      });
    },
    onError: () => {
      dispatchToast({
        type: "error",
        title: t(`${context}.error.title`),
        content: t(`${context}.error.message`),
      });
    },
  });

  return {
    isLoading,
    submit: mutate,
  };
}
