import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import { VendorRequest, vendorSchema } from "../vendor-schema";

export function useVendorForm(vendor?: Vendor) {
  const methods = useForm<VendorRequest>({
    resolver: zodResolver(vendorSchema),
    defaultValues: vendor
      ? {
          ...vendor,
          bankData: vendor.bankData,
          attachmentFiles: vendor?.attachments?.map((att) => {
            const initialFile = new File([""], att.fileName);
            Object.defineProperty(initialFile, "size", { value: 0 });
            return { previewUrl: att.presignedURL, file: initialFile };
          }),
        }
      : {
          address: { country: "Brasil" },
          active: true,
          bankData: [],
        },
  });

  return methods;
}
