import { PayablesPermissions } from "@common/permissions.type";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageApprovalFlowDetails } from "../pages/approval-flow-detail";
import PageApprovalFlowForm from "../pages/approval-flow-form";
import { PageBillAccount } from "../pages/bill-account";
import { PageBillDetails } from "../pages/bill-detail";
import { PageBillEdit } from "../pages/bill-edit";
import PageBillForm from "../pages/bill-form";
import { PageVendorDetails } from "../pages/vendor-detail";
import PageVendorForm from "../pages/vendor-form";
import PageVendorList from "../pages/vendors-list";
import { RoutesGuard } from "./routesGuard";

const AppRouter = () => {
  const basePath = "expense/company-payables";
  const baseVendorPath = `${basePath}/vendors`;
  const baseBillPath = `${basePath}/bills`;
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<RoutesGuard permission={PayablesPermissions.VENDOR_VIEW} />}
        >
          <Route path={baseVendorPath} element={<PageVendorList />} />
          <Route path={`${baseVendorPath}/form`} element={<PageVendorForm />} />
          <Route
            path={`${baseVendorPath}/form/:id`}
            element={<PageVendorForm />}
          />
          <Route
            path={`${baseVendorPath}/details/:id`}
            element={<PageVendorDetails />}
          />
        </Route>

        <Route
          element={<RoutesGuard permission={PayablesPermissions.BILL_VIEW} />}
        >
          <Route
            path={`${baseBillPath}/details/:id`}
            element={<PageBillDetails />}
          />
          <Route
            element={
              <RoutesGuard permission={PayablesPermissions.CREATE_BILL} />
            }
          >
            <Route path={`${baseBillPath}/form`} element={<PageBillForm />} />
            <Route
              path={`${baseBillPath}/edit/:id`}
              element={<PageBillEdit />}
            />
          </Route>
        </Route>
        <Route
          path={`expense/company-payables/approval-flow/form`}
          element={<PageApprovalFlowForm />}
        />
        <Route
          path={`expense/company-payables/approval-flow/form/:id`}
          element={<PageApprovalFlowForm />}
        />
        <Route
          path={`${basePath}/approval-flow/details/:id`}
          element={<PageApprovalFlowDetails />}
        />
        <Route
          element={
            <RoutesGuard permission={PayablesPermissions.ACCOUNTING_LIST} />
          }
        >
          <Route
            path={`${basePath}/accounting`}
            element={<PageBillAccount />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
