import { NotificationCard } from "@molecules/notification-card/notification-card";
import { useTransactionRows } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { TransactionList } from "@organisms/transaction-list";
import { useTranslation } from "react-i18next";
import { Container } from "./styled";

export function TransactionsTab() {
  const { isLoading, rows } = useTransactionRows();
  const { t } = useTranslation();
  const context = "components.transactionTab";
  return (
    <Container>
      <NotificationCard
        Title={t(`${context}.notification.title`)}
        content={t(`${context}.notification.content`)}
        iconName="IconSparkles"
      />
      <TransactionList
        rows={rows}
        isLoading={isLoading}
        itemActions="menuAction"
      />
    </Container>
  );
}
