import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { ApprovalFlowGrid } from "@containers/approval-flow/approval-flow-grid";
import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { BillFormConfiguration } from "../bill-form-configuration";
import { TabContainer } from "./styled";

export function ConfigurationTab() {
  const { t } = useTranslation();
  const { checkPermission } = usePlatformPermissions();
  const context = "components.configurationTab";

  function getPayablesTabItens() {
    const items = [];
    if (checkPermission(PayablesPermissions.FORM_CONFIGURATION)) {
      items.push({
        label: t(`${context}.forms`),
        component: (
          <TabContainer>
            <BillFormConfiguration />
          </TabContainer>
        ),
      });
    }
    // TODO: await until the configuration-service get ready
    // if (checkPermission(PayablesPermissions.CATEGORY_CONFIGURATION)) {
    //   items.push({
    //     label: t(`${context}.categories`),
    //     component: (
    //       <TabContainer>
    //         <CategoryConfiguration />
    //       </TabContainer>
    //     ),
    //   });
    // }

    if (checkPermission(PayablesPermissions.APPROVAL_FLOW_CONFIGURATION)) {
      items.push({
        label: t(`${context}.approvalFlow`),
        component: (
          <TabContainer>
            <ApprovalFlowGrid />
          </TabContainer>
        ),
      });
    }
    return items;
  }

  return <Tab tabItens={getPayablesTabItens()} variant="secondary" />;
}
