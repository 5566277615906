import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Badge } from "@molecules/bedge/bedge";
import { useTranslation } from "react-i18next";
import { ApprovalFlow } from "server/src/services/approval-flow-service/approval-flow.types";
import { BaseGrid, RowContainer } from "./styled";
import { useApprovalFlowSummary } from "./summary/hooks/useApprovalFlowSummary";
import { Summary } from "./summary/summary";

export function ApprovalFlowDetailsTab({
  approvalFlow,
}: {
  approvalFlow: ApprovalFlow;
}) {
  const { buildFields } = useApprovalFlowSummary();
  const { t } = useTranslation();
  const context = "components.approvalFlowSummary";

  const fields = buildFields(approvalFlow);

  return (
    <BaseGrid gridTemplate="1fr 1fr">
      <Summary title={t(`${context}.basicData.title`)}>
        {fields.basicData.map((field, i) => (
          <div key={i}>
            <Typography variant="body4">{field.label}</Typography>
            <Typography variant="body3" weight={600}>
              {field.value}
            </Typography>
          </div>
        ))}
      </Summary>
      <Summary title={t(`${context}.criteria.title`)}>
        {fields.criteria.map((field, i) => (
          <>
            {i > 0 && (
              <Typography variant="body3" weight={600}>
                e
              </Typography>
            )}
            <RowContainer key={i}>
              <Badge value={`${field.number}`} />
              <Typography variant="body3" weight={600}>
                {field.criteria}
              </Typography>
            </RowContainer>
          </>
        ))}
      </Summary>
      <Summary title={t(`${context}.condition.title`)}>
        <Typography variant="body3" weight={700}>
          {fields.approvalType}
        </Typography>
        {fields.conditions.map((field, i) => (
          <RowContainer key={i}>
            <Badge value={`${field.number}`} />
            <Typography variant="body3" weight={600}>
              {field.condition}
            </Typography>
          </RowContainer>
        ))}
      </Summary>
    </BaseGrid>
  );
}
