import { BillStatus } from "@organisms/bills-list/hooks/useBillsRows";
import {
  Container,
  StyledBadge,
  StyledBadgeText,
  StyledTableText,
  StyledTableTextSecondary,
} from "./styled";

const resolveBadge = (value: string) => {
  const valueNormalized = value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const initial = valueNormalized.match(/(\b[\w])/g)?.slice(0, 2);
  return initial?.join("").toUpperCase();
};
type VendorColumnProps = {
  name?: string;
  legalName: string;
  code?: string;
  status?: BillStatus;
};

export function VendorColumn({
  name,
  legalName,
  code,
  status,
}: VendorColumnProps) {
  return (
    <Container>
      <StyledBadge variantColor={status}>
        <StyledBadgeText variant="body3" variantColor={status}>
          {resolveBadge(legalName)}
        </StyledBadgeText>
      </StyledBadge>
      <div>
        <StyledTableText variant="body3">
          {code ? name || legalName : legalName}
        </StyledTableText>
        <StyledTableTextSecondary variant="body4">
          {code ? code : name ? legalName : ""}
        </StyledTableTextSecondary>
      </div>
    </Container>
  );
}
