import { DeepPartial } from "@trpc/server";

type Address = {
  street: string;
  number?: number;
  complement?: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  neighborhood: string;
};

type ContactData = {
  name?: string;
  email?: string;
  phone?: string;
};

export enum PaymentMethod {
  BILLET = "BILLET",
  TRANSFER = "TRANSFER",
  FLASH_CARD = "FLASH_CARD",
  CARD = "CARD",
  PIX = "PIX",
}

export enum PixType {
  CPF_CNPJ = "CPF_CNPJ",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  KEY = "KEY",
}

export enum AccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}
export enum Status {
  OPEN = "OPEN",
  PENDING = "PENDING",
  PROCESSING_APPROVAL = "PROCESSING_APPROVAL",
  ERROR_SUBMITTING_APPROVAL = "ERROR_SUBMITTING_APPROVAL",
  AWAIT_APPROVAL = "AWAIT_APPROVAL",
  PAID = "PAID",
  CANCELED = "CANCELED",
  REJECTED = "REJECTED",
}

export type ApprovalFlowType = "AUTO" | "CUSTOM";

export type ApprovalStatus = "PENDING" | "APPROVED" | "REJECTED";

export type ApprovalWorkflow = {
  approvalFlowId: string;
  approvalFlowName: string;
  type: ApprovalFlowType;
  comment?: string;
  approvers?: Approvers[];
};

export type Approvers = {
  level: number;
  approverId: string;
  approverName: string;
  approverEmail: string;
  status: ApprovalStatus;
  updateAt?: string;
};

type BankDataDto = {
  id?: string;
  paymentMethod: PaymentMethod;
  bank?: string;
  accountType?: AccountType;
  agency?: string;
  account?: string;
  pixKey?: string;
  pixType?: PixType;
  favorite: boolean;
  cardId?: string;
  cardNumber?: string;
};

type BankData = Omit<BankDataDto, "id"> & {
  id: string;
};

export type VendorDto = {
  id?: string;
  registrationNumber: string;
  name?: string;
  legalName: string;
  stateSubscription?: string;
  citySubscription?: string;
  address: Address;
  contactData?: ContactData;
  responsibleEmployeeId?: string;
  description?: string;
  bankData?: BankDataDto[];
  defaultPaymentMethod?: string;
  lineOfBusiness: string;
  active: boolean;
  code?: string;
  email?: string;
  phone?: string;
  attachments?: Attachment[];
};
export type Vendor = Omit<VendorDto, "id" | "bankData"> & {
  id: string;
  responsibleEmployee?: ContactData;
  bankData: BankData[];
};
export type VendorFilterDto = {
  status?: "all" | "active" | "inactive";
  lineOfBusiness?: string[];
  country?: string[];
  text?: string;
};

type Document = {
  type: string;
  number?: string;
  amount: number;
  s3Path?: string;
  presignedURL?: string;
};

type PaymentTerms = {
  paymentMethod: PaymentMethod;
  paymentId: string;
  bank?: string;
  accountType?: AccountType;
  agency?: string;
  account?: string;
  pixKey?: string;
  pixType?: PixType;
  cardId?: string;
};

export type Attachment = {
  fileName: string;
  s3Path: string;
  presignedURL?: string;
};

type Transaction = {
  id?: string;
  description?: string;
  cardNumberLastFour?: string;
};

export type BillCreateDto = {
  description?: string;
  amount: number;
  document: Document;
  paymentTerms?: PaymentTerms;
  attachments?: Attachment[];
  comments?: string;
  issuedAt?: string;
  dueAt?: string;
  paidAt?: string;
  vendorId: string;
  categoryId?: string;
  costCenterId?: string;
  currency?: string;
  transaction?: Transaction;
};

export type BillUpdateDto = DeepPartial<BillCreateDto> & {
  id: string;
};

export type BillListDto = {
  bills: Bill[];
  total: number;
};

export type Bill = BillCreateDto & {
  id: string;
  companyId: string;
  status: Status;
  approvalWorkflow?: ApprovalWorkflow;
  createdBy: string;
  vendor: {
    id: string;
    code: string;
    legalName: string;
  };
};

export type SummaryContent = {
  qty: number;
  total: number;
};
export type Summary = {
  open: SummaryContent;
  canceled: SummaryContent;
  pending: SummaryContent;
  paid: SummaryContent;
};

export type BillPayDto = {
  paidAmount: number;
};
export type BillCreateResponse = Pick<Bill, "id" | "status" | "amount">;

type BillSettings = {
  description: string;
  status: "ACTIVE" | "INACTIVE";
  rules: {
    property: string;
    required: boolean;
    visible: boolean;
  }[];
};

export type CompanySettings = {
  id: string;
  description: string;
  settings: {
    billValidation: BillSettings;
  };
};

export type CompanySettingsRequest = Omit<CompanySettings, "id"> & {
  id?: string;
};

export type ApprovalDTO = {
  action: "APPROVED" | "REJECTED";
  comment?: string;
};

export type ApprovalBatchDTO = ApprovalDTO & {
  id: string;
};
