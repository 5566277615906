import { trpc } from "@client";
import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import {
  Button,
  Icons,
  Menu,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouterOutputs } from "server/src/router";
import { MenuItemContainer } from "../styled";

export type GetStatementOutput =
  RouterOutputs["expense"]["employeeTransactions"];
export type Transaction = GetStatementOutput["transactions"][number];
export function useTransactionRows(transaction?: Transaction) {
  const [selected, setSelected] = useState<Transaction>();
  const navigate = useNavigate();
  const { data: fetchedData, isLoading } =
    trpc.expense.employeeTransactions.useQuery(undefined, {
      refetchOnWindowFocus: false,
      enabled: !transaction,
    });
  const { t } = useTranslation();
  const { checkPermission } = usePlatformPermissions();
  const context = "components.transactionList";

  const createRow = (transaction: Transaction) => {
    const options = [];

    if (checkPermission(PayablesPermissions.CREATE_BILL)) {
      options.push({
        children: (
          <MenuItemContainer>
            <Icons name="IconPackage" fill="transparent" />
            <Typography variant="body3">
              {t(`${context}.rowAction.addCompanyPayables`)}
            </Typography>
          </MenuItemContainer>
        ),
        onClick: () => {
          navigate("expense/company-payables/bills/form", {
            state: transaction,
          });
        },
      });
    }

    if (transaction.expenseId) {
      options.push({
        children: (
          <MenuItemContainer>
            <Icons name="IconReceipt2" fill="transparent" />
            <Typography variant="body3">
              {t(`${context}.rowAction.addCorporateExpense`)}
            </Typography>
          </MenuItemContainer>
        ),
        onClick: () => {
          navigate(
            `/expenseManagement/expenses/register/${transaction.expenseId}`,
            {
              state: transaction,
            }
          );
        },
      });
    }

    return {
      description: transaction.description,
      value: transaction.amount,
      date: transaction.createdAt,
      card: transaction.cardLastDigits,
      actions: (
        <Menu options={options.reverse()} type="default">
          <Button size="medium" variant="primary">
            {t(`${context}.rowAction.button`)}
          </Button>
        </Menu>
      ),
      select: (
        <Radio
          size="small"
          onChange={() => {
            setSelected(transaction);
          }}
          checked={selected?._id === transaction._id}
        />
      ),
    };
  };

  return {
    selected: selected || transaction,
    isLoading,
    rows: fetchedData?.transactions?.map(createRow),
  };
}
