import {
  DatePicker,
  Dropzone,
  Icons,
  SelectField,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { DatePickerProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/DatePicker/types";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs2};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const PaddingBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const FieldContainer = styled(Box)<{ visible: boolean }>`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  background-color: ${({ theme, visible }) =>
    !visible && theme.colors.neutral[95]};
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  width: 100%;
`;

export const ContainerColumn = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
`;

export const OpacityContainer = styled(Container)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? "1" : "0.50")};
`;

export const StyledIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;
export const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

export const StyledSelectField = styled(SelectField)`
  width: 100%;
`;
export const StyledDropzone = styled(Dropzone)`
  pointer-events: none;
`;
