import { trpc } from "@client";
import {
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { formatDocumentNumber } from "@utils/maskData";
import { useEffect, useState } from "react";
import useSearchVendor from "./hooks/useSearchVendor";

export const SelectVendor = (props: SelectFieldProps) => {
  const { loading, options, searchVendor } = useSearchVendor(true);
  const [selectedVendor, setSelectedVendor] =
    useState<{ value: string; label: string; caption: string }[]>();
  const { data } = trpc.vendor.get.useQuery(props.value, {
    enabled: !!props.value && !selectedVendor,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setSelectedVendor([
        {
          value: data.id,
          label: `${data.name || data.legalName}`,
          caption: formatDocumentNumber(data.registrationNumber),
        },
      ]);
    }
  }, [data]);

  return (
    <SelectField
      {...props}
      searchable
      options={options || selectedVendor}
      onChange={(e) => searchVendor(e.target.value)}
      inputMode="text"
      noOptionsText={loading ? "Buscando..." : undefined}
      filterOptions={(options) => options}
    />
  );
};
