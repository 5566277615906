import TableClient from "@organisms/TableClient";
import { BillActionModal } from "@organisms/bill-action-modal/billActionModal";
import { ApprovalsGridSelectOptions } from "./approvalsGridSelectOptions";
import { useApprovalColumns } from "./hooks/useApprovalColumns";
import { UseApprovalRows } from "./hooks/useApprovalRow";
import { useExportList } from "./hooks/useExport";
import { useFilters } from "./hooks/useFilters";
import useSearchFilter from "./hooks/useSearchFilter";
import { GridContainer } from "./styled";

export function Approvals() {
  const { isLoading, rows, ids } = UseApprovalRows();
  const { columns } = useApprovalColumns(ids);
  const { SearchFilterComponent, search } = useSearchFilter();
  const { filtersComponents, formattedFilter } = useFilters();
  const { columnsLabel, resolver } = useExportList();

  return (
    <>
      <BillActionModal />
      <GridContainer>
        <TableClient
          columns={columns}
          data={rows || []}
          emptyState={{ emptyText: "", filteredEmptyText: "" }}
          loading={isLoading}
          enablePagination
          searchFilter={search}
          TableSearchFilter={SearchFilterComponent}
          TableFilters={filtersComponents}
          filter={formattedFilter}
          selectOption={<ApprovalsGridSelectOptions ids={ids} />}
          downloadOptions={{
            fileName: "despesas-com-fornecedor",
            columns: columnsLabel,
            resolver: (rows) => {
              return resolver(rows.map((_) => _.original));
            },
          }}
        />
      </GridContainer>
    </>
  );
}
