import { trpc } from "@client";
import { Skeleton } from "@mui/material";
import { BasePage } from "@organisms/BasePage/BasePage";
import { VendorForm } from "@organisms/vendor-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const PageVendorForm = () => {
  const { t } = useTranslation();
  const pageContext = "pages.vendorForm";
  const { id } = useParams();
  const { data, isLoading } = trpc.vendor.get.useQuery(id as string, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.title`),
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.vendors`),
            to: "/expense/company-payables/vendors",
          },
          {
            label: t(`${pageContext}.breadCrumbs.addVendor`),
          },
        ],
      }}
      children={
        isLoading && id ? (
          <Skeleton width={"100%"} height={"85px"} />
        ) : (
          <VendorForm vendor={data} />
        )
      }
      hasFooter
    />
  );
};

export default PageVendorForm;
