import { trpc } from "@client";

export function useCostCenterData(id?: string, search?: string) {
  const { data: costCenterList, isRefetching: loadingList } =
    trpc.costCenter.getAByCompany.useQuery(search, {
      enabled: !id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const { data: costCenter, isRefetching: loadingCostCenter } =
    trpc.costCenter.getById.useQuery(id || "", {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const data = id ? [costCenter] : costCenterList?.costCenters;
  const loading = id ? loadingCostCenter : loadingList;

  return {
    loading,
    data,
  };
}
