import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TagStyledProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Tag/styled";
import { FlexContainer, Label, StyledTag } from "./styled";

export type FieldProps = {
  label: string | undefined;
  value: string | number | undefined;
  tagProps?: {
    variant: TagStyledProps["variant"];
  };
  expand?: boolean;
  visible?: boolean;
};
export function DataDisplay({ label, value, tagProps, expand }: FieldProps) {
  return (
    <FlexContainer expand={expand}>
      <Label variant="body4" weight={600}>
        {label}
      </Label>
      {tagProps ? (
        <StyledTag hasLeftDotIcon={true} variant={tagProps.variant}>
          {value}
        </StyledTag>
      ) : (
        <Typography variant="body3" weight={600}>
          {value}
        </Typography>
      )}
    </FlexContainer>
  );
}
