import {
  Button,
  Icons,
  LinkButton,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useApprovalFlowActionModal } from "./approvalModalContext";
import {
  ButtonContainer,
  Container,
  ContentContainer,
  StyledDivider,
  StyledIconError,
  StyledTypography,
  SubTitle,
  TypographyWarning,
} from "./styled";

export function ApprovalFlowActionModal() {
  const { t } = useTranslation();
  const { closeModal, billActionModal, setBillActionModal } =
    useApprovalFlowActionModal();
  const context = "components.approvalFlowActionModal";

  const { type, onConfirm: confirm, open, loading } = billActionModal;

  const onConfirm = () => {
    setBillActionModal((billActionModal) => ({
      ...billActionModal,
      loading: true,
    }));
    confirm && confirm();
  };

  const ConfirmButton = () => {
    switch (type) {
      case "inactive":
        return (
          <Button
            loading={loading}
            size="large"
            buttonType="primary"
            variantType="error"
            onClick={() => onConfirm()}
          >
            {t(`${context}.${type}.confirm`)}
          </Button>
        );
      case "delete":
        return (
          <Button
            loading={loading}
            size="large"
            buttonType="primary"
            variantType="error"
            onClick={() => onConfirm()}
          >
            {t(`${context}.${type}.confirm`)}
            <Icons name="IconTrash" fill="transparent" />
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <Modal.Root open={open} onClose={closeModal}>
      <Container>
        <ContentContainer>
          <StyledIconError
            name="IconAlertCircle"
            variant="error"
            fill="transparent"
          />
          <TypographyWarning variant="body3">
            {t(`${context}.attention`)}
          </TypographyWarning>
          <StyledTypography variant="headline6">
            {t(`${context}.${type}.title`)}
          </StyledTypography>
          <SubTitle variant="body3">
            {t(`${context}.${type}.subtitle`)}
          </SubTitle>
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={closeModal}>
              {t(`${context}.cancel`)}
            </LinkButton>
          </div>
          <ConfirmButton />
        </ButtonContainer>
      </Container>
    </Modal.Root>
  );
}
