import { transformToRecord } from "@utils/dataRecord";

export const paymentMethod = [
  { value: "BILLET", label: "Boleto" },
  { value: "FLASH_CARD", label: "Cartão Corporativo Flash" },
  { value: "CARD", label: "Cartão" },
  { value: "TRANSFER", label: "Transferência/TED/DOC" },
  { value: "PIX", label: "Chave Pix" },
];

export const SelectPaymentMethod = [
  { value: "BILLET", label: "Boleto" },
  { value: "CARD", label: "Cartão" },
  { value: "TRANSFER", label: "Transferência/TED/DOC" },
  { value: "PIX", label: "Chave Pix" },
];

export const paymentMethodRecord = transformToRecord(paymentMethod);
