import { Menu, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { useCallback } from "react";
import styled from "styled-components";
import { utils, writeFile } from "xlsx";

type GridDownloadProps<T> = {
  name: string;
  columns: string[];
  data: T[];
  transformer(value: T[]): string[][];
};
export function GridDownload<T>({
  data,
  name,
  columns,
  transformer,
}: GridDownloadProps<T>) {
  const values: any[] = [[...columns]];
  values.push(...transformer(data));

  const generateFile = useCallback(
    (fileName: string) => {
      const arr = fileName.split(".");
      const extension = arr.pop();

      if (extension === "xlsx" || extension === "xls") {
        const wb = utils.book_new();
        const ws = utils.aoa_to_sheet(values);

        utils.book_append_sheet(wb, ws, fileName);

        writeFile(wb, fileName);
      }
      if (extension === "csv" || extension === "txt") {
        const fileBody = values?.map((v) => v?.join("\t"))?.join("\n");
        const blob = new Blob([fileBody], {
          type: `text/${extension};charset=utf-8`,
        });
        const creatDocument = document.createElement("a");

        creatDocument.href = window.URL.createObjectURL(blob);
        creatDocument.download = fileName;
        creatDocument.click();
      }
    },
    [data]
  );

  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      options={[
        {
          label: "Exportar em CSV",
          onClick: () => {
            generateFile(`${name}.csv`);
          },
        },
        {
          label: "Exportar em XLS",
          onClick: () => {
            generateFile(`${name}.xls`);
          },
        },
      ]}
    >
      <StyledIcon
        name="IconDownload"
        variant="neutral"
        size={40}
        stroke="neutral"
      />
    </Menu>
  );
}

export const StyledIcon = styled(ShapeIcon)`
  background-color: #fff;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  :hover {
    cursor: pointer;
  }
`;
