import { usePlanFeatures } from "@common/hooks/usePlanFeatures";
import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { useAccountBill } from "@organisms/bills-list/hooks/useAccountBill";
import { useRejectBill } from "@organisms/bills-list/hooks/useRejectBill";
import { GridActions } from "@organisms/grid-actions";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import { Container } from "./styled";

type AccountingGridAction = {
  bill: Bill;
};
export function AccountGridAction({ bill }: AccountingGridAction) {
  const navigate = useNavigate();
  const { setBillActionModal } = useBillActionModal();
  const { pay } = useAccountBill();
  const { rejectBill } = useRejectBill();
  const { t } = useTranslation();
  const { checkPermission } = usePlatformPermissions();
  const { isBasicPlan } = usePlanFeatures();

  const context = "components.billGridActions";

  switch (bill.status) {
    case "OPEN":
    case "PAID":
    case "REJECTED":
      return (
        <Container>
          <Button
            size="small"
            buttonType="secondary"
            variantType="neutral"
            onClick={() =>
              navigate(`/expense/company-payables/bills/details/${bill.id}`)
            }
          >
            {t(`${context}.details`)}
          </Button>
        </Container>
      );
    case "PENDING":
      return (
        <Container>
          {checkPermission(PayablesPermissions.ACCOUNT_BILL) && (
            <>
              <Button
                size="small"
                buttonType="secondary"
                variantType="success"
                onClick={() => {
                  setBillActionModal({
                    open: true,
                    type: "account",
                    onConfirm: () => {
                      pay({ id: bill.id, amount: bill.amount });
                    },
                  });
                }}
              >
                {t(`${context}.account`)}
              </Button>
              {isBasicPlan() && (
                <Button
                  size="small"
                  buttonType="secondary"
                  variantType="error"
                  onClick={() =>
                    setBillActionModal({
                      open: true,
                      type: "reject",
                      onConfirm: (value) => {
                        if (value) {
                          rejectBill({ id: bill.id, comment: value });
                        } else {
                          dispatchToast({
                            type: "error",
                            content:
                              "É necessário informar um motivo para a reprovação",
                          });
                        }
                      },
                    })
                  }
                >
                  {t(`${context}.reject`)}
                </Button>
              )}
            </>
          )}
          <GridActions
            actions={[
              {
                description: "Ver detalhes de despesa",
                icon: "IconFileDescription",
                onclick: () => {
                  navigate(
                    `/expense/company-payables/bills/details/${bill.id}`
                  );
                },
                visible: true,
              },
            ]}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Button
            size="small"
            variant="secondary"
            variantType="neutral"
            onClick={() =>
              navigate(`/expense/company-payables/bills/details/${bill.id}`)
            }
          >
            {t(`${context}.details`)}
          </Button>
        </Container>
      );
  }
}
