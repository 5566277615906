import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";

export function UseSavePriorityOrder(closeModal: () => void) {
  const { t } = useTranslation();
  const context = "components.approvalPriority";
  const { mutate, isLoading } =
    trpc.approvalFlow.reorderApprovalFlow.reorder.useMutation({
      onSuccess: () => {
        dispatchToast({ type: "success", content: t(`${context}.success`) });
        closeModal();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: t(`${context}.error`),
          description: t(`${context}.tryAgain`),
        });
      },
    });

  return { saveOrder: mutate, isLoading };
}
