import React, { createContext, ReactNode, useContext, useState } from "react";

interface SelectedRowsContextType {
  selectedRows: string[];
  selectedAll: boolean;
  totalRows: number;
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  setTotalRows: React.Dispatch<React.SetStateAction<number>>;
  toggleSelectAll(ids: string[]): void;
}

const SelectedRowsContext = createContext<SelectedRowsContextType | undefined>(
  undefined
);

export const SelectedRowsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const toggleSelectAll = (ids: string[]) => {
    if (selectedAll) {
      setSelectedRows([]);
      setSelectedAll(false);
    } else {
      setSelectedRows(ids);
      setSelectedAll(true);
    }
  };

  return (
    <SelectedRowsContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        toggleSelectAll,
        selectedAll,
        totalRows,
        setTotalRows,
      }}
    >
      {children}
    </SelectedRowsContext.Provider>
  );
};

export const useSelectedRowsContext = (): SelectedRowsContextType => {
  const context = useContext(SelectedRowsContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedRows must be used within a SelectedRowsProvider"
    );
  }
  return context;
};
