import { useCategories } from "@common/hooks/useCategory";
import { paymentMethod } from "@common/payment-methods";
import { RowCheckBox } from "@molecules/checkbox/rowcheckBox";
import { BillActionModal } from "@organisms/bill-action-modal/billActionModal";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { BillStatus } from "@organisms/bills-list/hooks/useBillsRows";
import React from "react";
import { ApprovalGridActions } from "../../approval-actions";
import { useApprovalData } from "./useApprovalData";

export type ApprovalRow = {
  id: string;
  status: BillStatus;
  category: string;
  categoryId: string;
  vendorId: string;
  dueDate?: Date;
  amount: number;
  paymentType: string;
  vendorLegalName: string;
  vendorCode: string;
  select: React.ReactNode;
  actions: React.ReactNode;
  createdBy: string;
};

export function UseApprovalRows() {
  const { bills, isLoading: billIsLoading } = useApprovalData();

  // Retrieve category data
  const { categories, isLoading: categoryLoading } = useCategories();

  const isLoading = billIsLoading || categoryLoading;

  const rows = bills?.map((bill) => {
    return {
      id: bill.id,
      createdBy: bill.createdBy,
      status: bill.status,
      category: categories?.find((c) => c.id === bill.categoryId)?.name || "",
      dueDate: bill.dueAt ? new Date(bill.dueAt) : undefined,
      amount: bill.amount,
      paymentType:
        paymentMethod.find((p) => p.value === bill.paymentTerms?.paymentMethod)
          ?.label || "",
      vendorLegalName: bill.vendor.legalName,
      vendorCode: bill.vendor.code,
      vendorId: bill.vendor.id,
      categoryId: bill.categoryId || "",
      select: <RowCheckBox id={bill.id} />,
      actions: (
        <BillActionModalProvider>
          <BillActionModal />
          <ApprovalGridActions bill={bill} />
        </BillActionModalProvider>
      ),
    } satisfies ApprovalRow;
  });

  const ids = rows?.map((row) => row.id) || [];

  return {
    isLoading: isLoading,
    rows,
    ids,
  };
}
