import { Stepper, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { useTranslation } from "react-i18next";
import {
  Container,
  ContentContainer,
  ImagePreview,
  StyledDivider,
} from "./styled";

type OnboardSteps = {
  step: number;
};
export function OnboardSteps({ step }: OnboardSteps) {
  const { t } = useTranslation();
  const context = "components.onboarding.steps";

  const basePath = "https://public-images-flash.s3.sa-east-1.amazonaws.com/";

  function getStep(step: number) {
    switch (step) {
      case 0:
        return (
          <>
            <Container style={{ paddingTop: "0" }}>
              <Typography variant="headline6">
                {t(`${context}.addVendor.title`)}
              </Typography>
            </Container>
            <StyledDivider />
            <Container>
              <Typography variant="body3">
                {t(`${context}.addVendor.description`)}
              </Typography>
              <ImagePreview
                crossOrigin="anonymous"
                src={`${basePath}add_vendor.gif`}
              />
            </Container>
          </>
        );
      case 1:
        return (
          <>
            <Container style={{ paddingTop: "0" }}>
              <Typography variant="headline6">
                {t(`${context}.createTransactionBill.title`)}
              </Typography>
            </Container>
            <StyledDivider />
            <Container>
              <Typography variant="body3">
                {t(`${context}.createTransactionBill.description`)}
              </Typography>
              <ImagePreview src={`${basePath}add_bill_02.gif`} />
            </Container>
          </>
        );
      case 2:
        return (
          <>
            <Container style={{ paddingTop: "0" }}>
              <Typography variant="headline6">
                {t(`${context}.createBill.title`)}
              </Typography>
            </Container>
            <StyledDivider />
            <Container>
              <Typography variant="body3">
                {t(`${context}.createBill.description`)}
              </Typography>
              <ImagePreview src={`${basePath}add_bill_01.gif`} />
            </Container>
          </>
        );
      case 3:
        return (
          <>
            <Container style={{ paddingTop: "0" }}>
              <Typography variant="headline6">
                {t(`${context}.fillBillForm.title`)}
              </Typography>
            </Container>
            <StyledDivider />
            <Container>
              <Typography variant="body3">
                {t(`${context}.fillBillForm.description`)}
              </Typography>
              <ImagePreview src={`${basePath}add_bill_03.gif`} />
            </Container>
          </>
        );
      case 4:
        return (
          <>
            <Container style={{ paddingTop: "0" }}>
              <Typography variant="headline6">
                {t(`${context}.accounting.title`)}
              </Typography>
            </Container>
            <StyledDivider />
            <Container>
              <Typography variant="body3">
                {t(`${context}.accounting.description`)}
              </Typography>
              <ImagePreview src={`${basePath}account.gif`} />
            </Container>
          </>
        );
      default:
        return null;
    }
  }
  return (
    <>
      <ContentContainer>
        <Stepper
          steps={[
            t(`${context}.addVendor.title`),
            t(`${context}.createTransactionBill.title`),
            t(`${context}.createBill.title`),
            t(`${context}.fillBillForm.title`),
            t(`${context}.accounting.title`),
          ]}
          activeStep={step}
        />
      </ContentContainer>
      {getStep(step)}
    </>
  );
}
