import {
  LinkButton,
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SortPriorityModal } from "../../sort-priority/sortPriorityModal";
import { ApprovalFlow } from "../approval-flow-schema";
import {
  Box,
  CaptionBox,
  FormBox,
  FormContainer,
  Grid,
  RowContainer,
} from "../styled";
import { CriteriaSelector } from "./criteria-selector";

export function StepOne() {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();
  const { t } = useTranslation();
  const context = "components.approvalFlowForm.stepOne";
  const criteria = watch("criteria");
  const priority = watch("priority");

  const [sortModal, setSortModal] = useState(false);

  return (
    <FormBox>
      <FormContainer template="1fr 1fr">
        <Box columnStart={1} columnEnd={3}>
          <RowContainer justify="space-between">
            <Typography variant="headline8" weight={700}>
              {t(`${context}.basicInformation`)}
            </Typography>
            <Typography variant="body4">{t(`${context}.required`)}</Typography>
          </RowContainer>
        </Box>

        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label={t(`${context}.fields.name`)}
              required
            />
          )}
        />
        <Box rowStart={3}>
          <Grid template="1fr">
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t(`${context}.fields.description`)}
                  required
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>
        </Box>
        {priority && (
          <>
            <SortPriorityModal
              open={sortModal}
              closeModal={() => setSortModal(false)}
            />
            <Box rowStart={4}>
              <Grid template="1fr">
                <Controller
                  control={control}
                  name="priority"
                  defaultValue={1}
                  render={({ field: { value } }) => (
                    <SelectField
                      value={value}
                      disabled
                      options={[{ value: value, label: value }]}
                      label={t(`${context}.fields.priority`)}
                    />
                  )}
                />
                <CaptionBox>
                  <Typography variant="caption">
                    {t(`${context}.fields.orderTip`)}
                  </Typography>
                </CaptionBox>
              </Grid>
            </Box>
            <Box rowStart={4} style={{ marginTop: "-42px" }}>
              <LinkButton variant="primary" onClick={() => setSortModal(true)}>
                {t(`${context}.fields.changePriority`)}
              </LinkButton>
            </Box>
          </>
        )}
      </FormContainer>
      <FormContainer template="1fr">
        <div>
          <Typography variant="headline8" weight={700}>
            {t(`${context}.criteria.title`)}
          </Typography>
          <Typography variant="body4">
            {t(`${context}.criteria.subtitle`)}
          </Typography>
        </div>
        {criteria.map((c, index) => {
          return <CriteriaSelector index={index} key={index} />;
        })}

        <RowContainer>
          <LinkButton
            variant="primary"
            onClick={() =>
              setValue("criteria", [
                ...criteria,
                { relationshipCondition: "AND" } as any,
              ])
            }
          >
            {t(`${context}.criteria.addCriteria`)}
          </LinkButton>
        </RowContainer>
      </FormContainer>
    </FormBox>
  );
}
