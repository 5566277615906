import { StyledBadge, StyledBadgeText } from "./styled";

type BadgeProps = {
  value: string;
};
export function Badge({ value }: BadgeProps) {
  return (
    <StyledBadge>
      <StyledBadgeText variant="headline9">{value}</StyledBadgeText>
    </StyledBadge>
  );
}
