import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: ${({ theme }) => theme.spacings.m};
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
  margin-bottom: ${({ theme }) => theme.spacings.s};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs2};
`;

export const CustomContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const Footer = styled.div`
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-wrap: wrap;
  margin-left: -${({ theme }) => theme.spacings.m};
  background-color: ${(props) => props.theme.colors.neutral[100]};
  width: -webkit-fill-available;
  position: fixed;
  bottom: 0;
  z-index: 1300;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xs3};
`;
