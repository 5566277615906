import { trpc } from "@client";
import useUploadVendorAttachment from "@common/hooks/useUploadVendorAttachment";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VendorRequest } from "../vendor-schema";

export function useSaveVendor() {
  const uploadAttachments = useUploadVendorAttachment();
  const saveVendor = trpc.vendor.upsert.useMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const context = "pages.vendorForm";

  const save = async (e: VendorRequest) => {
    if (e.attachmentFiles) {
      for await (const { file: attachment } of e.attachmentFiles) {
        if (attachment && attachment.size > 0) {
          const s3Paths = await uploadAttachments.mutateAsync(attachment);
          e.attachments = e.attachments || [];
          console.log(s3Paths);
          if (s3Paths?.length) {
            e.attachments.push({
              fileName: attachment.name,
              s3Path: s3Paths[0],
            });
          }
        }
      }
    }

    saveVendor.mutateAsync(e, {
      onSuccess() {
        dispatchToast({
          type: "success",
          content: t(`${context}.success`),
        });
        navigate("/expense/company-payables/vendors");
      },
      onError() {
        dispatchToast({
          type: "error",
          content: t(`${context}.error.title`),
          description: t(`${context}.error.subtitle`),
        });
      },
    });
  };

  return {
    saveVendor: save,
    isLoading: saveVendor.isLoading || uploadAttachments.isLoading,
  };
}
