import { trpc } from "@client";
import {
  Button,
  IconButton,
  IconTypes,
  Icons,
  Tag,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider, Skeleton } from "@mui/material";
import { dispatchToast } from "@utils/dispatchToast";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RouterOutputs } from "server/src/router";
import { BillFormConfig } from "./form.schema";
import { useBillConfigurationForm } from "./hooks/useBillConfigurationForm";
import { PreviewFormModal } from "./preview-form-modal";
import {
  Box,
  Container,
  ContainerColumn,
  FieldContainer,
  OpacityContainer,
  PaddingBox,
  StyledDatePicker,
  StyledDropzone,
  StyledIcon,
  StyledSelectField,
  StyledTextField,
} from "./styled";

export type FieldType = {
  rule: RouterOutputs["billConfiguration"]["getSettings"]["settings"]["billValidation"]["rules"][number];
  index: number;
};

export function BillFormConfiguration() {
  const { t } = useTranslation();
  const { data, isLoading } = trpc.billConfiguration.getSettings.useQuery();
  const { mutate, status } = trpc.billConfiguration.updateSettings.useMutation({
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Erro ao salvar configuração",
      });
    },
  });
  const methods = useBillConfigurationForm();
  const [open, setIsOpen] = useState(false);
  const rules = data?.settings?.billValidation?.rules;

  useEffect(() => {
    methods.reset({ id: data?.id, rules });
  }, [rules]);

  const context = "components.billConfiguration";
  const values = methods.watch();
  const allRequired = values?.rules?.every((_) => _.required) ?? false;

  useEffect(() => {
    if (values.id) mutate(values);
  }, [JSON.stringify(values)]);

  if (isLoading || !values.rules) {
    return <Skeleton width={"100%"} height={"100Px"} />;
  }
  return (
    <form onSubmit={methods.handleSubmit(() => undefined)}>
      <Box>
        <PaddingBox>
          <ContainerColumn>
            <Container>
              <ContainerColumn>
                <Typography variant="headline8">
                  {t(`${context}.title`)}
                </Typography>
                <Typography variant="body4">
                  {t(`${context}.subtitle`)}
                </Typography>
              </ContainerColumn>
              <Button
                style={{ minWidth: "200px" }}
                variant="secondary"
                size="medium"
                onClick={() => setIsOpen(true)}
              >
                {t(`${context}.showForm`)}
                <Icons name="IconExternalLink" />
              </Button>
              {values && (
                <PreviewFormModal
                  rules={values.rules}
                  open={open}
                  onClose={() => setIsOpen(false)}
                />
              )}
            </Container>
            <Container>
              <Toggle
                checked={allRequired}
                onChange={() => {
                  rules?.forEach((_, index) => {
                    methods.setValue(`rules.${index}.required`, !allRequired);
                  });
                }}
              />
              <Typography variant="body3">
                {t(`${context}.keepAllRequired`)}
              </Typography>
            </Container>
          </ContainerColumn>
        </PaddingBox>
        <Divider style={{ width: "100%" }} />
        <PaddingBox>
          <FormProvider {...methods}>
            {values?.rules.map((field, i) => (
              <Field index={i} rule={field} key={i} />
            ))}
          </FormProvider>
        </PaddingBox>
      </Box>
    </form>
  );
}

function Field({ rule, index }: FieldType) {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<BillFormConfig>();

  const context = "components.billConfiguration";

  const getFieldProps = (rule: FieldType["rule"]) => {
    let iconName: IconTypes;
    let title;
    let child;

    switch (rule.property) {
      case "currency":
      case "categoryId":
      case "costCenterId":
      case "paymentTerms.paymentMethod":
        iconName = "IconList";
        title = t(`${context}.fieldTypes.dropdown`);
        child = (
          <StyledSelectField
            label={t(`${context}.labels.${rule.property}`)}
            disabled
          ></StyledSelectField>
        );
        break;
      case "issuedAt":
      case "dueAt":
        iconName = "IconCalendar";
        title = t(`${context}.fieldTypes.date`);
        child = (
          <StyledDatePicker
            onDateChange={() => undefined}
            label={t(`${context}.labels.${rule.property}`)}
            disabled
          />
        );
        break;
      case "attachments":
        iconName = "IconPaperclip";
        title = t(`${context}.fieldTypes.attachment`);
        child = (
          <StyledDropzone
            title="Anexos"
            accept={["JPG", "PNG", "PDF"]}
          ></StyledDropzone>
        );
        break;
      case "description":
        iconName = "IconCursorText";
        title = t(`${context}.fieldTypes.longText`);
        child = (
          <StyledTextField
            disabled
            label={t(`${context}.labels.${rule.property}`)}
            variant="filled"
            required={rule.required}
            multiline
            rows={4}
          />
        );
        break;
      default:
        iconName = "IconCursorText";
        title = t(`${context}.fieldTypes.shortText`);
        child = (
          <StyledTextField
            disabled
            label={t(`${context}.labels.${rule.property}`)}
            variant="filled"
            required={rule.required}
          />
        );
        break;
    }
    return { iconName, title, child };
  };

  const { iconName, title, child } = getFieldProps(rule);
  const visible = watch(`rules.${index}.visible`);

  return (
    <FieldContainer visible={visible}>
      <OpacityContainer visible={visible}>
        <StyledIcon name={iconName} stroke="neutral"></StyledIcon>
        <Tag variant={"gray"}>{title}</Tag>
      </OpacityContainer>
      <Container>
        <OpacityContainer visible={visible}>{child}</OpacityContainer>
        <div>
          <Controller
            control={control}
            name={`rules.${index}.visible`}
            render={({ field: { value, onChange } }) => (
              <IconButton
                icon={value ? "IconEyeOff" : "IconEye"}
                variant={value ? "filled" : "line"}
                size="large"
                style={{ background: value ? "" : "#fff" }}
                onClick={() => onChange(!value)}
              />
            )}
          />
        </div>
      </Container>
      <OpacityContainer visible={visible}>
        <Controller
          control={control}
          defaultValue={false}
          name={`rules.${index}.required`}
          render={({ field: { value, onChange } }) => (
            <Toggle checked={value} onChange={onChange}></Toggle>
          )}
        />
        <Typography variant="body3">
          {t(`${context}.labels.required`)}
        </Typography>
      </OpacityContainer>
    </FieldContainer>
  );
}
