import { Dropzone, IconButton } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect } from "react";
import { ButtonContainer, Container, StyledPDFViewer } from "./styled";

export type DocumentProp = {
  base64?: string | null;
  contentType?: string;
  file?: File;
  previewUrl?: string;
};

type CustomPreviewProps = DocumentProp & {
  onDelete: () => void;
};

interface DropzoneFile {
  id: string;
  data?: string | ArrayBuffer | null | undefined;
  progress: number;
  loaded: boolean;
  error: boolean;
  file: File;
  mime?: string;
}

type PdfDropZoneProps = {
  document: DocumentProp;
  onChange(document?: DocumentProp): void;
  height?: string;
};

const CustomPreview = ({ onDelete, previewUrl }: CustomPreviewProps) => (
  <Container>
    <ButtonContainer>
      <IconButton
        onClick={onDelete}
        variant="line"
        size="medium"
        icon="IconTrash"
        style={{ backgroundColor: "#fff" }}
      />
    </ButtonContainer>
    <StyledPDFViewer
      height={"700px"}
      src={previewUrl as string}
      options={{
        showToolbar: false,
        fit: "height",
      }}
    />
  </Container>
);

export function PdfDropZone({ document, onChange, height }: PdfDropZoneProps) {
  useEffect(() => {
    return () => {
      if (document?.previewUrl) URL.revokeObjectURL(document.previewUrl);
    };
  }, [document]);

  function onDropzoneChange(selectedReceipts: DropzoneFile[]) {
    const receipts = selectedReceipts.map((receipt) => {
      const { data, file } = receipt;
      if (!data || !file) {
        return;
      }
      const previewUrl = URL.createObjectURL(file);
      return {
        base64:
          data instanceof ArrayBuffer ? bufferToBase64(data, file.type) : data,
        contentType: file.type,
        file,
        previewUrl,
      };
    });
    onChange(receipts[0]);
  }
  return (
    <>
      {!document?.previewUrl ? (
        <Dropzone
          style={{ height: height || "700px" }}
          title="Documento fiscal"
          accept={["pdf", "jpeg", "png"]}
          onChange={onDropzoneChange}
        />
      ) : (
        <CustomPreview {...document} onDelete={() => onChange({})} />
      )}
    </>
  );
}

function bufferToBase64(buffer: ArrayBuffer, mime: string): string {
  const binary = Array.from(new Uint8Array(buffer))
    .map((byte) => String.fromCharCode(byte))
    .join("");

  return `data:${mime};base64,${window.btoa(binary)}`;
}
