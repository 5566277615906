import { brazilianStates } from "@common/brazilian-states ";
import { countries } from "@common/countries";
import { getCep } from "@flash-hros/utility";
import {
  Checkbox,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTextField } from "@molecules/text-field/text-field";
import { VendorRequest } from "@organisms/vendor-form/vendor-schema";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledFormGrid } from "../vendor-form/styled";
import { Container, RowContainer } from "./styled";

export const AddressForm = () => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<VendorRequest>();
  const { t } = useTranslation();
  const context = "pages.vendorForm.fields";
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const setAddress = (value: string) => {
    if (value?.length === 9) {
      setLoading(true);
      getCep(value).then((data: any) => {
        if (data) {
          setValue("address.city", data?.localidade, {
            shouldTouch: true,
            shouldValidate: true,
          });
          setValue("address.state", data?.uf, {
            shouldTouch: true,
            shouldValidate: true,
          });
          setValue("address.street", data?.logradouro, {
            shouldTouch: true,
            shouldValidate: true,
          });
          setValue("address.neighborhood", data?.bairro, {
            shouldTouch: true,
            shouldValidate: true,
          });
        }
        setLoading(false);
      });
    }
  };

  const country = watch("address.country");

  return (
    <StyledFormGrid>
      <RowContainer>
        <Typography variant="headline8">
          {t(`pages.vendorForm.address`)}
        </Typography>
        <Typography variant="body4">
          {t(`pages.vendorForm.addressSubTitle`)}
        </Typography>
      </RowContainer>
      <Controller
        name="address.country"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <SelectField
            required
            onSelectChange={(_, option) => onChange(option?.value)}
            value={value}
            options={countries}
            label={t(`${context}.country.label`)}
            searchable
            error={!!errors.address?.country}
            helperText={errors.address?.country && t(`${context}.required`)}
          />
        )}
      />
      <Controller
        name="address.postalCode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <StyledTextField
            required
            imaskProps={{ mask: "00000-000" }}
            onChange={(e) => {
              setAddress(e.target.value);
              onChange(e);
            }}
            value={value}
            label={t(`${context}.postalCode.label`)}
            inputMode="numeric"
            error={!!errors.address?.postalCode}
            helperText={errors.address?.postalCode && t(`${context}.required`)}
          />
        )}
      />
      <RowContainer>
        <Controller
          name="address.street"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              required
              value={value}
              onChange={onChange}
              label={t(`${context}.street.label`)}
              inputMode="text"
              error={!!errors.address?.street}
              helperText={errors.address?.street && t(`${context}.required`)}
              disabled={loading}
            />
          )}
        />
      </RowContainer>
      <Container>
        <Controller
          name="address.number"
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <StyledTextField
              {...field}
              required={!checked}
              disabled={checked}
              label={t(`${context}.number.label`)}
              inputMode="text"
              error={!!errors.address?.number}
              helperText={errors.address?.number && t(`${context}.required`)}
            />
          )}
        />
        <Container>
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          />
          <div>
            <Typography variant="body3">
              {t(`${context}.number.checkbox`)}
            </Typography>
          </div>
        </Container>
      </Container>
      <Controller
        name="address.complement"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t(`${context}.complement.label`)}
            inputMode="text"
            error={!!errors.address?.complement}
          />
        )}
      />

      <Controller
        name="address.neighborhood"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <StyledTextField
            required
            value={value}
            onChange={onChange}
            label={t(`${context}.neighborhood.label`)}
            inputMode="text"
            error={!!errors.address?.neighborhood}
            helperText={
              errors.address?.neighborhood && t(`${context}.required`)
            }
            disabled={loading}
          />
        )}
      />
      {country === "Brasil" ? (
        <Controller
          name="address.state"
          render={({
            field: { onChange, value },
            fieldState: { isTouched },
          }) => (
            <SelectField
              required
              InputLabelProps={{ shrink: isTouched }}
              onSelectChange={(_, option) => onChange(option?.value)}
              value={isTouched && value}
              options={brazilianStates}
              label={t(`${context}.state.label`)}
              error={!!errors.address?.state}
              helperText={errors.address?.state && t(`${context}.required`)}
              disabled={loading}
            />
          )}
          control={control}
        />
      ) : (
        <Controller
          name="address.state"
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              required
              value={value}
              imaskProps={{
                mask: "**",
                prepare: (value: string) => value.toUpperCase(),
              }}
              onChange={onChange}
              label={`${t(`${context}.state.label`)} (UF)`}
              inputMode="text"
              error={!!errors.address?.state}
              helperText={errors.address?.state && t(`${context}.required`)}
              disabled={loading}
            />
          )}
          control={control}
        />
      )}
      <Controller
        name="address.city"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <StyledTextField
            required
            value={value}
            onChange={onChange}
            label={t(`${context}.city.label`)}
            inputMode="text"
            error={!!errors.address?.city}
            helperText={errors.address?.street && t(`${context}.required`)}
            disabled={loading}
          />
        )}
      />
    </StyledFormGrid>
  );
};
