import {
  Button,
  LinkButton,
  Modal,
  TextField,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Category } from "..";
import { UseCategoryMutation } from "../hooks/useCategoryMutation";
import { Box, Content, Footer, Header } from "./styled";
type ModalProps = {
  open: boolean;
  onClose(): void;
  category?: Category;
};

const baseCategory = {
  id: "",
  active: true,
  name: "",
  externalCode: "",
  description: "",
  productType: "SUPPLIER_EXPENSE",
  tag: "Outros",
} as const;
export function ConfigurationEditModal({
  open,
  onClose,
  category,
}: ModalProps) {
  const [data, setData] = useState<Category>(baseCategory);
  const { updateCategory, createCategory, isLoading } = UseCategoryMutation();
  const { t } = useTranslation();

  useMemo(() => {
    category ? setData(category) : setData(baseCategory);
  }, [category]);

  const context = "components.categoryConfiguration.editModal";

  return (
    <Modal.Root open={open} onClose={onClose} size="sm">
      <>
        <Header title={t(`${context}.title`)} />

        <Content>
          <TextField
            style={{ width: "100%" }}
            disabled={!!data.isSystemDefault}
            value={data?.name}
            label={t(`${context}.labels.name`)}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <div>
            <TextField
              style={{ width: "100%" }}
              value={data?.externalCode}
              label={t(`${context}.labels.code`)}
              onChange={(e) =>
                setData({ ...data, externalCode: e.target.value })
              }
              helperText={t(`${context}.labels.codeHelperText`)}
            />
          </div>
          <Box>
            <Toggle
              checked={data.active}
              onChange={(e) => setData({ ...data, active: e.target.checked })}
            />
            <div>
              <Typography variant="body3" weight={700}>
                {t(`${context}.labels.toggle`)}
              </Typography>
              <Typography variant="body4">
                {t(`${context}.labels.toggleHelperText`)}
              </Typography>
            </div>
          </Box>
        </Content>

        <Divider style={{ width: "100%" }} />
        <Footer>
          <LinkButton variant="primary" onClick={onClose}>
            {t(`${context}.cancel`)}
          </LinkButton>
          <Button
            style={{ minWidth: "150px" }}
            loading={isLoading}
            size="medium"
            variant="primary"
            onClick={() => {
              if (data) {
                if (data.id) updateCategory(data, onClose);
                else {
                  createCategory(data, onClose);
                }
              }
            }}
          >
            {t(`${context}.save`)}
          </Button>
        </Footer>
      </>
    </Modal.Root>
  );
}
