import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const FlexContainer = styled.div<{ expand?: boolean }>`
  ${(props) => props.expand && "grid-column-start: 1;"}
  ${(props) => props.expand && "grid-column-end: 3;"}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.s};
`;

export const GapContainer = styled(FlexContainer)`
  row-gap: ${({ theme }) => theme.spacings.xs1};
`;

export const SubTitle = styled.div`
  padding-top: ${({ theme }) => theme.spacings.xs2};
`;

export const CustomContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const TitleContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs2};
  border-bottom: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  margin-left: -${({ theme }) => theme.spacings.xs};
  margin-right: -${({ theme }) => theme.spacings.xs};
  padding-left: ${({ theme }) => theme.spacings.xs};
  padding-right: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const SpanContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled(SpanContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIcon = styled(Icons)`
  path {
    fill: ${({ theme }) => theme.colors.error50};
    stroke: ${({ theme }) => theme.colors.error50};
  }
`;
