export enum PayablesPermissions {
  BILL_VIEW = "expense_payables_bill_list_view",
  CREATE_BILL = "expense_payables_create_bill",
  APPROVE_BILL = "expense_payables_approve_bill",

  TRANSACTIONS_LIST = "expense_transaction_list_view",

  VENDOR_VIEW = "expense_vendor_view",
  VENDOR_CREATE = "expense_payables_create_vendor",
  VENDOR_DELETE = "expense_payables_delete_vendor",

  ACCOUNTING_LIST = "expense_payables_account_view",
  ACCOUNT_BILL = "expense_payables_account",

  FORM_CONFIGURATION = "expense_payables_form_configuration",
  CATEGORY_CONFIGURATION = "expense_payables_category_configuration",
  APPROVAL_FLOW_CONFIGURATION = "expense_payables_approval_flow_configuration",
}
