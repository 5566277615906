import { trpc } from "@client";

export type VendorFilters = {
  status?: "all" | "active" | "inactive";
  lineOfBusiness?: string[];
  country?: string[];
  text?: string;
};
export function useVendorData(filters: VendorFilters, enable?: boolean) {
  const { data, isLoading, isFetching } = trpc.vendor.list.useQuery(filters, {
    enabled: enable,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isLoading || isFetching,
    isFetching,
    data: data,
  };
}
