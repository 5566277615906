import { Toggle, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { ApprovalFlowActionModal } from "../approval-flow-action-modal/approvalActionModal";
import { useApprovalFlowActionModal } from "../approval-flow-action-modal/approvalModalContext";
import { ApprovalFlow } from "../approval-flow-form/approval-flow-schema";
import { useToggleApprovalFlow } from "../approval-flow-grid/hooks/useToggleApprovalFlow";
import { Box } from "./styled";

type toggleApprovalFlowProps = {
  id: string;
  status: ApprovalFlow["status"];
};
export function ToggleApprovalFlow({ id, status }: toggleApprovalFlowProps) {
  const { toggle } = useToggleApprovalFlow();
  const { setBillActionModal } = useApprovalFlowActionModal();

  function onToggle() {
    if (status === "INACTIVE") {
      toggle(id);
    } else {
      setBillActionModal({
        open: true,
        type: "inactive",
        onConfirm: () => toggle(id),
      });
    }
  }
  return (
    <Box>
      <ApprovalFlowActionModal />
      <Toggle checked={status === "ACTIVE"} onChange={() => onToggle()} />
      <Typography variant="body3">
        {status === "ACTIVE" ? "on" : "off"}
      </Typography>
    </Box>
  );
}
