import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { useBillActionModal } from "./billModalContext";

export function ConfirmButton() {
  const { isLoading, description, billActionModal, setIsLoading } =
    useBillActionModal();
  const { t } = useTranslation();
  const { type, onConfirm: confirm } = billActionModal;
  const context = "components.billActionModal";

  const onConfirm = (data?: string) => {
    setIsLoading(true);
    confirm(data);
  };

  switch (type) {
    case "account":
      return (
        <Button
          loading={isLoading}
          size="large"
          variant="primary"
          onClick={() => onConfirm()}
        >
          {t(`${context}.${type}.confirm`)}
        </Button>
      );
    case "reclassification":
      return (
        <Button
          loading={isLoading}
          size="large"
          variant="primary"
          onClick={() => onConfirm()}
        >
          {t(`${context}.${type}.confirm`)}
        </Button>
      );
    case "reject":
      return (
        <Button
          loading={isLoading}
          size="large"
          buttonType="primary"
          variantType="error"
          onClick={() => onConfirm(description)}
        >
          {t(`${context}.${type}.confirm`)}
        </Button>
      );
    case "delete":
      return (
        <Button
          loading={isLoading}
          size="large"
          buttonType="primary"
          variantType="error"
          onClick={() => onConfirm()}
        >
          {t(`${context}.${type}.confirm`)}
          <Icons name="IconTrash" fill="transparent" />
        </Button>
      );
    case "approve":
      return (
        <Button
          loading={isLoading}
          size="large"
          buttonType="primary"
          onClick={() => onConfirm()}
        >
          {t(`${context}.${type}.confirm`)}
        </Button>
      );
    case "approvalReject":
      return (
        <Button
          loading={isLoading}
          size="large"
          buttonType="primary"
          variantType="error"
          onClick={() => onConfirm(description)}
        >
          {t(`${context}.${type}.confirm`)}
        </Button>
      );
  }
}
