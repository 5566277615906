import { usePlanFeatures } from "@common/hooks/usePlanFeatures";
import { useApproveBill } from "@containers/bill-approvals/approval-actions/hooks/useApproveBill";
import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { useSendBillToAccount } from "@organisms/bills-list/hooks/useSendBillToAccount";
import { useSubmitBill } from "@organisms/bills-list/hooks/useSubmitBill";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";

type RightButtonProps = {
  bill: Bill;
};
export function RightButton({ bill }: RightButtonProps) {
  const { t } = useTranslation();
  const { sendBillToAccount, isLoading: sending } = useSendBillToAccount();
  const { submit, isLoading: submitLoading } = useSubmitBill();
  const { setBillActionModal } = useBillActionModal();
  const { approve, canApprove } = useApproveBill();
  const { isBasicPlan } = usePlanFeatures();

  const context = "pages.billDetails";

  if (bill.status === "OPEN" && isBasicPlan())
    return (
      <Button
        loading={sending}
        size="medium"
        variant="primary"
        onClick={() => sendBillToAccount(bill.id)}
      >
        {t(`${context}.footer.sendToAccount`)}
      </Button>
    );

  if (bill.status === "OPEN" || bill.status === "ERROR_SUBMITTING_APPROVAL")
    return (
      <Button
        loading={submitLoading}
        size="medium"
        variant="primary"
        onClick={() => submit(bill.id)}
      >
        {t(`${context}.footer.sendToApprove`)}
      </Button>
    );
  if (bill.status === "AWAIT_APPROVAL" && canApprove(bill)) {
    return (
      <>
        <Button
          loading={sending}
          size="medium"
          variant="primary"
          variantType="error"
          onClick={() =>
            setBillActionModal({
              open: true,
              type: "approvalReject",
              onConfirm: (value) => {
                if (value) {
                  approve({
                    id: bill.id,
                    action: "REJECTED",
                    comment: value,
                  });
                } else {
                  dispatchToast({
                    type: "error",
                    content:
                      "É necessário informar um motivo para a reprovação",
                  });
                }
              },
            })
          }
        >
          {t(`${context}.footer.reject`)}
          <Icons name="IconThumbDown"></Icons>
        </Button>
        <Button
          loading={sending}
          size="medium"
          variant="primary"
          variantType="success"
          onClick={() =>
            setBillActionModal({
              open: true,
              type: "approve",
              onConfirm: () => {
                approve({ id: bill.id, action: "APPROVED" });
              },
            })
          }
        >
          {t(`${context}.footer.approve`)}
          <Icons name="IconThumbUp"></Icons>
        </Button>
      </>
    );
  }

  return null;
}
