import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";
import { useCostCenterData } from "./useCostCenterData";

export default function useSelectCostCenter(id?: string) {
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 1000);

  const { data, loading } = useCostCenterData(id, debouncedSearch);

  const options = data
    ?.filter((constCenter) => constCenter?.active)
    .map((constCenter) => ({
      value: constCenter?.id,
      label: constCenter?.name,
    }));

  return {
    loading: loading,
    options: options || [],
    setSearch,
  };
}
