import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedRowsContext } from "@organisms/select-rows-context";

type GridCheckboxProps = {
  ids: string[];
};
export function GridCheckbox({ ids }: GridCheckboxProps) {
  const { toggleSelectAll } = useSelectedRowsContext();
  return (
    <Checkbox
      size="small"
      onChange={() => toggleSelectAll(ids)}
      checked={false}
    />
  );
}
