import {
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import useSearchEmployee from "./hooks/useApprovers";

export const SelectApprover = (props: SelectFieldProps) => {
  const { options } = useSearchEmployee();

  return (
    <SelectField {...props} searchable options={options} inputMode="text" />
  );
};
