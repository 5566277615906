import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { batchActionResponse } from "./useToggleVendor";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";

export function useDeleteVendor(data?: Vendor[]) {
  const { t } = useTranslation();
  const utils = trpc.useContext();

  const context = "components.vendorGrid.messages";

  const deleteVendor = trpc.vendor.delete.useMutation({
    onSuccess: (response: batchActionResponse[]) => {
      response
        ?.filter((_) => _.status === "rejected")
        .forEach((error) => {
          const vendor = data?.find((_) => _.id === error.id);
          dispatchToast({
            type: "error",
            content: `${t(`${context}.deleteError`)} ${
              vendor?.legalName || vendor?.name
            }`,
            description: `${t(`${context}.subtitle`)}`,
          });
        });
      response.some((_) => _.status === "fulfilled") &&
        dispatchToast({
          type: "success",
          content: `${t(`${context}.deleteSuccess`)}`,
        });
      utils.vendor.list.invalidate();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: `${t(`${context}.batchDeleteError`)}`,
      });
    },
  });

  const deleteVendorMutation = (ids: string[]) => {
    deleteVendor.mutate(ids);
  };

  return {
    deleteVendorMutation,
  };
}
