import { trpc } from "@client";

export function useCostCenter(id?: string) {
  const { data, isLoading } = trpc.costCenter.getById.useQuery(id || "", {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
  };
}
