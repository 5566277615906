import { SearchField, TagFilter } from "@flash-tecnologia/hros-web-ui-v2";
import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type ApprovalFlowFilters = {
  status?: string[];
  approvalType?: string[];
};

export function useApprovalFlowFilters() {
  const [filters, setFilters] = useState<ApprovalFlowFilters>({});
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const debounceSearch = useDebounce(search, 500);

  const context = "components.approvalFlowGrid.filters";
  const filtersComponent = (
    <>
      <TagFilter
        hasLeftIcon={false}
        options={[
          { label: t(`${context}.status.active`), value: "ACTIVE" },
          { label: t(`${context}.status.inactive`), value: "INACTIVE" },
        ]}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.status.label`)}
        onApply={(values: string[]) => {
          setFilters({ ...filters, status: values });
        }}
        onClear={() => {
          const { status, ...rest } = filters;
          setFilters(rest);
        }}
      />
      <TagFilter
        hasLeftIcon={false}
        options={[
          { label: t(`${context}.approvalType.automatic`), value: "AUTO" },
          { label: t(`${context}.approvalType.custom`), value: "CUSTOM" },
        ]}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.approvalType.label`)}
        onApply={(values: string[]) => {
          setFilters({ ...filters, approvalType: values });
        }}
        onClear={() => {
          const { approvalType, ...rest } = filters;
          setFilters(rest);
        }}
      />
    </>
  );
  const searchFilter = (
    <SearchField
      label={t(`${context}.search`)}
      onInputChange={(_, value) => setSearch(value)}
    />
  );

  const formattedFilter =
    filters &&
    Object.entries(filters).map((entry) => ({
      id: entry[0],
      value: entry[1],
    }));

  return {
    formattedFilter,
    filtersComponent,
    searchFilter,
    search: debounceSearch,
  };
}
