import { DataDisplay } from "@molecules/Data-display";
import { Transaction } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { TransactionList } from "@organisms/transaction-list";
import { useTranslation } from "react-i18next";
import { CardInfoContainer } from "./styled";

type TransactionItemProps = {
  transaction: Transaction;
  isFlashCard: boolean;
};
export function TransactionItem({
  transaction,
  isFlashCard,
}: TransactionItemProps) {
  const { t } = useTranslation();
  const context = "components.billForm";

  return (
    <>
      {isFlashCard && (
        <CardInfoContainer>
          <DataDisplay
            label={t(`${context}.payment.paymentForm`)}
            value="Cartão corporativo flash"
          />
          <DataDisplay label={t(`${context}.payment.issuer`)} value="Flash" />
          <DataDisplay
            label={t(`${context}.payment.card`)}
            value={`––${transaction.cardLastDigits}`}
          />
        </CardInfoContainer>
      )}
      <TransactionList
        rows={[
          {
            description: transaction.description,
            value: transaction.amount,
            date: transaction.createdAt,
            card: transaction.cardLastDigits,
          },
        ]}
        itemActions="none"
      />
    </>
  );
}
