import { GridSelectOptions } from "@organisms/grid-select-options/gridSelectOptions";
import { useGridSelectActions } from "./hooks/useGridSelectionActions";
type BillGridSelectedOptionsProps = {
  ids: string[];
  isAccounting: boolean;
};
export function BillGridSelectedOptions({
  ids,
  isAccounting,
}: BillGridSelectedOptionsProps) {
  const gridSelectActions = useGridSelectActions(isAccounting);
  return <GridSelectOptions ids={ids} actions={gridSelectActions} />;
}
