import {
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import useSelectCostCenter from "./hooks/useSelectCostCenter";

export const SelectCostCenter = (props: SelectFieldProps) => {
  const { loading, options, setSearch } = useSelectCostCenter();
  return (
    <SelectField
      {...props}
      searchable
      options={options}
      inputMode="text"
      onChange={(e) => setSearch(e.target.value)}
      noOptionsText={loading ? "Buscando..." : undefined}
    />
  );
};
