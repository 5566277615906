import { useTracking } from "@common/hooks/useTracking";
import {
  Button,
  ButtonProps,
  Icons,
  IconsProps,
  LinkButton,
  LinkButtonProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { BillForm } from "@organisms/bill-form";
import { PageContent } from "@organisms/page-content";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Transaction } from "./hooks/UseTransactionRows";
import { Container } from "./styled";

type ButtonsProps = {
  label: string;
  icon?: IconsProps["name"];
  variant: ButtonProps["variant"];
  linkVariant?: LinkButtonProps["variant"];
  onClick?(): void;
  isSubmit?: boolean;
  formId?: string;
  isLoading?: boolean;
};

type PageButtons = {
  leftButtons: ButtonsProps[];
  rightButtons: ButtonsProps[];
};

const LeftButton = ({ prop }: { prop: ButtonsProps }) =>
  prop.isSubmit ? (
    <button type="submit" form={prop.formId}>
      <LinkButton variant={prop.linkVariant || "primary"}>
        {prop.label}
      </LinkButton>
    </button>
  ) : (
    <LinkButton variant={prop.linkVariant || "primary"} onClick={prop.onClick}>
      {prop.label}
    </LinkButton>
  );

const RightButton = ({ prop }: { prop: ButtonsProps }) =>
  prop.isSubmit ? (
    <Button
      variant={prop.variant}
      size="medium"
      type="submit"
      form={prop.formId}
      loading={prop.isLoading}
    >
      {prop.label}
      {prop?.icon && <Icons name={prop.icon} />}
    </Button>
  ) : (
    <Button variant={prop.variant} size="medium" onClick={prop.onClick}>
      {prop.label}
      {prop?.icon && <Icons name={prop.icon} />}
    </Button>
  );

export function BillWorkflow({ transaction }: { transaction?: Transaction }) {
  const [saveLoading, setSaveLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const event = useTracking();

  const pageContext = "pages.billForm";
  const componentContext = "components.billWorkFlow";

  const buttons: PageButtons = {
    leftButtons: [
      {
        label: t(`${componentContext}.buttons.cancel`),
        variant: "secondary",
        linkVariant: "secondary",
        onClick: () => {
          event("payables_bill_cancel_clicked");
          navigate("/expenseManagement/expenses");
        },
      },
    ],
    rightButtons: [
      {
        variant: "primary",
        label: t(`${componentContext}.buttons.save`),
        isSubmit: true,
        formId: "bill-form",
        isLoading: saveLoading,
      },
    ],
  };

  return (
    <PageContent
      title={t(`${pageContext}.formStepTitle`)}
      subtitle={t(`${pageContext}.formSubtitle`)}
      leftButton={
        <Container>
          {buttons?.leftButtons &&
            buttons?.leftButtons.map((prop, i) => (
              <LeftButton prop={prop} key={i} />
            ))}
        </Container>
      }
      rightButton={
        <Container>
          {buttons?.rightButtons &&
            buttons?.rightButtons.map((prop, i) => (
              <RightButton prop={prop} key={i} />
            ))}
        </Container>
      }
    >
      <BillForm transaction={transaction} setLoading={setSaveLoading} />
    </PageContent>
  );
}
