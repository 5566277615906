import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const ScrollContainer = styled(Container)`
  overflow: scroll;
  max-height: 60vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xl4}`};
  margin-top: ${({ theme }) => `-${theme.spacings.s}`};
  align-items: start;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xl4}`};
  align-items: start;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;

export const Fields = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Span = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;
