import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";
import { useDebounce } from "@uidotdev/usehooks";
import React from "react";

export default function useSearchFilter() {
  const [search, setAppliedFilter] = React.useState<string | undefined>(
    undefined
  );
  const debounceSearch = useDebounce(search, 500);

  const SearchFilterComponent = (
    <SearchField
      label="Buscar despesa"
      onInputChange={(_, value) => setAppliedFilter(value)}
    />
  );

  return {
    SearchFilterComponent,
    search: debounceSearch,
  };
}
