import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApprovalFlow } from "../approval-flow-schema";

export function useSaveApprovalFlow() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const context = "components.approvalFlowForm.messages";
  const { mutate: create, isLoading: createLoading } =
    trpc.approvalFlow.createApprovalFlow.create.useMutation({
      onSuccess: () => {
        dispatchToast({ type: "success", content: t(`${context}.create`) });
        navigate(-1);
      },
      onError: () =>
        dispatchToast({
          type: "error",
          content: t(`${context}.createError`),
          description: t(`${context}.tryAgain`),
        }),
    });
  const { mutate: update, isLoading: updateLoading } =
    trpc.approvalFlow.updateApprovalFlow.update.useMutation({
      onSuccess: () => {
        dispatchToast({ type: "success", content: t(`${context}.update`) });
        navigate(-1);
      },

      onError: () =>
        dispatchToast({
          type: "error",
          content: t(`${context}.updateError`),
          description: t(`${context}.tryAgain`),
        }),
    });

  function save(data: ApprovalFlow) {
    if (!data.id) {
      create(data);
    } else {
      update({ ...data, id: data.id });
    }
  }
  return { save, isLoading: createLoading || updateLoading };
}
