import {
  Icons,
  LinkButton,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaymentMethod } from "@organisms/bill-form/hook/useBillForm";
import { useState } from "react";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import { useGetPaymentData } from "./hooks/useGetPaymentData";
import {
  Container,
  DataBox,
  DataContainer,
  LinkBox,
  PaymentBox,
  RowBox,
  TitleContainer,
} from "./styled";
import { TransactionModal } from "./transaction-modal";

type PaymentMethodSelectProps = {
  paymentMethods: Vendor["bankData"];
  onSelect(id: string): void;
  selected?: string;
  payed: boolean;
  isError: boolean;
};
export function PaymentMethodSelect({
  paymentMethods,
  onSelect,
  selected,
  payed,
  isError,
}: PaymentMethodSelectProps) {
  const data = useGetPaymentData(paymentMethods);
  const [open, setOpen] = useState(false);

  return (
    <Container>
      {data?.map((payment, i) => (
        <RowBox key={i}>
          <PaymentBox isError={isError} selected={selected === payment.id}>
            <TitleContainer>
              <Radio
                checked={selected === payment.id}
                onChange={() => {
                  onSelect(payment.id);
                }}
              />
              <Typography variant="body3" weight={600} style={{ flexGrow: 1 }}>
                {payment.title}
              </Typography>
              {payment.favorite && (
                <Icons name="IconStarFilled" color="#FEA034" />
              )}
            </TitleContainer>
            <DataContainer>
              {payment.data.map((_, i) => {
                return (
                  <DataBox key={i}>
                    <Typography variant="caption" weight={600}>
                      {_.label}
                    </Typography>
                    <Typography variant="body4" weight={600}>
                      {_.value}
                    </Typography>
                  </DataBox>
                );
              })}
            </DataContainer>
          </PaymentBox>
          {payment.type === PaymentMethod.CARD &&
            payment.id === selected &&
            payment.cardId &&
            payed && (
              <LinkBox>
                <LinkButton
                  variant="primary"
                  style={{ fontSize: "14px" }}
                  onClick={() => setOpen(true)}
                >
                  Selecionar transação +
                </LinkButton>
                <TransactionModal
                  open={open}
                  cardId={payment.cardId}
                  onClose={() => setOpen(false)}
                />
              </LinkBox>
            )}
        </RowBox>
      ))}
    </Container>
  );
}
