import {
  SearchField,
  TagFilter,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { VendorActionModal } from "@organisms/vendor-action-modal/vendorActionModal";
import { useVendorActionModal } from "@organisms/vendor-action-modal/vendorModalContext";
import { useTranslation } from "react-i18next";
import { useVendorFilters } from "./hooks/useVendorFilters";
import { UseVendorRows } from "./hooks/useVendorRows";
import { FilterContainer, GridContainer, StyledDataGrid } from "./styled";
import { VendorColumns } from "./vendorColumns";
import { VendorGridSelectedOptions } from "./VendorGridSelectOptions";

export const VendorGrid = () => {
  const { filters, searchValue, setSearchValue, filtersList } =
    useVendorFilters();
  const { t } = useTranslation();
  const { rows, isLoading, data } = UseVendorRows(filters);
  const { vendorActionModal, closeModal } = useVendorActionModal();

  const ids = rows?.map((row) => row.id) || [];

  return (
    <>
      <FilterContainer>
        <SearchField
          value={searchValue}
          label={t(`components.vendorFilters.searchLabel`)}
          onInputChange={(_, value) => setSearchValue(value as string)}
        ></SearchField>
        <div>
          <Typography variant="body3">Filtrar por</Typography>
        </div>
        {filtersList.map((filter, i) => (
          <TagFilter
            key={i}
            hasLeftIcon={false}
            onApply={(values) => {
              filter.onApply(values as string[]);
            }}
            onClear={() => {
              filter.onClear();
            }}
            options={filter.options}
            optionIconType={"checkbox"}
            variant={"secondary"}
            filterLabel={filter.label}
          />
        ))}
      </FilterContainer>
      <GridContainer>
        <VendorActionModal {...vendorActionModal} onClose={closeModal} />
        <VendorGridSelectedOptions ids={ids} data={data} />
        <StyledDataGrid
          loading={isLoading}
          columns={VendorColumns(ids)}
          data={rows}
          hasPagination={rows && rows.length > 1}
          pageSizeOptions={[
            { value: 5, label: "5 itens" },
            { value: 10, label: "10 itens" },
            { value: 20, label: "20 itens" },
          ]}
        />
      </GridContainer>
    </>
  );
};
