import { BasePage } from "@organisms/BasePage/BasePage";
import { VendorActionModalProvider } from "@organisms/vendor-action-modal/vendorModalContext";
import { VendorDetails } from "@organisms/vendor-details";
import { useTranslation } from "react-i18next";

export const PageVendorDetails = () => {
  const { t } = useTranslation();
  const pageContext = "pages.vendorDetail";

  return (
    <BasePage
      header={{
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.vendors`),
            to: "/expense/company-payables/vendors",
          },
          {
            label: t(`${pageContext}.breadCrumbs.details`),
          },
        ],
      }}
      children={
        <VendorActionModalProvider>
          <VendorDetails />
        </VendorActionModalProvider>
      }
      hasFooter
    />
  );
};
