import { getFromLS, useSelectedCompany } from "@flash-hros/utility";
import { segmentEvent } from "../../helpers/segment";

export type EventName =
  //payables tab
  | "payables_bill_account_tab"
  | "payables_bill_tab"

  //screens
  | "vendor_screen"

  //vendor_actions
  | "vendor_add_clicked"
  | "vendor_cancel_clicked"
  | "vendor_save_clicked"

  // bill_actions
  | "payables_bill_add_clicked"
  | "payables_bill_cancel_clicked"
  | "payables_bill_save_clicked"
  | "payables_bill_save_send_to_account_clicked"
  | "payables_bill_send_to_account_clicked"

  //banner
  | "Payables_news_banner_viewed"
  | "payables_onboard_clicked"
  | "payables_onboard_finished";

interface User {
  employeeId: string;
}

export const useTracking = () => {
  const company = useSelectedCompany();
  const setUserProperties = (): User | null => {
    const user = getFromLS("hrosAccessToken");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.analytics?.identify(company?.selectedCompany?.id, {
      employeeId: user?.employeeId,
      companyId: company?.selectedCompany?.id,
    });

    return user;
  };

  const event = (name: EventName, data?: Record<string, string>) => {
    if (!name) {
      if (process.env.BUILD_ENV !== "production") {
        console.error("Invalid event name");
      }

      return;
    }

    const user = setUserProperties();

    const _data = {
      userId: user?.employeeId,
      origin: "flash-os",
      employeeId: user?.employeeId,
      companyId: company?.selectedCompany?.id,
      route: window.location.pathname,
      ...data,
    };

    segmentEvent({
      track: name,
      params: _data,
      module: "company-payables",
      businessUnit: "flash-expense",
    });
  };

  return event;
};
