import { useTracking } from "@common/hooks/useTracking";
import {
  Button,
  Icons,
  LinkButton,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Finishing } from "../content/finishing";
import { Introduction } from "../content/introduction";
import { OnboardSteps } from "../content/onboardSteps";
import { ButtonContainer, Container, StyledDivider } from "./styled";

type OnboardingModalProps = {
  open: boolean;
  onClose(): void;
};
export function OnboardingModal({ open, onClose }: OnboardingModalProps) {
  const [showSteps, setShowSteps] = useState(false);
  const [step, setStep] = useState(0);
  const event = useTracking();
  const { t } = useTranslation();

  const context = "components.onboarding.buttons";

  function getContent() {
    if (!showSteps) {
      return <Introduction />;
    }
    if (showSteps && step < 5) {
      return <OnboardSteps step={step} />;
    } else {
      return <Finishing />;
    }
  }

  function close() {
    setShowSteps(false);
    setStep(0);
    onClose();
  }
  return (
    <Modal.Root open={open} onClose={close} size="md">
      <Container>
        {getContent()}
        <StyledDivider />
        {showSteps ? (
          <ButtonContainer>
            <div>
              {!!step && (
                <LinkButton variant="primary" onClick={() => setStep(step - 1)}>
                  <Icons name={"IconArrowLeft"} /> {t(`${context}.back`)}
                </LinkButton>
              )}
            </div>
            {step < 5 ? (
              <Button
                size="large"
                buttonType="primary"
                onClick={() => setStep(step + 1)}
              >
                {t(`${context}.next`)}
                <Icons name={"IconArrowRight"} />
              </Button>
            ) : (
              <Button
                size="large"
                buttonType="primary"
                onClick={() => {
                  event("payables_onboard_finished");
                  close();
                }}
              >
                {t(`${context}.finish`)}
              </Button>
            )}
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <div>
              <LinkButton variant="primary" onClick={close}>
                {t(`${context}.noThanks`)}
              </LinkButton>
            </div>

            <Button
              size="large"
              buttonType="primary"
              onClick={() => setShowSteps(true)}
            >
              {t(`${context}.letsGo`)} <Icons name={"IconArrowRight"} />
            </Button>
          </ButtonContainer>
        )}
      </Container>
    </Modal.Root>
  );
}
