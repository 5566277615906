import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { BillFormConfig, billFormSchema } from "../form.schema";

export function useBillConfigurationForm() {
  const methods = useForm<BillFormConfig>({
    resolver: zodResolver(billFormSchema),
  });

  return methods;
}
