import { getAccessToken, useSelectedCompany } from "@flash-hros/utility";
import { useMutation } from "@tanstack/react-query";
import { dispatchToast } from "@utils/dispatchToast";
import axios from "axios";
import EnvService from "../../api/env.service";
import { UrlMap } from "../../api/service-constants";

const url = UrlMap[EnvService.BUILD_ENV];

function useUploadBillFiscalDocument() {
  const company = useSelectedCompany();

  return useMutation(
    async (data: File) => {
      const token = await getAccessToken();

      const formData = new FormData();
      formData.append("files", data);

      const response = await axios({
        method: "post",
        url: `${url}/file/bills/fiscaldoc/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          "company-id": company.selectedCompany.id,
        },
      });
      return response.data.s3Paths as string[];
    },
    {
      onError: (error: any) => {
        dispatchToast({
          type: "error",
          content: "Erro ao fazer upload de arquivos",
          description: error?.message,
        });
      },
    }
  );
}

export default useUploadBillFiscalDocument;
