import {
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import useSelectCategory from "./hooks/useSelectCategory";

export const SelectCategory = (props: SelectFieldProps) => {
  const { loading, options } = useSelectCategory();
  return (
    <SelectField
      {...props}
      searchable
      options={options}
      inputMode="text"
      noOptionsText={loading ? "Buscando..." : undefined}
    />
  );
};
