import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { TrpcProvider } from "./api/client";
import AppRouter from "./routes";

import { BillFormConfiguration } from "@containers/configuration/bill-form-configuration";
import { CategoryConfiguration } from "@containers/configuration/category-configuration";
import { ConfigurationTab } from "@containers/configuration/configuration-tab/configurationTab";
import { OnboardingBanner } from "@containers/onboarding/onboardAlert/onboardingBanner";
import { Accounting } from "@organisms/accounting";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { SelectedRowsProvider } from "@organisms/select-rows-context";
import { TransactionsTab } from "@organisms/transaction-tab";
import { BrowserRouter } from "react-router-dom";
import "../src/i18n";
import { BillsTab } from "./components/organisms/bills-tab";
import { Approvals } from "./containers/bill-approvals/approvals/approvals";

export default function Root() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <AppRouter />
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function TransactionTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <TransactionsTab />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function BillTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <BillsTab />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function AccountingTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <BillActionModalProvider>
            <Accounting />
          </BillActionModalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function PayablesConfigurationTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <ConfigurationTab />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function BillConfigurationTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <BillFormConfiguration />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function CategoryConfigurationTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <CategoryConfiguration />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function PayablesBanner() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <OnboardingBanner />
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}

export function ApprovalsTab() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <BrowserRouter>
          <BillActionModalProvider>
            <SelectedRowsProvider>
              <Approvals />
            </SelectedRowsProvider>
          </BillActionModalProvider>
        </BrowserRouter>
      </ThemeProvider>
    </TrpcProvider>
  );
}
