export function toCurrency(value: number, noPrefix = false): string {
  if (isNaN(Number(value))) return "R$ 0,00";

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  let formattedValue = formatter.format(value / 100);

  if (noPrefix) {
    formattedValue = formattedValue.replace("R$", "").trim();
  }

  return formattedValue;
}
