import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs2};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const CategoryBox = styled(Box)`
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.xs4};
`;

export const GridBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs2};
`;

export const PaddingBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  width: 100%;
`;

export const RowContainer = styled(Container)`
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: space-between;
`;

export const ContainerColumn = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
`;
