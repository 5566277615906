import { DocumentProp } from "@organisms/pdf-drop-zone";
import { z } from "zod";

export enum PaymentMethod {
  BILLET = "BILLET",
  TRANSFER = "TRANSFER",
  FLASH_CARD = "FLASH_CARD",
  CARD = "CARD",
  PIX = "PIX",
}

export enum PixType {
  CPF_CNPJ = "CPF_CNPJ",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  KEY = "KEY",
}

export enum AccountType {
  CHECKING = "CHECKING",
  SAVINGS = "SAVINGS",
}

export const vendorSchema = z.object({
  id: z.string().optional(),
  code: z.string().optional(),
  registrationNumber: z
    .string()
    .min(11)
    .transform((_) => _.replace(/\D/g, "")),
  name: z.string().optional(),
  legalName: z.string().trim().min(1),
  stateSubscription: z.string().optional(),
  citySubscription: z.string().optional(),
  address: z.object({
    street: z.string(),
    number: z.coerce.number().optional(),
    complement: z.string().optional(),
    postalCode: z
      .string()
      .max(9)
      .transform((_) => _.replace(/\D/g, "")),
    neighborhood: z.string(),
    city: z.string(),
    state: z.string(),
    country: z.string(),
  }),
  email: z
    .string()
    .email()
    .optional()
    .or(z.literal(""))
    .transform((v) => (v ? v : undefined)),
  phone: z
    .string()
    .optional()
    .transform((_) => _?.replace(/\D/g, "")),
  active: z.boolean(),
  contactData: z
    .object({
      name: z.string().optional(),
      email: z
        .string()
        .email()
        .optional()
        .or(z.literal(""))
        .transform((v) => (v ? v : undefined)),
      phone: z
        .string()
        .optional()
        .transform((_) => _?.replace(/\D/g, "")),
    })
    .optional(),
  responsibleEmployeeId: z.string().optional(),
  description: z.string().optional(),
  bankData: z.array(
    z.object({
      id: z.string().optional(),
      paymentMethod: z.nativeEnum(PaymentMethod),
      bank: z.coerce.string().optional(),
      accountType: z.nativeEnum(AccountType).optional(),
      agency: z.string().regex(/^\d+$/g).optional(),
      account: z.string().regex(/^\d+$/g).optional(),
      pixKey: z.string().optional(),
      pixType: z.nativeEnum(PixType).optional(),
      favorite: z.boolean(),
      cardId: z.string().optional(),
      cardNumber: z.string().optional(),
    })
  ),
  defaultPaymentMethod: z.string().optional(),
  lineOfBusiness: z.string(),
  attachments: z
    .array(
      z.object({
        fileName: z.string(),
        s3Path: z.string(),
      })
    )
    .optional(),
});
export type VendorRequest = z.infer<typeof vendorSchema> & {
  attachmentFiles: DocumentProp[];
};
