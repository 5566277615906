import { DataGrid, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledDataGrid = styled(DataGrid)``;
export const GridContainer = styled.div`
  position: relative;
`;

export const SelectContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.m};
  padding-left: ${(props) => props.theme.spacings.xs};
  gap: ${(props) => props.theme.spacings.xs};
  background-color: ${(props) => props.theme.colors.neutral[95]};
  position: absolute;
  z-index: 15;
  left: 1px;
  top: 1px;
  right: 1px;
  height: 105px;
  border-top-right-radius: ${(props) => props.theme.borders.radius.m};
  border-top-left-radius: ${(props) => props.theme.borders.radius.m};
`;

export const StyledTableText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
  min-width: 180px;
`;

export const StyledTableTextSecondary = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledBadgeText = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
`;

export const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacings.m};
  min-width: ${({ theme }) => theme.spacings.m};
  height: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.palette.secondary.light};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  row-gap: ${({ theme }) => theme.spacings.s};
`;

export const FilterContainer = styled(Container)`
  flex-wrap: wrap;
  padding-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const StyledTag = styled(Tag)`
  cursor: auto;
  pointer-events: none;
`;

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  flex-direction: "row";
`;
