import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { PageContent } from "@organisms/page-content";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApprovalFlow } from "./approval-flow-schema";
import { ApprovalTypeWarning } from "./approval-type-warning/approval-type-warning";
import { useApprovalFlowForm } from "./hooks/use-approval-flow-form";
import { useSaveApprovalFlow } from "./hooks/use-save-approval-flow";
import { StepOne } from "./step-one/step-one";
import { StepThree } from "./step-three/step-three";
import { StepTwo } from "./step-two/Step-Two";
type ApprovalFlowFormProps = {
  step: number;
  setStep(_: number): void;
  approvalFlow?: ApprovalFlow;
  isCopy?: boolean;
};
export function ApprovalFlowForm({
  step,
  setStep,
  approvalFlow,
  isCopy,
}: ApprovalFlowFormProps) {
  const { methods } = useApprovalFlowForm(approvalFlow, isCopy);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { save, isLoading: saveIsLoading } = useSaveApprovalFlow();
  const pageContext = "components.approvalFlowForm";

  const leftButtons = (
    <>
      <LinkButton
        variant="secondary"
        onClick={() => {
          navigate(-1);
        }}
      >
        {t(`${pageContext}.leftButtons.exit`)}
      </LinkButton>
    </>
  );
  const rightButtons = () => {
    const next = (
      <Button
        key="next-button"
        size="medium"
        variant="primary"
        onClick={() => setStep(step + 1)}
      >
        {t(`${pageContext}.rightButtons.next`)}
        <Icons name="IconArrowRight" />
      </Button>
    );
    const back = (
      <Button
        size="medium"
        variant="secondary"
        onClick={() => setStep(step - 1)}
      >
        <Icons name="IconArrowLeft" />
        {t(`${pageContext}.rightButtons.back`)}
      </Button>
    );
    const save = (
      <Button
        loading={saveIsLoading}
        key="save-button"
        size="medium"
        variant="primary"
        type="submit"
        form="approval-flow-form"
      >
        {t(`${pageContext}.rightButtons.save`)}
      </Button>
    );

    switch (step) {
      case 0:
        return next;
      case 1:
        return (
          <>
            {back}
            {next}
          </>
        );
      case 2:
        return (
          <>
            {back}
            {save}
          </>
        );
      default:
        return;
    }
  };

  const titles = [
    {
      title: t(`${pageContext}.stepTitles.one.title`),
      subtitle: t(`${pageContext}.stepTitles.one.subtitle`),
    },
    {
      title: t(`${pageContext}.stepTitles.two.title`),
      subtitle: t(`${pageContext}.stepTitles.two.subtitle`),
    },
    {
      title: t(`${pageContext}.stepTitles.three.title`),
      subtitle: t(`${pageContext}.stepTitles.three.subtitle`),
    },
  ];

  return (
    <PageContent
      leftButton={leftButtons}
      rightButton={rightButtons()}
      title={titles.at(step)?.title}
      subtitle={titles.at(step)?.subtitle}
      leftChildren={step === 1 ? <ApprovalTypeWarning /> : undefined}
    >
      <FormProvider {...methods}>
        <form
          id="approval-flow-form"
          onSubmit={methods.handleSubmit((values) => {
            save(values);
          })}
        >
          <>
            {step === 0 && <StepOne />}
            {step === 1 && <StepTwo />}
            {step === 2 && <StepThree />}
          </>
        </form>
      </FormProvider>
    </PageContent>
  );
}
