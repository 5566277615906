import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { GridCheckbox } from "@molecules/checkbox/gridCheckbox";
import { Tag } from "@molecules/tag";
import { VendorColumn } from "@molecules/vendor-column";
import TableClient from "@organisms/TableClient";
import { formatDateTime } from "@utils/dateFormater";
import { toCurrency } from "@utils/toCurrency";
import { useTranslation } from "react-i18next";
import { ApprovalRow } from "./useApprovalRow";

export function useApprovalColumns(ids: string[]) {
  const { t } = useTranslation();
  const context = "components.approvalsList.columns";
  type Columns = React.ComponentProps<
    typeof TableClient<ApprovalRow>
  >["columns"];

  const columns = [
    // columns used only for filters
    {
      header: "",
      accessorKey: "createdBy",
      isHidden: true,
      filterFn: "customIncludes",
    },
    {
      header: "",
      accessorKey: "vendorLegalName",
      isHidden: true,
    },
    {
      header: "",
      accessorKey: "vendorCode",
      isHidden: true,
    },
    {
      header: "",
      accessorKey: "vendorId",
      isHidden: true,
      filterFn: "customIncludes",
    },
    {
      header: "",
      accessorKey: "categoryId",
      isHidden: true,
      filterFn: "customIncludes",
    },
    // Columns to display
    {
      header: () => <GridCheckbox ids={ids} />,
      accessorKey: "select",
      enableGlobalFilter: false,
      enableSorting: false,
      cell: ({
        row: {
          original: { select = null },
        },
      }) => {
        return select;
      },
    },
    {
      header: t(`${context}.vendor`),
      accessorKey: "vendorLegalName",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: {
            vendorLegalName = "",
            vendorCode = "",
            status = undefined,
          },
        },
      }) => {
        return (
          <VendorColumn
            legalName={vendorLegalName}
            code={vendorCode}
            status={status}
          />
        );
      },
    },

    {
      header: t(`${context}.dueDate`),
      accessorKey: "dueDate",
      filterFn: "customInDateRange",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { dueDate = "" },
        },
      }) => {
        return (
          <Typography variant="body3">
            {formatDateTime(new Date(dueDate), "date")}
          </Typography>
        );
      },
    },
    {
      header: t(`${context}.amount`),
      accessorKey: "amount",
      filterFn: "customInNumberRange",
      cell: ({
        row: {
          original: { amount = 0 },
        },
      }) => {
        return <Typography variant="body3">{toCurrency(amount)}</Typography>;
      },
    },
    {
      header: t(`${context}.status`),
      accessorKey: "status",
      filterFn: "customIncludes",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { status = "" },
        },
      }) => {
        switch (status) {
          case "OPEN":
            return <Tag variant="primary">{"Aberto"}</Tag>;
          case "PROCESSING_APPROVAL":
            return <Tag variant="blue">{"Processando aprovação"}</Tag>;
          case "AWAIT_APPROVAL":
            return <Tag variant="blue">{"Aprovação pendente"}</Tag>;
          case "PENDING":
            return <Tag variant="blue">{"Contabilização pendente"}</Tag>;
          case "PAID":
            return <Tag variant="success">{"Contabilizado"}</Tag>;
          case "ERROR_SUBMITTING_APPROVAL":
            return <Tag variant="error">{"Erro ao submeter"}</Tag>;
          case "REJECTED":
            return <Tag variant="error">{"Reprovado"}</Tag>;
          case "CANCELED":
            return <Tag variant="error">{"Cancelado"}</Tag>;
          default:
            return <Tag variant="primary">{"Aberto"}</Tag>;
        }
      },
    },
    {
      header: t(`${context}.category`),
      accessorKey: "category",
      cell: ({
        row: {
          original: { category = "" },
        },
      }) => {
        return <Typography variant="body3">{category}</Typography>;
      },
    },
    {
      header: t(`${context}.action`),
      accessorKey: "actions",
      enableGlobalFilter: false,
      isSticky: true,
      enableSorting: false,
      cell: ({
        row: {
          original: { actions = null },
        },
      }) => {
        return actions;
      },
    },
  ] satisfies Columns;

  return {
    columns,
  };
}
