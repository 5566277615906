import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { PhoneField } from "@molecules/phone-input/phone-field";
import { VendorRequest } from "@organisms/vendor-form/vendor-schema";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RowContainer } from "./styled";

type Accessor = "contactData";
type ContactProps = {
  accessor: Accessor;
};

export const ContactForm = ({ accessor }: ContactProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<VendorRequest>();
  const { t } = useTranslation();

  const context = "pages.vendorForm.fields";

  return (
    <>
      <RowContainer>
        <Controller
          control={control}
          name={`${accessor}.name`}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label={t(`${context}.contactName.label`)}
              inputMode="text"
              error={!!errors[accessor]?.name}
            />
          )}
        />
      </RowContainer>
      <Controller
        control={control}
        name={`${accessor}.phone`}
        render={({ field }) => (
          <PhoneField
            {...field}
            label={t(`${context}.vendorPhone.label`)}
            error={!!errors[accessor]?.phone}
          />
        )}
      />
      <Controller
        control={control}
        name={`${accessor}.email`}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label={t(`${context}.contactEmail.label`)}
            inputMode="email"
            error={!!errors[accessor]?.email}
          />
        )}
      />
    </>
  );
};
