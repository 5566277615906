import { trpc } from "@client";

export function useCostCenters() {
  const { data, isLoading } = trpc.costCenter.getAByCompany.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  );

  const costCenters = data?.costCenters;
  return {
    costCenters,
    isLoading,
  };
}
