import { BasePage } from "@organisms/BasePage/BasePage";
import { useTranslation } from "react-i18next";
import { ApprovalFlowActionModalProvider } from "../../containers/approval-flow/approval-flow-action-modal/approvalModalContext";
import { ApprovalFlowDetails } from "../../containers/approval-flow/approval-flow-details";

export const PageApprovalFlowDetails = () => {
  const { t } = useTranslation();
  const pageContext = "pages.approvalFlowDetails";

  return (
    <BasePage
      header={{
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.customization`),
            to: "/expenseManagement/customizations",
          },
          {
            label: t(`${pageContext}.breadCrumbs.details`),
          },
        ],
      }}
      hasFooter
    >
      <ApprovalFlowActionModalProvider>
        <ApprovalFlowDetails />
      </ApprovalFlowActionModalProvider>
    </BasePage>
  );
};
