import { GridActions } from "@organisms/grid-actions";
import { ApprovalFlowActionModal } from "../approval-flow-action-modal/approvalActionModal";
import { ApprovalFlow } from "../approval-flow-form/approval-flow-schema";
import { useApprovalFlowActions } from "./hooks/useApprovalFlowActions";
type ApprovalFlowActionsProps = {
  id: string;
  status: ApprovalFlow["status"];
  type: "grid" | "details";
};
export function ApprovalFlowActions({
  id,
  status,
  type,
}: ApprovalFlowActionsProps) {
  const { buildActions } = useApprovalFlowActions();
  return (
    <>
      <ApprovalFlowActionModal />
      <GridActions actions={buildActions(id, status, type)} />
    </>
  );
}
