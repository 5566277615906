import { trpc } from "@client";
import { AccountType } from "@organisms/vendor-form/vendor-schema";
import { PixType } from "server/src/services/company-payables-service/company-payables.types";
import { z } from "zod";

export enum PaymentMethod {
  BILLET = "BILLET",
  TRANSFER = "TRANSFER",
  FLASH_CARD = "FLASH_CARD",
  CARD = "CARD",
  PIX = "PIX",
}
type Rule = {
  property: string;
  visible: boolean;
  required: boolean;
};

const paymentSchema = z.object({
  paymentMethod: z.nativeEnum(PaymentMethod),
  id: z.string(),
  transactionId: z.string().optional(),
  description: z.string().optional(),
  cardNumberLastFour: z.string().length(4).optional(),
  bank: z.coerce.string().optional(),
  accountType: z.nativeEnum(AccountType).optional(),
  agency: z.string().regex(/^\d+$/g).optional(),
  account: z.string().regex(/^\d+$/g).optional(),
  pixKey: z.string().optional(),
  pixType: z.nativeEnum(PixType).optional(),
  cardId: z.string().optional(),
});

export type BillPayment = z.infer<typeof paymentSchema>;

export function useBillSchema() {
  const { data, isLoading } = trpc.billConfiguration.getSettings.useQuery();

  const isRequired = (prop: string) => {
    return data?.settings?.billValidation?.rules.some(
      (r) => r.property === prop && r.visible && r.required
    );
  };

  const attachmentSchema = z.array(
    z.object({
      fileName: z.string(),
      s3Path: z.string(),
    })
  );

  const DocumentPropSchema = z.object({
    base64: z.string().optional(),
    contentType: z.string().optional(),
    file: z.instanceof(File).optional(),
    previewUrl: z.string(),
  });

  const billSchema = z.object({
    id: z.string().optional(),
    currency: isRequired("currency") ? z.string() : z.string().optional(),
    amount: z.string().refine((val) => parseInt(val.replace(/[.,]/g, "")) > 0, {
      message: "Valor inválido",
    }),
    vendorId: z.string().min(1),
    issuedAt: isRequired("issuedAt") ? z.date() : z.date().optional(),
    dueAt: isRequired("dueAt") ? z.date() : z.date().optional(),
    categoryId: isRequired("categoryId") ? z.string() : z.string().optional(),
    costCenterId: isRequired("constCenterId")
      ? z.string()
      : z.string().optional(),
    description: isRequired("description") ? z.string() : z.string().optional(),
    document: z.object({
      type: z.string().optional(),
      number: isRequired("document.number")
        ? z.string()
        : z.string().optional(),
      nfeKey: z.string().max(44).optional(),
      barcode: z.string().optional(),
      amount: z.number().positive().optional(),
      s3Path: z.string(),
    }),
    payment: isRequired("paymentForm")
      ? paymentSchema
      : paymentSchema.optional(),
    attachments: attachmentSchema.optional(),
    documentFile: DocumentPropSchema,
    attachmentFiles: isRequired("attachments")
      ? z.array(DocumentPropSchema).nonempty()
      : z.array(DocumentPropSchema).optional(),
  });

  const fields = data?.settings.billValidation.rules;

  const rules: Record<string, Rule> =
    fields?.reduce((acc: Record<string, Rule>, rule) => {
      acc[rule.property] = rule;
      return acc;
    }, {}) || {};

  return { billSchema, rules, isLoading };
}
