import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { ActionProps } from "@organisms/grid-actions";
import { useVendorActionModal } from "@organisms/vendor-action-modal/vendorModalContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteVendor } from "./useDeleteVendor";
import { useToggleVendor } from "./useToggleVendor";

export function useVendorActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteVendorMutation } = useDeleteVendor();
  const { toggleVendorMutation } = useToggleVendor();
  const { setVendorActionModal, closeModal } = useVendorActionModal();
  const { checkPermission } = usePlatformPermissions();

  const context = "components.vendorGrid.actions";

  const buildVendorActions = (id: string, type: string, active: boolean) => {
    const props: ActionProps[] = [
      {
        description: t(`${context}.openVendor`),
        icon: "IconFileDescription",
        visible: type === "grid",
        onclick: () =>
          navigate(`/expense/company-payables/vendors/details/${id}`),
      },
    ];

    if (checkPermission(PayablesPermissions.VENDOR_CREATE)) {
      const editProps: ActionProps[] = [
        {
          description: t(`${context}.editVendor`),
          icon: "IconPencil",
          visible: true,
          onclick: () =>
            navigate(`/expense/company-payables/vendors/form/${id}`),
        },
        {
          description: t(`${context}.deactivateVendor`),
          icon: "IconToggleRight",
          visible: active,
          onclick: () => {
            setVendorActionModal({
              open: true,
              type: "deactivate",
              onConfirm: () => {
                toggleVendorMutation("deactivate", [id]);
                closeModal();
              },
            });
          },
        },
        {
          description: t(`${context}.activateVendor`),
          icon: "IconToggleLeft",
          visible: !active,
          onclick: () => {
            toggleVendorMutation("activate", [id]);
          },
        },
      ];
      props.push(...editProps);
    }

    if (checkPermission(PayablesPermissions.VENDOR_DELETE)) {
      props.push({
        description: t(`${context}.deleteVendor`),
        icon: "IconTrash",
        visible: true,
        onclick: () => {
          setVendorActionModal({
            open: true,
            type: "delete",
            onConfirm: () => {
              deleteVendorMutation([id]);
              closeModal();
            },
          });
        },
      });
    }

    if (checkPermission(PayablesPermissions.CREATE_BILL)) {
      props.push({
        description: t(`${context}.addBill`),
        icon: "IconBox",
        visible: true,
        onclick: () => {
          navigate(`/expense/company-payables/bills/form`);
        },
      });
    }

    return props;
  };

  return {
    buildVendorActions,
  };
}
