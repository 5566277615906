import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { Category } from "..";

export function UseCategoryMutation() {
  const {
    mutate: update,
    isLoading: isLoadingUpdate,
    context: mutationContext,
  } = trpc.category.update.useMutation();
  const { mutate: toggleAll, isLoading: toggleAllLoading } =
    trpc.category.toggleAll.useMutation();
  const { mutate: create, isLoading: createLoading } =
    trpc.category.create.useMutation();
  const { t } = useTranslation();
  const { mutate: deleteMutation, isLoading: deleteLoading } =
    trpc.category.delete.useMutation();

  const context = "components.categoryConfiguration.messages";
  console.log(mutationContext);

  const toggleCategory = (category: Category, callback?: () => void) => {
    update(
      { id: category.id, category },
      {
        onSuccess: () => {
          dispatchToast({
            type: "success",
            content: t(
              `${context}.${
                category.active ? "activated" : "deactivated"
              }Success`
            ),
          });

          callback && callback();
        },
        onError: () => {
          dispatchToast({
            type: "error",
            content: t(
              `${context}.${category.active ? "activated" : "deactivated"}Error`
            ),
            description: t(`${context}.tryAgain`),
          });
        },
      }
    );
  };
  const updateCategory = (category: Category, callback?: () => void) => {
    update(
      { id: category.id, category },
      {
        onSuccess: () => {
          dispatchToast({
            type: "success",
            content: t(`${context}.updateSuccess`),
          });
          callback && callback();
        },
        onError: () => {
          dispatchToast({
            type: "error",
            content: t(`${context}.updateError`),
            description: t(`${context}.tryAgain`),
          });
        },
      }
    );
  };

  const toggleAllCategories = (active: boolean) => {
    toggleAll(active, {
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: t(`${context}.toggleAllSuccess`),
        });
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: t(`${context}.toggleAllError`),
          description: t(`${context}.tryAgain`),
        });
      },
    });
  };

  const createCategory = (category: Category, callback?: () => void) => {
    create(category, {
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: t(`${context}.createSuccess`),
        });
        callback && callback();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: t(`${context}.createError`),
          description: t(`${context}.tryAgain`),
        });
      },
    });
  };

  const deleteCategory = (id: string, callback?: () => void) => {
    deleteMutation(id, {
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: t(`${context}.deleteSuccess`),
        });
        callback && callback();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: t(`${context}.deleteError`),
          description: t(`${context}.tryAgain`),
        });
      },
    });
  };

  const isLoading = isLoadingUpdate || createLoading || deleteLoading;

  return {
    toggleCategory,
    updateCategory,
    toggleAllCategories,
    createCategory,
    deleteCategory,
    isLoading,
    toggleAllLoading,
  };
}
