import { TextField, TextFieldProps } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

export const StyledTextField = (props: TextFieldProps) => {
  return (
    <Container>
      <TextField {...props} />
    </Container>
  );
};
