import { comparisonList } from "@common/comparison";
import { currency } from "@common/currency";
import {
  IconButton,
  Icons,
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Badge } from "@molecules/bedge/bedge";
import { SelectCategory } from "@organisms/category-select";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApprovalFlow } from "../approval-flow-schema";
import { Box, Grid, RowContainer } from "../styled";

type CriteriaSelectorProps = {
  index: number;
};
export function CriteriaSelector({ index }: CriteriaSelectorProps) {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();
  const { t } = useTranslation();
  const context = "components.approvalFlowForm.stepOne.criteria";

  const type = watch(`criteria.${index}.type`);

  const deleteCriteria = () => {
    setValue(
      "criteria",
      watch("criteria").filter((_, criteriaIndex) => criteriaIndex !== index)
    );
  };

  return (
    <Grid
      template={`120px repeat(${
        type === "EXPENSE_AMOUNT" ? "4" : "2"
      }, 1fr) auto`}
    >
      <RowContainer>
        <Badge value={`${index + 1}`} />
        <Typography variant="body3" weight={700}>
          {index === 0 ? `${t(`${context}.when`)}` : `${t(`${context}.and`)}`}
        </Typography>
      </RowContainer>
      <Controller
        control={control}
        name={`criteria.${index}.type`}
        render={({ field: { value, onChange } }) => (
          <SelectField
            style={{ width: "100%" }}
            onSelectChange={(_, option) => onChange(option?.value)}
            label={`${t(`${context}.condition`)} 1`}
            value={value}
            options={[
              { value: "EXPENSE_AMOUNT", label: "Valor" },
              { value: "CATEGORY", label: "Categoria" },
            ]}
          />
        )}
      />
      {type === "CATEGORY" && (
        <Controller
          control={control}
          name={`criteria.${index}.value`}
          render={({ field: { value, onChange } }) => (
            <SelectCategory
              label={`${t(`${context}.condition`)} 2`}
              value={value}
              onSelectChange={(_, option) => {
                setValue(`criteria.${index}.operator`, "EQUAL");
                setValue(`criteria.${index}.description`, option?.label);
                onChange(option?.value);
              }}
            />
          )}
        />
      )}
      {type === "EXPENSE_AMOUNT" && (
        <>
          <Controller
            control={control}
            name={`criteria.${index}.operator`}
            render={({ field: { value, onChange } }) => (
              <SelectField
                value={value}
                onSelectChange={(_, option) => onChange(option?.value)}
                label={`${t(`${context}.condition`)} 2`}
                options={comparisonList}
              />
            )}
          />
          <Controller
            control={control}
            name={`criteria.${index}.complementValue`}
            defaultValue={"BRL"}
            render={({ field: { value, onChange } }) => (
              <SelectField
                value={value}
                onSelectChange={(_, option) => onChange(option?.value)}
                label={`${t(`${context}.condition`)} 3`}
                options={currency}
              />
            )}
          />
          <Controller
            control={control}
            name={`criteria.${index}.value`}
            defaultValue={""}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                imaskProps={{
                  mask: Number,
                  thousandsSeparator: ".",
                  padFractionalZeros: true,
                  scale: 2,
                  radix: ",",
                }}
                label={`${t(`${context}.condition`)} 4`}
              />
            )}
          />
        </>
      )}
      <Box columnStart={type === "EXPENSE_AMOUNT" ? 7 : 5}>
        <IconButton variant="line" size="medium">
          <Icons name="IconTrash" onClick={deleteCriteria} />
        </IconButton>
      </Box>
    </Grid>
  );
}
