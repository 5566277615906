import { GridCheckbox } from "@molecules/checkbox/gridCheckbox";
import { VendorColumn } from "@molecules/vendor-column";
import TableClient from "@organisms/TableClient";
import { BillActionModal } from "@organisms/bill-action-modal/billActionModal";
import { formatDateTime } from "@utils/dateFormater";
import { toCurrency } from "@utils/toCurrency";
import { useTranslation } from "react-i18next";
import { BillGridSelectedOptions } from "./billGridSelectOptions";
import { BillRow, BillRowsContext, UseBillsRows } from "./hooks/useBillsRows";
import { useFilters } from "./hooks/useFilters";
import useSearchFilter from "./hooks/useSearchFilter";
import { GridContainer, StyledTag, StyledTypography } from "./styled";

type BillLstProps = {
  listContext: BillRowsContext;
  externalFilters?: { paidFilter: boolean; pendingFilter: boolean };
  isAccounting?: boolean;
};
export function BillsList({
  listContext,
  externalFilters,
  isAccounting,
}: BillLstProps) {
  const { rows, isLoading, amountRange } = UseBillsRows(listContext);
  const { t } = useTranslation();
  const { SearchFilterComponent, search } = useSearchFilter({
    disable: isLoading,
  });
  const { formattedFilter, filtersComponents } = useFilters(
    amountRange,
    externalFilters
  );
  const context = "components.billGrid";

  const ids = rows?.map((row) => row.id) || [];

  type Columns = React.ComponentProps<typeof TableClient<BillRow>>["columns"];

  const columns = [
    // columns used only for filters
    {
      header: "",
      accessorKey: "vendorLegalName",
      isHidden: true,
    },
    {
      header: "",
      accessorKey: "vendorCode",
      isHidden: true,
    },
    {
      header: "",
      accessorKey: "vendorId",
      isHidden: true,
      filterFn: "customIncludes",
    },
    {
      header: "",
      accessorKey: "categoryId",
      isHidden: true,
      filterFn: "customIncludes",
    },
    // Columns to display
    {
      header: () => {
        return <GridCheckbox ids={ids} />;
      },
      accessorKey: "select",
      enableGlobalFilter: false,
      enableSorting: false,
      cell: ({
        row: {
          original: { select = null },
        },
      }) => {
        return select;
      },
    },
    {
      header: t(`${context}.vendor`),
      accessorKey: "vendorLegalName",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: {
            vendorLegalName = "",
            vendorCode = "",
            status = undefined,
          },
        },
      }) => {
        return (
          <VendorColumn
            legalName={vendorLegalName}
            code={vendorCode}
            status={status}
          />
        );
      },
    },
    {
      header: t(`${context}.category`),
      accessorKey: "category",
      cell: ({
        row: {
          original: { category = "" },
        },
      }) => {
        return <StyledTypography variant="body3">{category}</StyledTypography>;
      },
    },
    {
      header: t(`${context}.dueDate`),
      accessorKey: "dueDate",
      filterFn: "customInDateRange",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { dueDate = "" },
        },
      }) => {
        return (
          <StyledTypography variant="body3">
            {formatDateTime(new Date(dueDate), "date")}
          </StyledTypography>
        );
      },
    },
    {
      header: t(`${context}.amount`),
      accessorKey: "amount",
      filterFn: "customInNumberRange",
      cell: ({
        row: {
          original: { amount = 0 },
        },
      }) => {
        return (
          <StyledTypography variant="body3">
            {toCurrency(amount)}
          </StyledTypography>
        );
      },
    },
    {
      header: t(`${context}.paymentType`),
      accessorKey: "paymentType",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { paymentType = "" },
        },
      }) => {
        return (
          <StyledTypography variant="body3">{paymentType}</StyledTypography>
        );
      },
    },
    {
      header: t(`${context}.costCenter`),
      accessorKey: "costCenter",
      cell: ({
        row: {
          original: { costCenter = "" },
        },
      }) => {
        return (
          <StyledTypography variant="body3">{costCenter}</StyledTypography>
        );
      },
    },
    {
      header: t(`${context}.status`),
      accessorKey: "status",
      filterFn: "customIncludes",
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { status = "" },
        },
      }) => {
        switch (status) {
          case "OPEN":
            return <StyledTag variant="primary">{"Aberto"}</StyledTag>;
          case "PENDING":
            return (
              <StyledTag variant="blue">{"Contabilização pendente"}</StyledTag>
            );
          case "PROCESSING_APPROVAL":
            return (
              <StyledTag variant="blue">{"Processando aprovação"}</StyledTag>
            );
          case "AWAIT_APPROVAL":
            return <StyledTag variant="blue">{"Aprovação pendente"}</StyledTag>;
          case "PAID":
            return <StyledTag variant="success">{"Contabilizado"}</StyledTag>;
          case "ERROR_SUBMITTING_APPROVAL":
            return <StyledTag variant="error">{"Erro ao submeter"}</StyledTag>;
          case "REJECTED":
            return <StyledTag variant="error">{"Reprovado"}</StyledTag>;
          case "CANCELED":
            return <StyledTag variant="error">{"Cancelado"}</StyledTag>;
          default:
            return <StyledTag variant="primary">{"Aberto"}</StyledTag>;
        }
      },
    },
    {
      header: t(`${context}.action`),
      accessorKey: "actions",
      enableGlobalFilter: false,
      isSticky: true,
      enableSorting: false,
      cell: ({
        row: {
          original: { actions = null },
        },
      }) => {
        return actions;
      },
    },
  ] satisfies Columns;

  const columnsLabel = [
    "Fornecedor",
    "Categoria",
    "Fatura",
    "Valor",
    "Pagamento",
    "Centro de custo",
    "Status do pagamento",
  ];

  const resolver = (rows: BillRow[]) => {
    {
      return rows.map(
        ({
          vendorLegalName,
          category,
          dueDate,
          amount,
          paymentType,
          costCenter,
          status,
        }) => {
          return [
            vendorLegalName,
            category,
            dueDate ? formatDateTime(dueDate) : "",
            toCurrency(amount),
            paymentType,
            costCenter,
            status,
          ];
        }
      );
    }
  };
  return (
    <>
      <BillActionModal />
      <GridContainer>
        <TableClient
          columns={columns}
          data={rows || []}
          emptyState={{ emptyText: "", filteredEmptyText: "" }}
          loading={isLoading}
          enablePagination
          searchFilter={search}
          TableSearchFilter={SearchFilterComponent}
          TableFilters={filtersComponents}
          filter={formattedFilter}
          selectOption={
            <BillGridSelectedOptions ids={ids} isAccounting={!!isAccounting} />
          }
          downloadOptions={{
            fileName: "despesas-com-fornecedor",
            columns: columnsLabel,
            resolver: (rows) => {
              return resolver(rows.map((_) => _.original));
            },
          }}
        />
      </GridContainer>
    </>
  );
}
