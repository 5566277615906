import { segmentTracking } from "@flash-hros/utility";

export interface SegmentTrackingProps {
  track: string;
  module: string;
  businessUnit: string;
  params?: Record<string, string | undefined>;
  identify?: {
    id: string;
    params?: Record<string, string>;
  };
}

export const segmentEvent: (props: SegmentTrackingProps) => void =
  segmentTracking;
