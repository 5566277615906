import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { ActionProps } from "@organisms/grid-select-options/gridSelectOptions";
import { useSelectedRowsContext } from "@organisms/select-rows-context";
import { useBillBatchActions } from "./useBillBatchActions";

export function useGridSelectActions(isAccounting: boolean) {
  const { checkPermission } = usePlatformPermissions();
  const { setBillActionModal } = useBillActionModal();
  const { approve } = useBillBatchActions();
  const { selectedRows } = useSelectedRowsContext();

  const gridSelectActions: ActionProps[] = [];
  if (isAccounting && checkPermission(PayablesPermissions.ACCOUNT_BILL)) {
    gridSelectActions.push({
      iconName: "IconThumbUp",
      label: "Contabilizar",
      onClick: () => {
        setBillActionModal({
          open: true,
          onConfirm: () => {
            approve(selectedRows);
          },
          type: "account",
        });
      },
    });
  }
  return gridSelectActions;
}
