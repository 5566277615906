import { z } from "zod";

export const approvalFlowSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  condition: z.array(
    z.object({
      conditionType: z.enum(["MANUAL", "HIERARCHY", "COST_CENTER_OWNER"]),
      value: z.coerce.string().min(1).optional(),
      approver: z.string().optional(),
    })
  ),
  description: z.string(),
  status: z.enum(["ACTIVE", "INACTIVE"]),
  approvalType: z.enum(["AUTO", "CUSTOM"]),
  documentType: z.enum(["BILL", "REPORT", "ADVANCE"]),
  priority: z.coerce.number().optional(),
  criteria: z.array(
    z.object({
      type: z.enum(["EXPENSE_AMOUNT", "CATEGORY"]),
      operator: z
        .enum([
          "EQUAL",
          "GREATER_THAN",
          "LESS_THAN",
          "GREATER_THAN_OR_EQUAL",
          "LESS_THAN_OR_EQUAL",
          "NOT_EQUAL",
          "CONTAINS",
          "DOES_NOT_CONTAIN",
          "STARTS_WITH",
          "ENDS_WITH",
          "IS_EMPTY",
          "IS_NOT_EMPTY",
          "IS_NULL",
          "IS_NOT_NULL",
        ])
        .optional(),
      relationshipCondition: z.enum(["AND", "OR"]),
      value: z.string(),
      complementValue: z.string().optional(),
      description: z.string().optional(),
    })
  ),
});

export type ApprovalFlow = z.infer<typeof approvalFlowSchema>;
