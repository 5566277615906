import { usePlanFeatures } from "@common/hooks/usePlanFeatures";
import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { ActionProps } from "@organisms/grid-actions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BillStatus } from "./useBillsRows";
import { useReleaseTransaction } from "./useReleaseTransaction";
import { useReopenBill } from "./useReopenBill";
import { useSendBillToAccount } from "./useSendBillToAccount";
import { useSubmitBill } from "./useSubmitBill";

export function useBillActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendBillToAccount } = useSendBillToAccount();
  const { releaseTransaction } = useReleaseTransaction();
  const { setBillActionModal } = useBillActionModal();
  const { checkPermission } = usePlatformPermissions();
  const { submit } = useSubmitBill();
  const { isBasicPlan } = usePlanFeatures();
  const { reopen, canReopen } = useReopenBill();

  const context = "components.billActions";

  const buildActions = (
    id: string,
    type: string,
    status: BillStatus,
    createdBy: string,
    transactionId?: string
  ) => {
    const props: ActionProps[] = [
      {
        description: t(`${context}.details`),
        icon: "IconFileDescription",
        visible: type === "grid",
        onclick: () => {
          navigate(`/expense/company-payables/bills/details/${id}`);
        },
      },
    ];

    if (checkPermission(PayablesPermissions.CREATE_BILL)) {
      const editProps: ActionProps[] = [
        {
          description: t(`${context}.edit`),
          icon: "IconPencil",
          visible: status === "OPEN",
          onclick: () => {
            navigate(`/expense/company-payables/bills/edit/${id}`);
          },
        },
        {
          description: t(`${context}.reopen`),
          icon: "IconPencil",
          visible: status === "REJECTED" && canReopen(createdBy),
          onclick: () => {
            reopen(id);
          },
        },
        isBasicPlan()
          ? {
              description: t(`${context}.sendToAccount`),
              icon: "IconThumbUp",
              visible: status === "OPEN" && type === "grid",
              onclick: () => {
                sendBillToAccount(id);
              },
            }
          : {
              description: t(`${context}.submit`),
              icon: "IconThumbUp",
              visible:
                (status === "OPEN" || status === "ERROR_SUBMITTING_APPROVAL") &&
                type === "grid",
              onclick: () => {
                submit(id);
              },
            },
      ];
      props.push(...editProps);
    }

    if (transactionId && checkPermission(PayablesPermissions.CREATE_BILL)) {
      props.push({
        description: t(`${context}.alterToCorporate`),
        icon: "IconReceipt",
        visible: status === "OPEN",
        onclick: () => {
          setBillActionModal({
            open: true,
            type: "reclassification",
            onConfirm: () => {
              releaseTransaction(transactionId);
            },
          });
        },
      });
    }

    return props;
  };

  return {
    buildActions,
  };
}
