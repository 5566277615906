import { transformToRecord } from "@utils/dataRecord";

export const pixKeyOptions = [
  { value: "EMAIL", label: "Email" },
  { value: "CPF_CNPJ", label: "CPF/CNPJ" },
  { value: "PHONE", label: "Telefone" },
  { value: "KEY", label: "Chave aleatória" },
];

export const pixKeyOptionsRecord = transformToRecord(pixKeyOptions);
