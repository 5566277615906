import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => `-${theme.spacings.m}`};
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
  min-width: 400px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xl4}`};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `0 ${theme.spacings.xl4}`};
  margin: ${({ theme }) => `${theme.spacings.xs2} 0`};
  max-height: 400px;
  overflow: scroll;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xl4} 0 ${theme.spacings.xl4}`};
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SubTitle = styled(StyledTypography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;
