import { trpc } from "@client";
import { useEffect, useState } from "react";
import { UseSavePriorityOrder } from "./useSavePriorityOrder";

type PriorityData = {
  approvalFlows: {
    [key: string]: {
      id: string;
      content: string;
      description: string;
    };
  };
  columns: {
    approvalFlowIds: string[];
  };
};

const initialData: PriorityData = {
  approvalFlows: {},
  columns: {
    approvalFlowIds: [],
  },
};
export function usePriorityData(closeModal: () => void) {
  const [data, setData] = useState<PriorityData>(initialData);
  const { data: approvalFlows, isLoading } =
    trpc.approvalFlow.getApprovalFlows.get.useQuery({
      limit: 999,
      page: 1,
    });

  const { saveOrder, isLoading: saveIsLoading } =
    UseSavePriorityOrder(closeModal);

  useEffect(() => {
    if (approvalFlows) {
      const data: PriorityData = {
        approvalFlows: {},
        columns: {
          approvalFlowIds: [],
        },
      };
      approvalFlows.forEach((item) => {
        data.approvalFlows[item.id] = {
          id: item.id,
          content: item.name,
          description: item.description,
        };
      });
      data.columns.approvalFlowIds = approvalFlows
        .sort((preview, next) => {
          if (preview.priority > next.priority) return 1;
          else return -1;
        })
        .map((app) => app.id);
      setData(data);
    }
  }, [approvalFlows]);

  function reorder(id: string, index?: number) {
    if (index === undefined) return;
    const order = data.columns.approvalFlowIds.filter((item) => item !== id);
    order.splice(index, 0, id);
    setData({
      ...data,
      columns: {
        ...data.columns,
        approvalFlowIds: order,
      },
    });
  }

  function save() {
    saveOrder(data.columns.approvalFlowIds);
  }

  return { initialData: data, reorder, isLoading, save, saveIsLoading };
}
