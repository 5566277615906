import { Modal } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.l}`};
`;

export const Header = styled(Modal.Header)`
  margin-top: -${({ theme }) => theme.spacings.xs};
  margin-bottom: 0;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.l}`};
  padding-bottom: 0;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs2};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;
