import { BasePage } from "@organisms/BasePage/BasePage";
import { BillActionModal } from "@organisms/bill-action-modal/billActionModal";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { useTranslation } from "react-i18next";
import { BillDetails } from "../../components/organisms/bill-details";

export const PageBillDetails = () => {
  const { t } = useTranslation();
  const pageContext = "pages.billDetails";

  return (
    <BasePage
      header={{
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.expenses`),
            to: "/expenseManagement/expenses",
          },
          {
            label: t(`${pageContext}.breadCrumbs.details`),
          },
        ],
      }}
      children={
        <BillActionModalProvider>
          <BillActionModal />
          <BillDetails />
        </BillActionModalProvider>
      }
      hasFooter
    />
  );
};
