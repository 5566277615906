import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .MuiPopper-root {
    z-index: 999;
  }
`;

export const Content = styled.div<{ hasFooter?: boolean }>`
  padding: ${({ theme }) => theme.spacings.m};
  padding-bottom: ${({ hasFooter }) => hasFooter && "75px"};
  display: flex;
  grid-row-gap: ${({ theme }) => theme.spacings.xs};
  flex-direction: column;
`;
export const Header = styled.div`
  width: 100%;
  height: min-content;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacings.xs2};
  grid-template-columns: 3fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacings.m};
  justify-items: end;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;
export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
