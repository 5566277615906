import { z } from "zod";

export const billFormSchema = z.object({
  id: z.string(),
  rules: z.array(
    z.object({
      property: z.string(),
      required: z.boolean(),
      visible: z.boolean(),
    })
  ),
});

export type BillFormConfig = z.infer<typeof billFormSchema>;
