import { IconTypes, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, IconDots, StyledText } from "./styled";

type GridActionProps = {
  actions: ActionProps[];
};

export type ActionProps = {
  icon: IconTypes;
  description: string;
  visible: boolean;
  onclick(): void;
};

export const GridActions = ({ actions }: GridActionProps) => {
  return (
    <Menu
      type="default"
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      options={actions
        .filter((_) => _.visible)
        .map((action, i) => {
          return {
            onClick: action.onclick,
            children: (
              <Container key={i}>
                <Icons name={action.icon} fill="transparent" />
                <StyledText variant="body4">{action.description}</StyledText>
              </Container>
            ),
          };
        })}
    >
      <IconDots />
    </Menu>
  );
};
