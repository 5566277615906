import {
  Button,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import TableClient from "@organisms/TableClient";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SortPriorityModal } from "../sort-priority/sortPriorityModal";
import { useApprovalFlowColumns } from "./hooks/approval-flow-columns";
import { useApprovalFlow } from "./hooks/useApprovalFlowData";
import { useApprovalFlowFilters } from "./hooks/useApprovalFlowFilters";
import {
  Box,
  Container,
  ContainerColumn,
  FiltersBox,
  PaddingBox,
} from "./styled";

export function ApprovalFlowGrid() {
  const { filtersComponent, formattedFilter, search, searchFilter } =
    useApprovalFlowFilters();
  const { rows, isLoading } = useApprovalFlow();
  const collumns = useApprovalFlowColumns();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const context = "components.approvalFlowGrid";

  const [sortModal, setSortModal] = useState(false);

  const addButton = (
    <div style={{ minWidth: "fit-content" }}>
      <Button
        size="large"
        variant="primary"
        onClick={() => navigate("/expense/company-payables/approval-flow/form")}
      >
        {t(`${context}.addButton`)}
      </Button>
    </div>
  );

  return (
    <Container>
      <PaddingBox>
        <ContainerColumn>
          <Typography variant="headline8">{t(`${context}.title`)}</Typography>
          <Typography variant="body4">{t(`${context}.subtitle`)}</Typography>
        </ContainerColumn>
      </PaddingBox>
      <Divider style={{ width: "100%" }} />
      <SortPriorityModal
        open={sortModal}
        closeModal={() => setSortModal(false)}
      />
      <PaddingBox>
        <TableClient
          loading={isLoading}
          columns={collumns}
          data={rows || []}
          emptyState={{
            emptyText: t(`${context}.emptyFilter`),
            filteredEmptyText: "",
            action: addButton,
          }}
          TableFilters={
            <FiltersBox>
              <Box>{filtersComponent}</Box>
              <div>
                <LinkButton
                  variant="primary"
                  onClick={() => setSortModal(true)}
                >
                  {t(`${context}.reorder`)}
                </LinkButton>
              </div>
            </FiltersBox>
          }
          filter={formattedFilter}
          TableSearchFilter={
            <Box>
              {searchFilter} {!!rows?.length && addButton}
            </Box>
          }
          enablePagination
          searchFilter={search}
        />
      </PaddingBox>
    </Container>
  );
}
