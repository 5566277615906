import { trpc } from "@client";

export function useCategories() {
  const { data, isLoading } = trpc.category.getAll.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const categories = data?.categories;

  return { categories, isLoading };
}
