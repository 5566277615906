import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import styled from "styled-components";

type Props = {
  /** Button's label */
  label: string;
  /** Icon on the left of the button's label */
  leftIcon?: React.ComponentProps<typeof Icons>["name"];
  /** Icon on the left of the button's label */
  rightIcon?: React.ComponentProps<typeof Icons>["name"];
  /** Changes the aspect of the tag */
  status: "active" | "neutral" | "disabled";
  /** Called when the button is clicked */
  onClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function TagButton(props: Props) {
  return (
    <StyledButton
      status={props.status}
      onClick={props.status === "disabled" ? undefined : props.onClick}
    >
      {props.leftIcon && <Icons name={props.leftIcon} fill="transparent" />}
      <Typography variant="body3" color="inherit" weight={600}>
        {props.label}
      </Typography>
      {props.rightIcon && <Icons name={props.rightIcon} fill="transparent" />}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  status: "active" | "neutral" | "disabled";
}>`
  border-radius: 100px;
  border-style: solid;
  border-width: 1px;

  align-items: center;
  display: flex;
  gap: 8px;
  flex-direction: row;
  padding: 8px 16px;
  text-align: center;
  white-space: nowrap;

  ${(p) => {
    switch (p.status) {
      case "active":
        return {
          "border-color": p.theme.colors.secondary["95"],
          "background-color": p.theme.colors.secondary["95"],
          color: p.theme.colors.secondary["50"],
        };
      case "neutral":
        return {
          "border-color": p.theme.colors.neutral["80"],
          "background-color": "transparent",
          color: p.theme.colors.neutral["40"],
        };
      case "disabled":
        return {
          "border-color": p.theme.colors.neutral["90"],
          "background-color": p.theme.colors.neutral["90"],
          color: p.theme.colors.neutral["80"],
          cursor: "default",
        };
    }
  }}
  &:hover {
    background-color: ${(p) => p.theme.colors.neutral["90"]};
    color: ${(p) =>
      p.status === "disabled"
        ? p.theme.colors.neutral["80"]
        : p.theme.colors.neutral["40"]};
    border-color: ${(p) => p.theme.colors.neutral["90"]};
  }
`;
