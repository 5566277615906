import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

type StarButtonProps = {
  checked: boolean;
  onClick: () => void;
};

export function StarButton({ checked, onClick }: StarButtonProps) {
  return (
    <StyledIconButton
      icon="IconStar"
      size="medium"
      variant="line"
      onClick={onClick}
      checked={checked}
    />
  );
}
const StyledIconButton = styled(IconButton)<{ checked: boolean }>`
  svg {
    fill: ${({ checked, theme }) => (checked ? theme.colors.error50 : "")};
    stroke: ${({ checked, theme }) => (checked ? theme.colors.error50 : "")};
  }
`;
