import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { useBillSchema } from "./useBillSchema";

export function useBillFormContext() {
  const { billSchema, rules } = useBillSchema();
  type BillRequest = z.infer<typeof billSchema>;
  const methods = useFormContext<BillRequest>();
  return { methods, rules };
}
