import { useTracking } from "@common/hooks/useTracking";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton } from "@mui/material";
import { BillFormFields } from "@organisms/bill-fields";
import { BillFormPaymentMethod } from "@organisms/bill-form-payment-method";
import { Transaction } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { PdfDropZone } from "@organisms/pdf-drop-zone";
import { dispatchToast } from "@utils/dispatchToast";
import { useEffect } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import { useBillForm } from "./hook/useBillForm";
import { useSaveBill } from "./hook/useSaveBill";
import {
  Container,
  CustomContainer,
  DropContainer,
  FlexRowContainer,
  FormContainer,
  StyledDropZone,
} from "./styled";

export interface LocationState {
  transaction: Transaction;
}

export function BillForm({
  transaction,
  bill,
  setLoading,
  billIsLoading,
}: {
  transaction?: Transaction;
  bill?: Bill;
  setLoading(value: boolean): void;
  billIsLoading?: boolean;
}) {
  const { t } = useTranslation();
  const {
    methods,
    isLoading: configIsLoading,
    rules,
  } = useBillForm(transaction, bill);
  const { saveBill, isLoading, isSuccess } = useSaveBill();
  const context = "components.billForm";
  const navigate = useNavigate();
  const event = useTracking();

  useEffect(() => {
    setLoading(isLoading);
    if (isSuccess) {
      event("payables_bill_save_clicked");
      dispatchToast({
        type: "success",
        content: t(`${context}.toast.saveSuccess`),
      });
      navigate("/expenseManagement/expenses");
    }
  }, [isLoading, isSuccess]);

  if (configIsLoading || billIsLoading) {
    return <Skeleton width={"100%"} height={"150px"} />;
  }

  return (
    <form
      id="bill-form"
      onSubmit={methods.handleSubmit((e) => {
        saveBill(e);
      })}
    >
      <FormProvider {...methods}>
        <Container>
          <FormContainer>
            <Container style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
              <Typography variant="headline8">
                {t(`${context}.title`)}
              </Typography>
              <Typography variant="body3">
                {t(`${context}.subtitle`)}
              </Typography>
            </Container>
            <DropContainer>
              <Controller
                control={methods.control}
                name="documentFile"
                defaultValue={undefined}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <FlexRowContainer>
                      {!!error && (
                        <ShapeIcon
                          name="IconExclamationMark"
                          variant="error"
                          stroke="error"
                          size={32}
                        />
                      )}
                      <Typography variant="body3" weight={600}>
                        {t(`${context}.documentAttachment`)}
                        {!!error && (
                          <Typography variant="body4">Obrigatório!</Typography>
                        )}
                      </Typography>
                    </FlexRowContainer>
                    <PdfDropZone
                      document={value}
                      onChange={(files) => {
                        if (files?.previewUrl)
                          methods.setValue(
                            "document.s3Path",
                            files?.previewUrl
                          );
                        else methods.resetField("document");
                        onChange(files);
                      }}
                    />
                  </>
                )}
              />
            </DropContainer>
            <Container>
              <BillFormFields />
            </Container>
          </FormContainer>
          {rules["paymentTerms.paymentMethod"]?.visible && (
            <BillFormPaymentMethod />
          )}
          {rules?.attachments?.visible && (
            <CustomContainer>
              <Controller
                control={methods.control}
                name="attachmentFiles"
                defaultValue={[]}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <div>
                      <FlexRowContainer>
                        {!!error && (
                          <ShapeIcon
                            name="IconExclamationMark"
                            variant="error"
                            stroke="error"
                            size={32}
                          />
                        )}
                        <Typography variant="headline8" weight={700}>
                          {t(`${context}.attachment.title`)}
                          {!!error && (
                            <Typography variant="body4">
                              Obrigatório!
                            </Typography>
                          )}
                        </Typography>
                      </FlexRowContainer>
                      <Typography variant="caption">
                        {t(`${context}.attachment.subtitle`)}
                      </Typography>
                    </div>
                    <StyledDropZone
                      initialFiles={value?.map((v, i) => {
                        return {
                          file: v.file as File,
                          id: `${i}`,
                          loaded: true,
                          progress: 100,
                          error: false,
                          mime: v.previewUrl,
                        };
                      })}
                      span={value?.length || 0}
                      multiple
                      title="Anexos"
                      onChange={(files) => {
                        onChange(
                          files.map((file) => {
                            return { ...file, previewUrl: file?.mime };
                          })
                        );
                      }}
                    />
                  </>
                )}
              />
            </CustomContainer>
          )}
        </Container>
      </FormProvider>
    </form>
  );
}
