export default {
  translations: {
    pages: {
      vendorList: {
        title: "Fornecedores",
        subtitle: "Adicione os fornecedores para o lançamento de despesas.",
        breadCrumbs: {
          vendors: "Fornecedores",
        },
        headerAction: "Adicionar Fornecedor",
      },
      vendorForm: {
        title: "Adicionar fornecedor",
        subtitle:
          "Insira os dados do fornecedor, e anexe documentos que possam ser relevantes ao fornecedor.",
        breadCrumbs: {
          vendors: "Fornecedores",
          addVendor: "Adicionar fornecedor manualmente",
        },
        actions: {
          save: "Salvar",
          cancel: "Cancelar",
        },
        tips: {
          vendorContact: "Contato de funcionário na empresa fornecedora",
        },
        companyContacts: "Contato dentro do fornecedor",
        responsibleContacts: "Contato do responsável interno pelo fornecedor",
        address: "Endereço",
        addressSubTitle: "Preencha as informações de endereço do fornecedor.",
        bankData: "Dados de pagamento",
        bankDataSubtitle:
          "Informações que servirão para o pagamento do fornecedor.",
        toggle: {
          title: "Ativar fornecedor",
          helperText:
            "Quando ativo, o fornecedor aparece como uma opção na hora de lançar uma despesa.",
        },
        save: "Salvar",
        cancel: "Cancelar",
        success: "Fornecedor adicionado com sucesso!",
        error: {
          title: "Não foi possível adicionar o fornecedor.",
          subtitle: "Por favor, tente novamente dentro de alguns instantes.",
        },
        fields: {
          required: "Campo obrigatório",
          code: {
            label: "Código",
            helperText:
              "Insira o código utilizado pela sua empresa para melhor controle.",
          },
          registrationNumber: {
            label: "CNPJ/CPF",
          },
          legalName: {
            label: "Razão social",
          },
          name: {
            label: "Nome fantasia",
          },
          stateSubscription: {
            label: "Inscrição estadual",
          },
          citySubscription: {
            label: "Inscrição municipal",
          },
          lineOfBusiness: {
            label: "Ramo de atividade",
          },
          vendorEmail: {
            label: "E-mail da empresa",
          },
          vendorPhone: {
            label: "Telefone (com DDD)",
          },
          contactName: {
            label: "Nome completo",
          },
          contactLastName: {
            label: "Sobrenome",
          },
          contactPhone: {
            label: "Telefone (com DDD)",
          },
          contactEmail: {
            label: "E-mail",
          },
          country: {
            label: "País",
          },
          postalCode: {
            label: "CEP",
          },
          street: {
            label: "Logradouro",
          },
          number: {
            label: "Número",
            checkbox: "Endereço sem número",
          },
          complement: {
            label: "Complemento",
          },
          neighborhood: {
            label: "Bairro",
          },
          state: {
            label: "Estado",
          },
          city: {
            label: "Cidade",
          },
          paymentMethod: {
            label: "Forma de pagamento",
            value: "Cartão Corporativo Flash",
          },
          bank: {
            label: "Instituição Bancaria",
          },
          accountType: {
            label: "Tipo de conta",
          },
          bankAgency: {
            label: "Agência",
          },
          bankAccount: {
            label: "Conta",
          },
          pixKey: {
            label: "Chave pix",
          },
          responsible: {
            label: "Colaborador responsável pelo fornecedor",
          },
          pixKeyType: {
            label: "Tipo da chave pix",
          },
          card: {
            label: "Cartão",
          },
        },
      },
      vendorDetail: {
        breadCrumbs: {
          vendors: "Fornecedores",
          details: "Detalhes do fornecedor",
        },
      },
      billForm: {
        pageTitle: "Adicionar despesa com fornecedor",
        transactionStepTitle: "Transação de cartão",
        transactionSubtitle:
          "Selecione a transação do seu cartão corporativo Flash que servirá como base para a despesa criada.",
        formStepTitle: "Detalhes da despesa",
        formSubtitle:
          "Preencha as informações da despesa com fornecedor e inclua eventuais documentos que possam ser relevantes para o pagamento.",
        breadCrumbs: {
          expenses: "Despesas",
          addBill: "Adicionar despesas com fornecedor",
          editBill: "Editar despesas com fornecedor",
        },
        editTitle: "Editar despesa com fornecedor.",
        editSubtitle:
          "Edite as informações da despesa com fornecedor e inclua eventuais documentos que possam ser relevantes para o pagamento.",
        fields: {
          currency: { label: "Moeda" },
          amount: { label: "Valor líquido" },
          vendor: { label: "Fornecedor" },
          issueAt: { label: "Data de emissão" },
          dueDate: { label: "Data de vencimento" },
          documentNumber: { label: "Número da nota fiscal" },
          category: { label: "Categoria" },
          costCenter: { label: "Centro de custo" },
          description: { label: "Justificativa do pagamento" },
        },
      },
      billDetails: {
        breadCrumbs: {
          expenses: "Despesas",
          details: "Detalhes da despesa",
        },
        footer: {
          cancel: "Cancelar",
          sendToAccount: "Enviar para contabilização",
          sendToApprove: "Enviar para aprovação",
          back: "Voltar",
          approve: "Aprovar",
          reject: "Reprovar",
        },
        tabs: {
          details: "Detalhes",
          history: "Histórico",
        },
        rejectAlert: "Motivo da reprovação",
        errorAlert: "Motivo do Erro",
      },
      approvalFlow: {
        title: "Gerenciar aprovações",
        tabs: {
          payables: "Despesas com fornecedor",
        },
      },
      approvalFlowForm: {
        titleAdd: "Adicionar fluxo de aprovação despesa com fornecedor",
        titleEdit: "Editar fluxo de aprovação despesa com fornecedor",
        breadCrumbs: {
          customization: "Personalizações",
          addApprovalFlow: "Adicionar fluxo de aprovação",
        },
        steps: {
          one: "Informações básicas",
          two: "Tipo de aprovação",
          three: "Resumo",
        },
      },
      approvalFlowDetails: {
        breadCrumbs: {
          customization: "Personalizações",
          details: "Detalhe do fluxo de aprovação",
        },
        tabs: {
          details: "Detalhes",
          history: "Histórico",
        },
      },
      accounting: {
        title: "Despesas com fornecedor",
        breadCrumbs: {
          accounting: "Contabilizações",
          payablesAccounting: "Despesas com fornecedor",
        },
      },
    },
    components: {
      vendorGrid: {
        columns: {
          vendor: "Fornecedor",
          vendorCode: "Código",
          businessLine: "Ramo de atividade",
          status: "status",
          actions: "Ações",
          paymentMethods: "Pagamento",
          pendingPayments: "Pagamento pendentes",
          responsible: "Responsável interno",
        },
        actions: {
          openVendor: "Ver detalhes do fornecedor",
          editVendor: "Editar fornecedor",
          addBill: "Prestar conta de despesa com fornecedor",
          deactivateVendor: "Desativar fornecedor",
          activateVendor: "Ativar fornecedor",
          deleteVendor: "Excluir fornecedor",
          deactivate: "Desativar",
          activate: "Ativar",
          delete: "Excluir",
        },
        messages: {
          deleteError: "Erro ao excluir",
          deleteSuccess: "Fornecedor Excluído com sucesso",
          batchDeleteError: "Não foi possível excluir o fornecedor.",
          toggleDeactivateError: "Não foi possível desativar o fornecedor.",
          toggleDeactivateSuccess: "Fornecedor desativado com sucesso!",
          toggleActiveError: "Não foi possível ativar o fornecedor.",
          toggleActiveSuccess: "Fornecedor ativado com sucesso!",
          subtitle: "Por favor, tente novamente dentro de alguns instantes.",
          count: "{1} de {2} selecionados",
        },
      },
      VendorActionModal: {
        attention: "Atenção!",
        cancel: "Cancelar",
        deactivate: {
          title: "Tem certeza que deseja desativar esse fornecedor?",
          subtitle:
            "Ao desativar, não será mais possível selecioná-lo na criação de uma despesa.",
          confirm: "Confirmar desativação",
        },
        delete: {
          title: "Tem certeza que deseja excluir esse fornecedor?",
          subtitle:
            "Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.",
          confirm: "Confirmar exclusão",
        },
        actions: {
          openVendor: "Ver fornecedor",
          addBill: "Adicionar despesa",
          deactivateVendor: "Desativar fornecedor",
          activateVendor: "Ativar fornecedor",
          deleteVendor: "Excluir fornecedor",
        },
      },
      vendorFilters: {
        status: "Status",
        lineOfBusiness: "Ramo de atividade",
        country: "País",
        searchLabel: "Buscar por fornecedor",
      },
      vendorDetails: {
        addBill: "Adicionar despesas",
        tabs: {
          details: "Detalhes",
          bills: "Despesas",
          history: "Histórico",
        },
        detailsTab: {
          titles: {
            baseData: "Dados Cadastrais",
            vendorContact: "Contato na empresa",
            responsibleContact:
              "Contato do responsável interno pelo fornecedor",
            address: "Endereço",
            attachment: "Anexos",
            paymentData: "Dados de pagamento",
          },
          sections: {
            baseData: "Dados Cadastrais",
            contactData: "Contato na empresa",
            responsible: "Contato do responsável interno pelo fornecedor",
            attachment: "Anexos",
            address: "Endereço",
            payment: "Dados de pagamento",
          },
          fields: {
            status: "Status",
            registrationNumber: "CPF/CNPJ",
            name: "Nome fantasia",
            stateSubscription: "Inscrição estadual",
            email: "E-mail",
            code: "Código",
            legalName: "Razão Social",
            citySubscription: "Inscrição municipal",
            lineOfBusiness: "Ramo de atividade",
            phone: "Telefone",
            contactName: "Nome completo",
            country: "País",
            street: "Logradouro",
            postalCode: "CEP",
            number: "Número",
            complement: "Complemento",
            paymentType: "Forma de pagamento",
            bank: "Instituição bancaria",
            accountType: "Tipo de conta",
            agency: "Agencia",
            account: "Conta",
            pixType: "Tipo de chave pix",
          },
        },
      },
      transactionList: {
        grid: {
          description: "Descrição",
          value: "Valor",
          date: "Data e hora",
          action: "Ações",
        },
        rowAction: {
          button: "Prestar conta",
          addCorporateExpense: "Prestar conta de despesa corporativa",
          addCompanyPayables: "Prestar conta de despesa com fornecedor",
        },
      },
      billForm: {
        title: "Documento fiscal",
        subtitle: "Preencha as informações relacionadas ao documento fiscal.",
        documentAttachment: "Documento fiscal",
        payment: {
          title: "Dados de pagamento",
          subtitle:
            "Preencha as informações referentes ao pagamento do fornecedor.",
          paymentForm: "Forma de pagamento",
          issuer: "Emissora",
          card: "Cartão",
        },
        toast: {
          saveSuccess: "Despesa salva com sucesso!",
          sendToAccountSuccess:
            "Despesa enviada para contabilização com sucesso!",
          error: {
            title: "Não foi possível adicionar a despesa.",
            content: "Por favor, tente novamente dentro de alguns instantes.",
          },
        },
        attachment: {
          title: "Anexo de despesa",
          subtitle:
            "Caso necessário, adicione outros documentos relevantes para o pagamento.",
        },
      },
      billWorkFlow: {
        buttons: {
          cancel: "Cancelar",
          next: "Continuar",
          save: "Salvar",
          saveAndAdd: "Salvar e adicionar nova",
          sendToAccount: "Enviar para contabilização",
        },
        toast: {
          selectWarning: "Selecione uma transação",
        },
      },
      billActions: {
        details: "Ver detalhes de despesa",
        edit: "Editar despesa",
        sendToAccount: "Enviar para contabilização",
        alterToCorporate: "Alterar para despesa corporativa",
        submit: "Enviar para aprovação",
        reopen: "Reabrir despesa",
      },
      billGrid: {
        vendor: "Fornecedor",
        category: "Categoria",
        dueDate: "Fatura",
        amount: "Valor",
        paymentType: "Pagamento",
        costCenter: "Centro de custo",
        status: "Status de pagamento",
        action: "Ações",
      },
      billGridFilters: {
        labels: {
          vendor: "Fornecedor",
          status: "Status",
          category: "Categoria",
          amount: "Valor",
          dueDate: "Fatura",
        },
        status: {
          open: "Aberto",
          pending: "Contabilização Pendente",
          approved: "Contabilizado",
          rejected: "Reprovado",
        },
      },
      billDetailsTab: {
        document: "Documento fiscal",
        paymentData: "Dados de pagamento",
        transaction: "Transação",
        attachments: "Anexos",
        fields: {
          status: "Status",
          amount: "Valor",
          vendor: "Fornecedor",
          issueDate: "Data de emissão",
          documentNumber: "Número da nota fiscal",
          dueDate: "Data de vencimento",
          category: "Categoria",
          costCenter: "Centro de custo",
          description: "Justificativa do pagamento",
          paymentMethod: "Forma de pagamento",
          pixKeyType: "Chave de acesso",
          bank: "Instituição bancaria",
          accountType: "Tipo de conta",
          agency: "Agência",
          account: "Conta",
        },
        approvers: {
          label: "Aprovadores",
          status: {
            APPROVED: "Aprovado",
            REJECTED: "Reprovado",
            PENDING: "Pendente",
          },
        },
      },
      attachmentPreview: {
        title: "Visualizar Anexos",
      },
      billActionModal: {
        attention: "Atenção!",
        cancel: "Cancelar",
        account: {
          title:
            "Tem certeza que deseja alterar o status da despesa para contabilizado?",
          subtitle: "A despesa será concluída e não poderá ser editada.",
          confirm: "Contabilizar",
        },
        reclassification: {
          title: "Tem certeza que deseja alterar a despesa para corporativa?",
          subtitle:
            "A despesa deixará de ser uma despesa com fornecedor, para ser uma despesa corporativa.",
          confirm: "Confirmar",
        },
        delete: {
          title: "Tem certeza que deseja excluir essa despesa?",
          subtitle:
            "Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.",
          confirm: "Confirmar exclusão",
        },
        reject: {
          title: "Tem certeza que deseja reprovar a despesa?",
          subtitle:
            "Após reprovado, ele poderá ser reaberto, ajustado, e submetido novamente para contabilização.",
          confirm: "Confirmar reprovação",
          label: "Justifique a reprovação",
          placeholder: "Digite o motivo.",
        },
        approve: {
          title: "Tem certeza que deseja aprovar a despesa?",
          batchTitle: "Tem certeza que deseja aprovar as $ despesas?",
          subtitle:
            "As despesas serão encaminhados para o fluxo de aprovação do financeiro.",
          confirm: "Aprovar",
        },
        approvalReject: {
          title: "Tem certeza que deseja reprovar a despesa?",
          batchTitle: "Tem certeza que deseja reprovar as $ despesas?",
          subtitle:
            "Após reprovada, o solicitante poderá editar e realizar as alterações para enviar para aprovação novamente.",
          reason: "Selecione um motivo para a reprovação (opcional)",
          confirm: "Confirmar Reprovação",
          placeholder: "Digite o motivo.",
          label: "Motivo",
        },
        toast: {
          submit: {
            success: {
              title: "Despesa enviada para aprovação com sucesso!",
            },
            error: {
              title: "Não foi possível enviar a despesa para aprovação",
              message: "Por favor, tente novamente dentro de alguns instantes.",
            },
          },
          sendToAccount: {
            success: {
              title: "Despesa enviada para contabilização com sucesso!",
            },
            error: {
              title: "Não foi possível enviar a despesa para contabilização",
              message: "Por favor, tente novamente dentro de alguns instantes.",
            },
          },
          account: {
            success: {
              title: "Despesa contabilizada com sucesso!",
            },
            error: {
              title: "Não foi possível contabilizar a despesa",
              message: "Por favor, tente novamente dentro de alguns instantes.",
            },
          },
          reject: {
            success: {
              title: "Despesa reprovada com sucesso!",
            },
            error: {
              title: "Não foi possível reprovar a despesa",
              message: "Por favor, tente novamente dentro de alguns instantes.",
            },
          },
          reopen: {
            success: {
              title: "Despesa reaberta com sucesso!",
            },
            error: {
              title: "Não foi possível reabrir a despesa",
              message: "Por favor, tente novamente dentro de alguns instantes.",
            },
          },
        },
      },
      billGridActions: {
        details: "Ver detalhes",
        account: "Contabilizar",
        reject: "Reprovar",
      },
      billConfiguration: {
        title: "Formulário",
        subtitle:
          "Configure os campos que serão exibidos e obrigatórios no formulário de lançamento de despesas.",
        showForm: "Pré-visualizar",
        keepAllRequired: "Manter todos os campos obrigatórios desse formulário",
        labels: {
          currency: "Moeda",
          issuedAt: "Data da Fatura",
          dueAt: "Data de vencimento",
          "document.number": "Numero da nota fiscal",
          categoryId: "Categoria",
          costCenterId: "Centro de custo",
          description: "Justificativa de pagamento",
          "paymentTerms.paymentMethod": "Forma de pagamento",
          required: "Preenchimento obrigatório",
          vendor: "Fornecedor",
          amount: "Valor",
        },
        fieldTypes: {
          dropdown: "Campo de dropdown",
          date: "Campo de data",
          attachment: "Anexo",
          shortText: "Campo de texto curto",
          longText: "Campo de texto longo",
        },
        previewModal: {
          title: "Pré-visualização",
          subtitle:
            "Ao lançar uma despesa com fornecedor, é assim que a seção será visualizada.",
          document: "Documento Fiscal",
          payment: "Dados de pagamento",
          attachments: "Anexos",
          close: "Fechar",
        },
      },
      alertModal: {
        attention: "Atenção",
        cancel: "Cancelar",
      },
      categoryConfiguration: {
        title: "Categorias",
        subtitle:
          "Configure as categorias que estarão disponíveis para o lançamento de despesas com fornecedor.",
        alertModal: {
          title: "Tem certeza que deseja desativar essa categoria?",
          deleteTitle: "Tem certeza que deseja excluir essa categoria?",
          subtitle:
            "Ao desativar, a categoria não será mais uma opção ao lançar as despesas.",
          deleteSubtitle: "Ao excluir, não será possível recuperar a categoria",
          confirm: "Confirmar desativação",
          deleteConfirm: "Confirmar exclusão",
        },
        activatedAll: "Ativar todas",
        deactivatedAll: "desativas todas",
        editModal: {
          title: "Editar categoria",
          labels: {
            name: "Nome da categoria",
            code: "Código",
            codeHelperText:
              "Insira o código utilizado pela sua empresa para melhor controle. O uso desse código facilita a conciliação de relatórios exportados pela nossa plataforma",
            toggle: "Ativar categoria",
            toggleHelperText:
              "Quando ativa, a categoria aparece como uma opção ao lançar uma despesa.",
          },
          cancel: "Cancelar",
          save: "Salvar",
        },
        messages: {
          deactivatedSuccess: "Categoria desativada com sucesso",
          activatedSuccess: "Categoria ativada com sucesso",
          deactivatedError: "Não foi possível desativar a categoria",
          activatedError: "Não foi possível ativar a categoria",
          tryAgain: "Por favor, tente novamente dentro de alguns instantes.",
          updateSuccess: "Categoria atualizada com sucesso",
          updateError: "Erro ao desativar categoria",
          createSuccess: "Categoria criada com sucesso",
          createError: "Erro ao criar categoria",
          deleteSuccess: "Categoria excluída com sucesso",
          deleteError: "Erro ao excluir categoria",
          toggleAllSuccess: "Categorias atualizadas com sucesso",
          toggleAllError: "Erro ao atualizar as categorias",
        },
        add: "Criar categoria",
      },
      newsBanner: {
        notification: {
          title: "Novidade!",
          content:
            "Agora temos uma área dedicada à Despesas com Fornecedores, onde é possível prestar contas de pagamento feito à fornecedores, lançar um Contas a Pagar e cadastrar um novo Fornecedor.",
        },
        clickHere: "Clique aqui",
      },
      onboarding: {
        buttons: {
          back: "Voltar",
          next: "Próximo",
          finish: "Finalizar",
          noThanks: "Não, obrigado",
          letsGo: "Vamos, lá",
        },
        introduction: {
          news: "Novidade!",
          title: "Despesas com fornecedor",
          subtitle:
            "Agora sua empresa pode lançar despesas com fornecedor ou prestar conta de transações.",
          content: "Quer entender como? Vamos te mostrar!",
        },
        steps: {
          addVendor: {
            title: "Adicione fornecedores",
            description: "Preencha os dados dos fornecedores",
          },
          createTransactionBill: {
            title: "Prestação de conta",
            description:
              "Na aba transação sem prestação de conta, classifique o gasto do cartão corporativo entre despesa corporativa (ex.: ) ou despesa com fornecedor (ex.: software/materiais/internet/contas de água, luz, etc",
          },
          createBill: {
            title: "Adicionar despesas com fornecedor",
            description:
              "Além de prestar conta, é possível adicionar despesa com fornecedor diretamente pelo botão na página principal",
          },
          fillBillForm: {
            title: "Preencha as informações",
            description:
              "Preencha os dados da despesa, e envie para aprovação do gestor.",
          },
          accounting: {
            title: "Contabilize despesas",
            description:
              "Acompanhe as contabilizações pendentes, e contabilize diretamente pela tabela",
          },
        },
        finishing: {
          allRight: "Tudo certo!",
          title: "Agora é só criar seu primeiro fornecedor!",
          subtitle:
            "Se você tiver dúvidas ou quiser saber mais sobre , entre em contato com nosso suporte.",
        },
      },
      transactionTab: {
        notification: {
          title: "Novidade!",
          content:
            "Agora você poderá realizar a prestação de contas de maneira simples e separada entre Despesas Corporativas e Despesas com Fornecedor (para pagamentos realizados à fornecedores).",
        },
      },
      billFormPaymentMethod: {
        title: "Formas de pagamento",
        subtitle:
          "Selecione dentro das formas de pagamento cadastradas aceitas pelo fornecedor, como será ou foi o pagamento dessa despesa.",
        wasPaid: "A despesa já foi paga?",
        yes: "Sim",
        no: "Não",
        wasPaidBy: "Qual foi a forma de pagamento?",
        willBePaidBy: "Qual será a forma de pagamento?",
        warning:
          "Selecione o fornecedor para visualizar as formas de pagamento que ele aceita. Selecione o fornecedor para visualizar as formas de pagamento aceitas.",
      },
      approvalFlowGrid: {
        title: "Fluxo de aprovação",
        subtitle:
          "Configure o fluxo de aprovação para as despesas com fornecedores.",
        filters: {
          search: "Buscar por fluxo",
          status: {
            label: "Status",
            active: "Ativo",
            inactive: "Inativo",
          },
          approvalType: {
            label: "Tipo de aprovação",
            custom: "Aprovação por níveis",
            automatic: "Aprovação automática",
          },
        },
        reorder: "Reordenar prioridade",
        emptyFilter: "Você ainda não adicionou nenhum fluxo de aprovação",
        addButton: "Adicionar fluxo +",
        disableFlow: "Desativar fluxo",
        deleteFlow: "Excluir fluxo",
        columns: {
          priority: "Prioridade",
          name: "Nome do Fluxo",
          description: "Descrição",
          approvalType: "Tipo de aprovação",
          status: "Status",
          actions: "Ações",
          custom: "Aprovação por níveis",
          automatic: "Aprovação automática",
        },
      },
      approvalFlowActions: {
        details: "Ver detalhes do fluxo",
        edit: "Editar fluxo",
        copy: "Duplicar fluxo",
        delete: "Excluir fluxo",
        active: "Ativar Fluxo",
        inactive: "Desativar fluxo",
      },
      approvalFlowForm: {
        leftButtons: {
          exit: "Sair sem salvar",
          draft: "Sair e salvar rascunho",
        },
        rightButtons: {
          next: "Continuar",
          back: "Voltar",
          save: "Salvar",
        },
        stepTitles: {
          one: {
            title: "Informações básicas",
            subtitle:
              "Adicione as informações básicas do fluxo de aprovação, e quais serão os critérios para ativação.",
          },
          two: {
            title: "Tipo de aprovação",
            subtitle:
              "Selecione de que forma acontecerá a aprovação pelos aprovadores.",
          },
          three: {
            title: "Resumo",
            subtitle:
              "Revise as informações do fluxo de aprovação. Apenas prossiga após de confirmar as definições, caso necessário, retorne para a edição. ",
          },
        },
        warning: {
          title: "Importante:",
          one: "Quando o solicitante também for o aprovador do relatório, a aprovação é realizada de forma automática, seguindo para os demais aprovadores se houver.",
          two: "Se um aprovador estiver envolvido em várias etapas do fluxo ele só precisará aprovar uma vez.",
          three:
            "Caso o relatório atenda o critério de 2 fluxos de aprovação, ele irá requisitar apenas o primeiro nível de aprovação.",
        },
        stepOne: {
          basicInformation: "Informações básicas",
          required: "*campo obrigatório",
          fields: {
            name: "Nome do fluxo",
            description: "Descrição",
            priority: "Prioridade",
            changePriority: "Reordenar prioridade",
            orderTip:
              "Caso deseje criar um fluxo com alguma prioridade já utilizada, reordene os fluxos. A prioridade determina qual fluxo será aplicado em casos de conflito",
          },
          criteria: {
            title: "Critérios de aprovação",
            subtitle:
              "Insira critérios que quando atingidos, acionarão o fluxo de aprovação.",
            when: "Quando",
            and: "e",
            condition: "Condicional",
            addCriteria: "Adicionar condicional +",
          },
        },
        stepTwo: {
          byLevel: {
            title: "Aprovação por níveis",
            subtitle:
              "Defina uma sequência e estabeleça os critérios para os aprovadores do fluxo. Para aprovação por nível de hierarquia os cargos das pessoas precisam estar configurados corretamento",
          },
          automatic: {
            title: "Aprovação automática",
            subtitle:
              "Ao submeter a despesa, ela é enviado automaticamente para o financeiro, sem a necessidade de aprovação por algum aprovador.",
          },
          condition: {
            fields: {
              criteria: "Critério",
              person: "Pessoa",
              hierarchyLevel: "Níveis de hierarquia",
            },
            addCondition: "Adicionar nível +",
          },
        },
        messages: {
          create: "Fluxo adicionado com sucesso!",
          createError: "Não foi possível adicionar o fluxo",
          tryAgain: "Por favor, tente novamente dentro de alguns instantes",
          update: "Fluxo alterado com sucesso!",
          updateError: "Não foi possível alterar o fluxo",
        },
      },
      approvalFlowSummary: {
        basicData: {
          title: "Informações básicas",
          fields: {
            name: "Nome do Fluxo",
            description: "Descrição",
            priority: "Prioridade",
          },
        },
        criteria: {
          title: "Critérios",
          fields: {
            when: "Quando",
            amount: "valor",
            category: "Categoria é",
          },
        },
        approvalType: {
          custom: "Aprovação por níveis",
          auto: "Aprovação Automática",
        },
        condition: {
          title: "Tipo de aprovação",
          fields: {
            hierarchy: "Aprovação por hierarquia -",
            level: "nível",
            levels: "níveis",
            ccOwner: "Responsável pelo centro de custo",
          },
        },
      },
      approvalFlowActionModal: {
        attention: "Atenção",
        inactive: {
          confirm: "Confirmar desativação",
          title: "Tem certeza que deseja desativar esse fluxo",
          subtitle:
            "Ao desativar, os fluxos não serão aplicados nas despesas, mas podem ser ativados quando desejar.",
          success: "Fluxos desativados com sucesso!",
          error: "Não foi possível desativar o fluxo",
        },
        active: {
          success: "Fluxos ativado com sucesso!",
          error: "Não foi possível ativar o fluxo",
        },
        delete: {
          confirm: "Confirmar exclusão",
          title: "Tem certeza que deseja excluir esse fluxo?",
          subtitle:
            "Todos os dados relacionados a ele serão perdidos e essa ação não poderá ser desfeita.",
          success: "Fluxo excluído com sucesso!",
          error: "Não foi possível excluir o fluxo",
        },
        tryAgain: "Por favor, tente novamente dentro de alguns instantes.",
        cancel: "Cancelar",
      },
      approvalPriority: {
        title: "Reordenar prioridade dos fluxos",
        subtitle:
          "Arraste as linhas e reordene os fluxos de acordo com a prioridade desejada.",
        cancel: "Cancelar",
        save: "Salvar",
        success: "Prioridade Reordenada com sucesso",
        error: "Erro ao reordenar as prioridades",
        tryAgain: "Por favor, tente novamente dentro de alguns instantes.",
      },
      configurationTab: {
        forms: "Formulários",
        approvalFlow: "Fluxo de aprovação",
        categories: "Categorias",
      },
      approvalsList: {
        columns: {
          vendor: "Fornecedor",
          dueDate: "Data de vencimento",
          amount: "Valor",
          status: "Status",
          category: "Categoria",
          action: "Ações",
        },
        filters: {
          labels: {
            status: "Status",
            dueDate: "Data de vencimento",
            category: "Categoria",
            vendor: "Fornecedor",
            requester: "Solicitante",
          },
          status: {
            approvePending: "Aprovação pendente",
            rejected: "Reprovado",
            accountingPending: "Contabilização pendente",
            accounted: "Contabilizado",
          },
        },
        actions: {
          details: "Ver detalhes",
          aprove: "Aprovar",
          reject: "Reprovar",
        },
        toast: {
          approve: {
            success: "Despesa aprovada com sucesso",
            error: "Erro ao aprovar despesa",
            batchSuccess: "Despesas aprovadas com sucesso",
            batchError: "Erro ao aprovar despesas",
          },
          approvalReject: {
            success: "Despesa reprovada com sucesso",
            batchSuccess: "Despesas reprovadas com sucesso",
            error: "Erro ao reprovar despesa",
            batchError: "Erro ao reprovar despesas",
          },
          tryAgain: "Por favor, tente novamente dentro de alguns instantes.",
        },
      },
    },
  },
};
