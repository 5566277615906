import { zodResolver } from "@hookform/resolvers/zod";
import { toCurrency } from "@utils/toCurrency";
import { useForm } from "react-hook-form";
import { ApprovalFlow, approvalFlowSchema } from "../approval-flow-schema";

export function useApprovalFlowForm(
  approvalFlow?: ApprovalFlow,
  isCopy?: boolean
) {
  if (isCopy) {
    delete approvalFlow?.priority;
    delete approvalFlow?.id;
  }
  const methods = useForm<ApprovalFlow>({
    resolver: zodResolver(approvalFlowSchema),
    defaultValues: approvalFlow
      ? {
          ...approvalFlow,
          criteria: approvalFlow.criteria.map((c) => {
            if (c.type === "EXPENSE_AMOUNT") {
              c.value = toCurrency(parseInt(c.value), true);
            }
            return c;
          }),
        }
      : {
          documentType: "BILL",
          status: "ACTIVE",
          criteria: [{ relationshipCondition: "AND" }],
          condition: [],
        },
  });
  return { methods };
}
