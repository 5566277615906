import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xl4} 0 ${theme.spacings.xl4}`};
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;
