import { useFormContext } from "react-hook-form";
import { useApprovalFlowSummary } from "../../approval-flow-details/summary/hooks/useApprovalFlowSummary";
import { ApprovalFlow } from "../approval-flow-schema";

export function useFormSummary() {
  const { getValues } = useFormContext<ApprovalFlow>();
  const { buildFields } = useApprovalFlowSummary();

  const approvalFlow = getValues();

  const data = buildFields(approvalFlow);

  return data;
}
