import {
  Button,
  DatePicker,
  Dropzone,
  Modal,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { SelectCategory } from "@organisms/category-select";
import { SelectCostCenter } from "@organisms/cost-center-select";
import { PdfDropZone } from "@organisms/pdf-drop-zone";
import { SelectVendor } from "@organisms/select-vendor/selectVendor";
import { useTranslation } from "react-i18next";
import { FieldType } from "..";
import {
  Container,
  ContentContainer,
  Fields,
  HeaderContainer,
  ScrollContainer,
  Span,
  StyledDivider,
  StyledTextField,
} from "./styled";
type PreviewModalProps = {
  rules: FieldType["rule"][];
  open: boolean;
  onClose: () => void;
};

export function PreviewFormModal({ rules, open, onClose }: PreviewModalProps) {
  const { t } = useTranslation();
  const context = "components.billConfiguration";

  const fields: Record<string, FieldType["rule"]> = rules?.reduce(
    (acc: Record<string, FieldType["rule"]>, rule) => {
      acc[rule.property] = rule;
      return acc;
    },
    {}
  );

  return (
    <Modal.Root open={open} onClose={onClose} size="md">
      <Container>
        <HeaderContainer>
          <Typography variant="headline6">
            {t(`${context}.previewModal.title`)}
          </Typography>
          <Typography variant="body3">
            {t(`${context}.previewModal.subtitle`)}
          </Typography>
        </HeaderContainer>
        <StyledDivider />
        <ScrollContainer>
          <ContentContainer>
            <Fields>
              <Span>
                <Typography variant="headline8">
                  {t(`${context}.previewModal.document`)}
                </Typography>
                <PdfDropZone
                  height="350px"
                  document={{}}
                  onChange={() => undefined}
                />
              </Span>
              {fields.currency?.visible && (
                <SelectField
                  required={fields.currency?.required}
                  label={t(`${context}.labels.currency`)}
                />
              )}
              <StyledTextField required label={t(`${context}.labels.amount`)} />
              <Span>
                <SelectVendor required label={t(`${context}.labels.vendor`)} />
              </Span>
              <Span>
                <DatePicker
                  required
                  label={t(`${context}.labels.issuedAt`)}
                  onDateChange={() => undefined}
                />
              </Span>
              {fields.dueAt?.visible && (
                <Span>
                  <DatePicker
                    required={fields.dueAt?.required}
                    label={t(`${context}.labels.dueAt`)}
                    onDateChange={() => undefined}
                  />
                </Span>
              )}
              {fields.documentNumber?.visible && (
                <Span>
                  <StyledTextField
                    required={fields.documentNumber?.required}
                    label={t(`${context}.labels.documentNumber`)}
                  />
                </Span>
              )}
              {fields.categoryId?.visible && (
                <Span>
                  <SelectCategory
                    required={fields.categoryId?.required}
                    label={t(`${context}.labels.categoryId`)}
                  />
                </Span>
              )}
              {fields.costCenterId?.visible && (
                <Span>
                  <SelectCostCenter
                    required={fields.costCenterId?.required}
                    label={t(`${context}.labels.costCenterId`)}
                  />
                </Span>
              )}
              {fields.description?.visible && (
                <Span>
                  <StyledTextField
                    required={fields.description?.required}
                    label={t(`${context}.labels.description`)}
                    rows={4}
                  />
                </Span>
              )}
              {fields["paymentTerms.paymentMethod"]?.visible && (
                <Span>
                  <StyledDivider />
                  <Typography variant="headline8">
                    {t(`${context}.previewModal.payment`)}
                  </Typography>
                  <SelectField
                    required={fields.paymentForm?.required}
                    label={t(`${context}.labels.paymentTerms.paymentMethod`)}
                  />
                </Span>
              )}
              {fields.attachments?.visible && (
                <Span>
                  <StyledDivider />
                  <Typography variant="headline8">
                    {t(`${context}.previewModal.attachments`)}
                  </Typography>
                  <Dropzone title="Anexos" accept={["JPG", "PNG", "PDF"]} />
                </Span>
              )}
            </Fields>
          </ContentContainer>
        </ScrollContainer>
        <StyledDivider />
        <div>
          <Button variant="secondary" size="medium" onClick={onClose}>
            {t(`${context}.previewModal.close`)}
          </Button>
        </div>
      </Container>
    </Modal.Root>
  );
}
