import { trpc } from "@client";
import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { getAccessTokenPayloadSync } from "@flash-hros/utility";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";

export function useApproveBill() {
  const {
    closeModal,
    setIsLoading,
    billActionModal: { type },
  } = useBillActionModal();
  const { t } = useTranslation();
  const context = "components.approvalsList.toast";
  const { mutate, isLoading } = trpc.bill.approve.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t(`${context}.${type}.success`),
      });
      closeModal();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t(`${context}.${type}.error`),
        description: t(`${context}.tryAgain`),
      });
      setIsLoading(false);
    },
  });
  const { checkPermission } = usePlatformPermissions();
  const accessToken = getAccessTokenPayloadSync();

  function canApprove(bill: Bill) {
    if (checkPermission(PayablesPermissions.APPROVE_BILL)) {
      //check if no one reject the bill
      if (
        bill.approvalWorkflow?.approvers?.some(
          (approver) => approver.status === "REJECTED"
        )
      ) {
        return false;
      }
      //check if the current bill approver is the user logged in
      const currentApprover = bill.approvalWorkflow?.approvers?.find(
        (approver) => approver.status === "PENDING"
      );

      return currentApprover?.approverId === accessToken?.employeeId;
    }
    return false;
  }

  return {
    canApprove,
    approve: mutate,
    isLoading,
  };
}
