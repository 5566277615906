import { BigNumber } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;
export const RowContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const BigNumberContainer = styled(BigNumber)<{ disabled: boolean }>`
  max-width: none;
  opacity: ${({ disabled }) => (disabled ? "60%" : "100%")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
`;

export const SkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
`;
