// @filename: client.ts
import { getAccessToken, useSelectedCompany } from "@flash-hros/utility";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";
import EnvService from "./env.service";
import { UrlMap } from "./service-constants";

const url = UrlMap[EnvService.BUILD_ENV];

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn();
        await opts.queryClient.invalidateQueries();
      },
    },
  },
});

const queryClient = new QueryClient();
export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const company = useSelectedCompany();

  const trpcClient = trpc.createClient({
    links: [
      httpBatchLink({
        url: `${url}/trpc`,
        async headers() {
          const token = await getAccessToken();
          return {
            authorization: `Bearer ${token}`,
            ["company-id"]: company.selectedCompany.id,
          };
        },
      }),
    ],
  });
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
