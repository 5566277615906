import { trpc } from "@client";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";

export function useApprovalBatch() {
  const {
    closeModal,
    setIsLoading,
    billActionModal: { type },
  } = useBillActionModal();
  const { t } = useTranslation();
  const context = "components.approvalsList.toast";
  const { mutate } = trpc.bill.approvalBatch.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t(`${context}.${type}.batchSuccess`),
      });
      closeModal();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: t(`${context}.${type}.batchError`),
        description: t(`${context}.tryAgain`),
      });
      setIsLoading(false);
    },
  });
  return {
    approvalBatch: mutate,
  };
}
