import { ActionProps } from "@organisms/grid-actions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useApprovalFlowActionModal } from "../../approval-flow-action-modal/approvalModalContext";
import { useDeleteApprovalFlow } from "../../approval-flow-grid/hooks/useDeleteApprovalFlow";
import { useToggleApprovalFlow } from "../../approval-flow-grid/hooks/useToggleApprovalFlow";

export function useApprovalFlowActions() {
  const navigate = useNavigate();
  const { deleteApprovalFlow } = useDeleteApprovalFlow();
  const { toggle } = useToggleApprovalFlow();
  const { setBillActionModal } = useApprovalFlowActionModal();
  const { t } = useTranslation();
  const context = "components.approvalFlowActions";

  function buildActions(
    id: string,
    status: "ACTIVE" | "INACTIVE",
    type: "grid" | "details"
  ) {
    const toggleAction: ActionProps = {
      description:
        status === "INACTIVE"
          ? t(`${context}.active`)
          : t(`${context}.inactive`),
      icon: "IconToggleLeft",
      visible: true,
      onclick: () => {
        if (status === "INACTIVE") {
          setBillActionModal({
            open: true,
            type: "inactive",
            onConfirm: () => toggle(id),
          });
        } else {
          toggle(id);
        }
      },
    };

    const detailAction: ActionProps = {
      description: t(`${context}.details`),
      icon: "IconFileDescription",
      visible: true,
      onclick: () => {
        navigate(`/expense/company-payables/approval-flow/details/${id}`);
      },
    };
    const actions: ActionProps[] = [
      ...(type === "grid" ? [detailAction] : []),
      {
        description: t(`${context}.edit`),
        icon: "IconPencil",
        visible: true,
        onclick: () => {
          navigate(`/expense/company-payables/approval-flow/form/${id}`);
        },
      },
      ...(type === "details" ? [toggleAction] : []),
      {
        description: t(`${context}.copy`),
        icon: "IconCopy",
        visible: true,
        onclick: () => {
          navigate(`/expense/company-payables/approval-flow/form/${id}`, {
            state: { isCopy: true },
          });
        },
      },
      {
        description: t(`${context}.delete`),
        icon: "IconTrash",
        visible: true,
        onclick: () => {
          setBillActionModal({
            open: true,
            type: "delete",
            onConfirm: () => deleteApprovalFlow(id),
          });
        },
      },
    ];

    return actions;
  }

  return { buildActions };
}
