import { GridSelectOptions } from "@organisms/grid-select-options/gridSelectOptions";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import { useGridSelectionActions } from "./hooks/useGridSelectionActions";
type VendorGridSelectedOptionsProps = {
  ids: string[];
  data?: Vendor[];
};
export function VendorGridSelectedOptions({
  ids,
  data,
}: VendorGridSelectedOptionsProps) {
  const getSelectActions = useGridSelectionActions(data);
  return <GridSelectOptions ids={ids} actions={getSelectActions()} />;
}
