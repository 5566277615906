import {
  Button,
  Icons,
  LinkButton,
  Modal,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import {
  ButtonContainer,
  Container,
  ContentContainer,
  StyledDivider,
  StyledIcon,
  StyledTypography,
  SubTitle,
  TypographyWarning,
} from "./styled";

type VendorActionModalProps = {
  type?: "deactivate" | "delete";
  open: boolean;
  onClose(): void;
  onConfirm(): void;
};

export function VendorActionModal({
  type,
  open,
  onClose,
  onConfirm,
}: VendorActionModalProps) {
  const { t } = useTranslation();
  const context = "components.VendorActionModal";

  return (
    <Modal.Root open={open} onClose={onClose}>
      <Container>
        <ContentContainer>
          <StyledIcon
            name="IconAlertCircle"
            variant="error"
            fill="transparent"
          />
          <TypographyWarning variant="body3">
            {t(`${context}.attention`)}
          </TypographyWarning>
          <StyledTypography variant="headline6">
            {t(`${context}.${type}.title`)}
          </StyledTypography>
          <SubTitle variant="body3">
            {t(`${context}.${type}.subtitle`)}
          </SubTitle>
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={onClose}>
              {t(`${context}.cancel`)}
            </LinkButton>
          </div>
          <Button
            size="large"
            variant="secondary"
            variantType="error"
            onClick={onConfirm}
          >
            {t(`${context}.${type}.confirm`)}
            {type === "delete" && <Icons name="IconTrash" fill="transparent" />}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal.Root>
  );
}
