import { trpc } from "@client";
import useUploadBillAttachment from "@common/hooks/useUploadBillAttachment";
import useUploadBillFiscalDocument from "@common/hooks/useUploadBillFiscalDocument";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useBillSchema } from "./useBillSchema";

export function useSaveBill() {
  const uploadFiscalDocument = useUploadBillFiscalDocument();
  const uploadAttachment = useUploadBillAttachment();
  const sendToAccount = trpc.bill.sendToAccount.useMutation();
  const { t } = useTranslation();
  const context = "components.billForm.toast.error";
  const save = trpc.bill.save.useMutation({
    onError() {
      dispatchToast({
        type: "error",
        title: t(`${context}.title`),
        content: t(`${context}.content`),
      });
    },
  });

  const update = trpc.bill.update.useMutation({
    onError() {
      dispatchToast({
        type: "error",
        title: t(`${context}.title`),
        content: t(`${context}.content`),
      });
    },
  });

  const { billSchema } = useBillSchema();
  type BillRequest = z.infer<typeof billSchema>;

  const saveBill = async (bill: BillRequest) => {
    try {
      const fiscalDocument = bill.documentFile?.file;
      if (fiscalDocument) {
        const s3Paths = await uploadFiscalDocument.mutateAsync(fiscalDocument);
        if (s3Paths?.length) {
          bill.document.s3Path = s3Paths[0];
        }
      }
      if (bill.attachmentFiles) {
        for (const { file: attachment } of bill.attachmentFiles) {
          if (attachment && attachment.size > 0) {
            const s3Paths = await uploadAttachment.mutateAsync(attachment);
            bill.attachments = bill.attachments || [];
            if (s3Paths?.length) {
              bill.attachments.push({
                fileName: attachment.name,
                s3Path: s3Paths[0],
              });
            }
          }
        }
      }

      if (bill.id) {
        update.mutateAsync({
          id: bill.id,
          dueAt: bill.dueAt,
          issuedAt: bill.issuedAt,
          document: bill.document,
          attachments: bill.attachments,
          vendorId: bill.vendorId,
          categoryId: bill.categoryId,
          description: bill.description,
          costCenterId: bill.costCenterId,
          amount: bill.amount,
          payment: bill.payment,
        });
      } else {
        save.mutateAsync(bill);
      }
    } catch (error) {}
  };

  return {
    saveBill,
    isLoading:
      save.isLoading ||
      uploadFiscalDocument.isLoading ||
      uploadAttachment.isLoading ||
      update.isLoading,
    isSuccess: save.isSuccess || update.isSuccess,
  };
}
