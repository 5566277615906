import { usePlatformPermissions } from "@common/hooks/usePlatformPermissions";
import { PayablesPermissions } from "@common/permissions.type";
import { ActionProps } from "@organisms/grid-select-options/gridSelectOptions";
import { useSelectedRowsContext } from "@organisms/select-rows-context";
import { useVendorActionModal } from "@organisms/vendor-action-modal/vendorModalContext";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import { useDeleteVendor } from "../hooks/useDeleteVendor";
import { Action, useToggleVendor } from "../hooks/useToggleVendor";

export function useGridSelectionActions(data?: Vendor[]) {
  const [actionType, setActionType] = useState<Action>("activate");
  const { checkPermission } = usePlatformPermissions();
  const { t } = useTranslation();
  const { deleteVendorMutation } = useDeleteVendor(data);
  const { toggleVendorMutation } = useToggleVendor(data);
  const { selectedRows } = useSelectedRowsContext();
  const { setVendorActionModal, closeModal } = useVendorActionModal();

  useEffect(() => {
    const selectedData = data?.filter((_) => selectedRows.includes(_.id));
    setActionType(
      selectedData?.every((_) => _.active) ? "deactivate" : "activate"
    );
  }, [selectedRows, data]);

  const onDeletedClick = () => {
    deleteVendorMutation(selectedRows);
  };

  const onToggleClicked = (action: Action) => {
    toggleVendorMutation(action, selectedRows);
  };

  function getSelectActions() {
    const gridSelectActions: ActionProps[] = [];
    if (checkPermission(PayablesPermissions.VENDOR_CREATE)) {
      gridSelectActions.push({
        iconName: "IconToggleRight",
        label: `${
          actionType === "activate"
            ? t(`components.vendorGrid.actions.activate`)
            : t(`components.vendorGrid.actions.deactivate`)
        }`,
        onClick: () => {
          actionType === "activate"
            ? onToggleClicked(actionType)
            : setVendorActionModal({
                open: true,
                type: actionType,
                onConfirm: () => {
                  onToggleClicked(actionType);
                  closeModal();
                },
              });
        },
      });

      if (checkPermission(PayablesPermissions.VENDOR_DELETE)) {
        gridSelectActions.push({
          iconName: "IconTrash",
          label: t(`components.vendorGrid.actions.delete`),
          onClick: () => {
            setVendorActionModal({
              open: true,
              type: "delete",
              onConfirm: () => {
                onDeletedClick();
                closeModal();
              },
            });
          },
        });
      }
      return gridSelectActions;
    }
    return [];
  }

  return getSelectActions;
}
