import {
  Checkbox,
  IconTypes,
  Icons,
  LinkButton,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedRowsContext } from "@organisms/select-rows-context";
import { useTranslation } from "react-i18next";
import { Container, SelectContainer } from "./styled";

type SelectOptionsProps = {
  ids: string[];
  actions: ActionProps[];
};

export type ActionProps = {
  label: string;
  iconName: IconTypes;
  onClick(): void;
};

export function GridSelectOptions({ ids, actions }: SelectOptionsProps) {
  const { t } = useTranslation();
  const {
    selectedRows,
    selectedAll,
    totalRows,
    toggleSelectAll,
    setTotalRows,
  } = useSelectedRowsContext();

  setTotalRows(ids.length);

  if (!selectedRows?.length) {
    return null;
  }

  return (
    <SelectContainer>
      <Container>
        <Checkbox
          size="small"
          onChange={() => toggleSelectAll(ids)}
          checked={selectedAll}
        />
        <div>
          <Tag variant="primary" disableRipple>
            {t("components.vendorGrid.messages.count")
              .replace("{1}", `${selectedRows.length}`)
              .replace("{2}", `${totalRows}`)}
          </Tag>
        </div>
        <Container>
          {actions.map((action, i) => {
            return (
              <LinkButton
                key={i}
                variant="secondary"
                onClick={() => action.onClick()}
              >
                <Icons name={action.iconName} fill="transparent" />
                {action.label}
              </LinkButton>
            );
          })}
        </Container>
      </Container>
    </SelectContainer>
  );
}
