import { BasePage } from "@organisms/BasePage/BasePage";
import { BillEdit } from "@organisms/bill-edit";
import { useTranslation } from "react-i18next";

export const PageBillEdit = () => {
  const { t } = useTranslation();
  const pageContext = "pages.billForm";

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.editTitle`),
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.expenses`),
            to: "/expenseManagement/expenses",
          },
          {
            label: t(`${pageContext}.breadCrumbs.editBill`),
          },
        ],
      }}
      children={<BillEdit />}
      hasFooter
    />
  );
};
