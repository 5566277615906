import { TextFieldProps } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTextField } from "@molecules/text-field/text-field";

export const RegistrationNumberField = (props: TextFieldProps) => {
  return (
    <StyledTextField
      {...props}
      inputMode="numeric"
      imaskProps={{
        mask: [{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }],
      }}
    />
  );
};
