import { trpc } from "@client";
import {
  Button,
  Dot,
  Skeleton,
  Tab,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { BillsList } from "@organisms/bills-list";
import { GridActions } from "@organisms/grid-actions";
import { SelectedRowsProvider } from "@organisms/select-rows-context";
import { VendorActionModal } from "@organisms/vendor-action-modal/vendorActionModal";
import { useVendorActionModal } from "@organisms/vendor-action-modal/vendorModalContext";
import { useVendorActions } from "@organisms/vendor-grid/hooks/useVendorActions";
import { VendorInformation } from "@organisms/vendor-information";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UseVendorFields } from "./hooks/useVendorFields";
import { Container, HeaderContainer, StyledTag } from "./style";

export function VendorDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = trpc.vendor.get.useQuery(id || "", {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
  const fields = UseVendorFields(data);
  const { buildVendorActions } = useVendorActions();
  const { vendorActionModal, closeModal } = useVendorActionModal();

  const context = "components.vendorDetails";

  return (
    <>
      <VendorActionModal {...vendorActionModal} onClose={closeModal} />
      <HeaderContainer>
        {isLoading && (
          <Skeleton
            animation="pulse"
            variant="rounded"
            width={"100%"}
            height={"80px"}
          />
        )}
        {data && (
          <>
            <Container>
              <Container style={{ width: "100%" }}>
                <div>
                  <Typography variant="headline6">{data?.legalName}</Typography>
                </div>
                <div>
                  <StyledTag
                    hasLeftDotIcon={true}
                    variant={data?.active ? "success" : "error"}
                  >
                    {data?.active ? "Ativo" : "Inativo"}
                  </StyledTag>
                </div>
              </Container>
              <Container>
                <Typography variant="body4">{data?.name}</Typography>
                <Typography variant="body4">
                  {data?.code && (
                    <>
                      <Dot variant="gray" />
                    </>
                  )}
                </Typography>
                <Typography variant="body4">{data.code}</Typography>
              </Container>
            </Container>
            <Container>
              <GridActions
                actions={buildVendorActions(data.id, "page", data.active)}
              />
              <Button
                variant="primary"
                size="large"
                onClick={() => navigate("/expense/company-payables/bills/form")}
              >
                {t(`${context}.addBill`)}
              </Button>
            </Container>
          </>
        )}
      </HeaderContainer>
      <Tab
        variant="primary"
        tabItens={[
          {
            label: t(`${context}.tabs.details`),
            component: fields && data && (
              <VendorInformation fields={fields} vendor={data} />
            ),
          },
          {
            label: t(`${context}.tabs.bills`),
            component: (
              <SelectedRowsProvider>
                <BillActionModalProvider>
                  <BillsList listContext="employee" />
                </BillActionModalProvider>
              </SelectedRowsProvider>
            ),
          },
          // {
          //   label: t(`${context}.tabs.history`),
          //   component: <VendorHistory />,
          // },
        ]}
      />
    </>
  );
}
