import styled from "styled-components";

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
`;
export const RowContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs2};
`;
