import { trpc } from "@client";

export function useCategory(id?: string) {
  const { data, isLoading } = trpc.category.getById.useQuery(id || "", {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
  };
}
