import { countries } from "@common/countries";
import { lineOfBusiness } from "@common/line-of-business";
import { SearchField, TagFilter } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { VendorFilters } from "./useVendorData";
import { useDebounce } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
export function useVendorFilters() {
  const [filters, setFilters] = useState<VendorFilters>({});
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { t } = useTranslation();
  const context = "components.vendorFilters";

  useEffect(() => {
    setFilters({ ...filters, text: debouncedSearchValue });
  }, [debouncedSearchValue]);

  const options = [
    { value: "active", label: "Ativo" },
    { value: "inactive", label: "Inativo" },
  ];

  const filterByStatus = (values: string[]) => {
    if (values.includes("active") && values.includes("inactive")) {
      setFilters({ ...filters, status: "all" });
    } else if (values.includes("active")) {
      setFilters({ ...filters, status: "active" });
    } else if (values.includes("inactive")) {
      setFilters({ ...filters, status: "inactive" });
    }
  };

  const clearStatusFilter = () => {
    const newFilters = { ...filters };
    delete newFilters.status;
    setFilters(newFilters);
  };

  const filterByLineOfBusiness = (values: string[]) => {
    setFilters({ ...filters, lineOfBusiness: values as string[] });
  };

  const clearLineOfBusinessFilter = () => {
    const newFilters = { ...filters };
    delete newFilters.lineOfBusiness;
    setFilters(newFilters);
  };

  const filterByCountry = (values: string[]) => {
    setFilters({ ...filters, country: values as string[] });
  };

  const clearCountryFilter = () => {
    const newFilters = { ...filters };
    delete newFilters.country;
    setFilters(newFilters);
  };

  const filtersList = [
    {
      label: t(`${context}.lineOfBusiness`),
      options: lineOfBusiness,
      onApply: filterByLineOfBusiness,
      onClear: clearLineOfBusinessFilter,
    },
    {
      label: t(`${context}.status`),
      options: options,
      onApply: filterByStatus,
      onClear: clearStatusFilter,
    },
    {
      label: t(`${context}.country`),
      options: countries,
      onApply: filterByCountry,
      onClear: clearCountryFilter,
    },
  ];
  return {
    setSearchValue,
    searchValue,
    filters,
    filtersList,
  };
}
