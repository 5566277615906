import { comparisonListRecord } from "@common/comparison";
import { currencyRecord } from "@common/currency";
import { toCurrency } from "@utils/toCurrency";
import { useTranslation } from "react-i18next";
import { ApprovalFlow } from "../../../approval-flow-form/approval-flow-schema";

export function useApprovalFlowSummary() {
  const { t } = useTranslation();
  const context = "components.approvalFlowSummary";

  function resolveAmountValue(value: string) {
    const cleanValue = value.replace(/[.,]/g, "");
    return toCurrency(parseInt(cleanValue), true);
  }

  function buildFields(approvalFlow: ApprovalFlow) {
    return {
      basicData: [
        {
          label: t(`${context}.basicData.fields.name`),
          value: approvalFlow.name,
        },
        {
          label: t(`${context}.basicData.fields.description`),
          value: approvalFlow.description,
        },
        {
          label: t(`${context}.basicData.fields.priority`),
          value: approvalFlow.priority,
        },
      ],
      criteria: approvalFlow.criteria.map((c, i) => {
        return {
          number: i + 1,
          criteria: `${i === 0 ? t(`${context}.criteria.fields.when`) : ""} 
        ${
          c?.type === "EXPENSE_AMOUNT"
            ? t(`${context}.criteria.fields.amount`)
            : ""
        } 
        ${
          c?.operator && c.type === "EXPENSE_AMOUNT"
            ? comparisonListRecord[c.operator].label
            : ""
        } 
        ${
          c?.type === "EXPENSE_AMOUNT" && c.complementValue
            ? currencyRecord[c.complementValue].label
            : ""
        }
        ${c?.type === "EXPENSE_AMOUNT" ? resolveAmountValue(c.value) : ""}
        ${
          c?.type === "CATEGORY" ? t(`${context}.criteria.fields.category`) : ""
        }
        ${c?.description ? c.description : ""}
        `,
        };
      }),
      approvalType:
        approvalFlow.approvalType === "AUTO"
          ? t(`${context}.approvalType.auto`)
          : t(`${context}.approvalType.custom`),
      conditions: approvalFlow.condition.map((c, i) => {
        return {
          number: i + 1,
          condition: `
        ${c?.conditionType === "MANUAL" ? c.approver : ""}
        ${
          c?.conditionType === "HIERARCHY"
            ? t(`${context}.condition.fields.hierarchy`)
            : ""
        }
        ${
          c?.conditionType === "HIERARCHY" && c.value
            ? `${c.value} ${
                parseInt(c.value) > 1
                  ? t(`${context}.condition.fields.levels`)
                  : t(`${context}.condition.fields.level`)
              }`
            : ""
        } 
        ${
          c?.conditionType === "COST_CENTER_OWNER"
            ? t(`${context}.condition.fields.ccOwner`)
            : ""
        }`,
        };
      }),
    };
  }

  return { buildFields };
}
