import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const GridContainer = styled.div`
  position: relative;
`;

export const StyledTag = styled(Tag)`
  cursor: auto;
  pointer-events: none;
  min-width: max-content;
`;

export const StyledTypography = styled(Typography)`
  min-width: 150px;
`;
