import { useTracking } from "@common/hooks/useTracking";
import { BasePage } from "@organisms/BasePage/BasePage";
import { BillWorkflow } from "@organisms/bill-workflow";
import { Transaction } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const PageBillForm = () => {
  const { t } = useTranslation();
  const pageContext = "pages.billForm";
  const location = useLocation();
  const event = useTracking();

  const transaction = useMemo(() => {
    return location.state as Transaction;
  }, []);

  event("payables_bill_add_clicked");

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.pageTitle`),
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.expenses`),
            to: "/expenseManagement/expenses",
          },
          {
            label: t(`${pageContext}.breadCrumbs.addBill`),
          },
        ],
      }}
      hasFooter
    >
      <BillWorkflow transaction={transaction} />
    </BasePage>
  );
};

export default PageBillForm;
