import {
  Button,
  LinkButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { PriorityList } from "./PriorityList";
import { usePriorityData } from "./hooks/usePriorityData";
import {
  ButtonContainer,
  Container,
  ContentContainer,
  HeaderContainer,
  StyledDivider,
  SubTitle,
} from "./styled";

type SortPriorityModalProps = {
  open: boolean;
  closeModal: () => void;
};

export function SortPriorityModal({
  open,
  closeModal,
}: SortPriorityModalProps) {
  const { initialData, reorder, save, saveIsLoading } =
    usePriorityData(closeModal);
  const { t } = useTranslation();
  const context = "components.approvalPriority";

  const approvalFlow = initialData.columns.approvalFlowIds.map(
    (id) => initialData.approvalFlows[id]
  );

  return (
    <Modal.Root open={open} onClose={closeModal} size="md">
      <Container>
        <HeaderContainer>
          <Typography variant="headline6">{t(`${context}.title`)}</Typography>
          <SubTitle variant="body3">{t(`${context}.subtitle`)}</SubTitle>
        </HeaderContainer>
        <StyledDivider />
        <ContentContainer>
          <DragDropContext
            onDragEnd={(e) => {
              reorder(e.draggableId, e.destination?.index);
            }}
          >
            <PriorityList
              approvalFlow={approvalFlow.map((app, i) => {
                return {
                  id: app.id,
                  name: app.content,
                  priority: `${i + 1}`,
                  description: app.description,
                };
              })}
            />
          </DragDropContext>
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={closeModal}>
              {t(`${context}.cancel`)}
            </LinkButton>
          </div>
          <Button
            loading={saveIsLoading}
            variant="primary"
            size="medium"
            onClick={save}
          >
            {t(`${context}.save`)}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal.Root>
  );
}
