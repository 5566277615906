import styled from "styled-components";

export const Container = styled.div`
  margin-left: ${({ theme }) => theme.spacings.xs2};
  padding-top: ${({ theme }) => theme.spacings.xs4};
`;

export const Grid = styled.div<{ template: string }>`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs2};
  grid-template-columns: ${({ template }) => template};
  align-items: center;
`;

export const FormContainer = styled(Grid)`
  display: grid;
  grid-template-columns: ${({ template }) => template};
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  align-items: center;
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const RowContainer = styled.div<{ justify?: string; width?: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${({ width }) => width};
  justify-content: ${({ justify }) => justify};
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const FormBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
`;

export const Box = styled.div<{
  columnStart?: number;
  columnEnd?: number;
  rowStart?: number;
  rowEnd?: number;
}>`
  grid-column-start: ${({ columnStart }) => columnStart};
  grid-column-end: ${({ columnEnd }) => columnEnd};
  grid-row-start: ${({ rowStart }) => rowStart};
  grid-row-end: ${({ rowEnd }) => rowEnd};
`;

export const CaptionBox = styled.div`
  margin-top: -${({ theme }) => theme.spacings.xs2};
  padding-left: ${({ theme }) => theme.spacings.xs4};
`;
