import { banks } from "@common/banks";
import { paymentMethod } from "@common/payment-methods";
import { pixKeyOptions } from "@common/pix-key-options";
import {
  PaymentMethod,
  Vendor,
} from "server/src/services/company-payables-service/company-payables.types";
type PaymentData = {
  id: string;
  title: string;
  favorite: boolean;
  type: PaymentMethod;
  cardId?: string;
  data: {
    label?: string;
    value?: string;
  }[];
};

export function useGetPaymentData(payments: Vendor["bankData"]) {
  return payments?.map((payment) => {
    const data: PaymentData = {
      id: payment.id as string,
      favorite: payment.favorite,
      type: payment.paymentMethod,
      title:
        paymentMethod?.find((_) => _.value === payment.paymentMethod)?.label ||
        "-",
      data: [],
    };
    switch (payment.paymentMethod) {
      case PaymentMethod.BILLET:
        break;
      case PaymentMethod.PIX:
        data.data.push(
          {
            label: "Chave de acesso",
            value: pixKeyOptions.find((_) => _.value === payment.pixType)
              ?.label,
          },
          {
            label: pixKeyOptions.find((_) => _.value === payment.pixType)
              ?.label,
            value: payment.pixKey,
          }
        );
        break;
      case PaymentMethod.TRANSFER:
        data.data.push(
          {
            label: "Instituição bancária",
            value: banks.find((_) => _.value.toString() === payment.bank)
              ?.label,
          },
          {
            label: "Agência",
            value: payment.agency,
          },
          {
            label: "Conta com dígito",
            value: payment.account,
          }
        );
        break;
      case PaymentMethod.CARD:
      case PaymentMethod.FLASH_CARD:
        data.cardId = payment.cardId;
        data.data.push({
          label: "Cartão",
          value: payment.cardId,
        });

      default:
        break;
    }
    return data;
  });
}
