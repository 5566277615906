import { trpc } from "@client";
import { useParams } from "react-router-dom";
import { BillRowsContext } from "./useBillsRows";

export function useBillData(context: BillRowsContext) {
  const { id } = useParams();
  // Define and retrieve employee data based on the context
  const {
    data: employeeData,
    isLoading: employeeIsLoading,
    isFetching: employeeFetching,
  } = trpc.bill.getBills.useQuery(
    { vendorId: id },
    {
      enabled: context === "employee",
      refetchOnWindowFocus: false,
    }
  );

  // Define and retrieve company data based on the context
  const {
    data: companyData,
    isLoading: companyDataIsLoading,
    isFetching: companyFetching,
  } = trpc.bill.getByCompany.useQuery(undefined, {
    enabled: context === "company",
    refetchOnWindowFocus: false,
  });

  const data = context === "employee" ? employeeData : companyData;

  const isLoading =
    (employeeIsLoading && companyDataIsLoading) ||
    employeeFetching ||
    companyFetching;

  const amountRange = {
    min: 0,
    max: 0,
  };

  // Calculate the range of the amount if data is available
  if (data) {
    amountRange.min = Math.min(...data.map((row) => row.amount));
    amountRange.max = Math.max(...data.map((row) => row.amount));
  }

  return {
    bills: data,
    isLoading,
    amountRange,
  };
}
