import { useTracking } from "@common/hooks/useTracking";
import {
  Button,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import { BankForm } from "@organisms/bank-form/bank-form";
import { Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import { AddressForm } from "../address-form/address-form";
import { BasicVendor } from "./basic-vendor";
import { useSaveVendor } from "./hooks/useSaveVendor";
import { useVendorForm } from "./hooks/useVendorForm";
import { Footer, FooterContainer, StyledDropZone, StyledGrid } from "./styled";

export const VendorForm = ({ vendor }: { vendor?: Vendor }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { saveVendor, isLoading } = useSaveVendor();
  const event = useTracking();

  const methods = useVendorForm(vendor);
  const context = "pages.vendorForm";

  const onCancel = () => {
    event("vendor_cancel_clicked");
    navigate("/expense/company-payables/vendors");
  };

  return (
    <form
      onSubmit={methods.handleSubmit((e) => {
        event("vendor_save_clicked");
        saveVendor(e);
      })}
    >
      <StyledGrid>
        <Typography variant="body3" style={{ gridRowStart: 1, gridRowEnd: 5 }}>
          {t(`${context}.subtitle`)}
        </Typography>
        <FormProvider {...methods}>
          <BasicVendor />
          <AddressForm />
          <BankForm />
          <Controller
            control={methods.control}
            name="attachmentFiles"
            defaultValue={[]}
            render={({ field: { value, onChange } }) => (
              <StyledDropZone
                initialFiles={value.map((v, i) => {
                  return {
                    file: v.file as File,
                    id: `${i}`,
                    loaded: true,
                    progress: 100,
                    error: false,
                  };
                })}
                span={value?.length || 0}
                multiple
                title="Anexos"
                onChange={onChange}
              />
            )}
          />
        </FormProvider>
        <Footer>
          <Divider style={{ width: "100%" }} />
          <FooterContainer>
            <div>
              <LinkButton variant="secondary" onClick={onCancel}>
                {t(`${context}.cancel`)}
              </LinkButton>
            </div>
            <Button
              variant="primary"
              size="medium"
              type="submit"
              loading={isLoading}
            >
              {t(`${context}.save`)}
            </Button>
          </FooterContainer>
        </Footer>
      </StyledGrid>
    </form>
  );
};
