import { trpc } from "@client";
import { getAccessTokenPayloadSync } from "@flash-hros/utility";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";

export function useReopenBill() {
  const { t } = useTranslation();
  const accessToken = getAccessTokenPayloadSync();
  const context = "components.billActionModal.toast.reopen";
  const { mutate, isLoading } = trpc.bill.reopen.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: t(`${context}.success.title`),
      });
    },
    onError: () => {
      dispatchToast({
        type: "error",
        title: t(`${context}.error.title`),
        content: t(`${context}.error.message`),
      });
    },
  });

  function canReopen(createdBy: string) {
    return createdBy === accessToken?.employeeId;
  }

  return {
    isLoading,
    reopen: mutate,
    canReopen,
  };
}
