import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useApprovalFlowActionModal } from "../../approval-flow-action-modal/approvalModalContext";

export function useToggleApprovalFlow() {
  const { closeModal, billActionModal } = useApprovalFlowActionModal();
  const { t } = useTranslation();
  const context = "components.approvalFlowActionModal";
  const { mutate } =
    trpc.approvalFlow.changeApprovalFlowStatus.changeStatus.useMutation({
      onSuccess: () => {
        if (billActionModal.type === "inactive") {
          dispatchToast({
            type: "success",
            content: t(`${context}.inactive.success`),
          });
          closeModal();
        } else
          dispatchToast({
            type: "success",
            content: t(`${context}.active.success`),
          });
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: t(`${context}.inactive.error`),
          description: t(`${context}.tryAgain`),
        });
        closeModal();
      },
    });

  return { toggle: mutate };
}
