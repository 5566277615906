import { lineOfBusiness } from "@common/line-of-business";
import {
  Icons,
  SelectField,
  Toggle,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PhoneField } from "@molecules/phone-input/phone-field";
import { RegistrationNumberField } from "@molecules/registration-number-field/registration-number-field";
import { StyledTextField } from "@molecules/text-field/text-field";
import { Divider } from "@mui/material";
import { ContactForm } from "@organisms/contact-form/contact-form";
import { SelectEmployee } from "@organisms/select-employee/selectEmployee";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Container,
  CustomContainer,
  RowContainer,
  StyledFormGrid,
} from "./styled";
import { VendorRequest } from "./vendor-schema";

export const BasicVendor = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<VendorRequest>();
  const { t } = useTranslation();

  const context = "pages.vendorForm.fields";

  return (
    <StyledFormGrid>
      <Controller
        control={control}
        name="code"
        defaultValue=""
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t(`${context}.code.label`)}
            inputMode="text"
            error={!!errors.code}
            helperText={t(`${context}.code.helperText`)}
          />
        )}
      />

      <Controller
        control={control}
        name="registrationNumber"
        render={({ field }) => (
          <RegistrationNumberField
            {...field}
            required
            label={t(`${context}.registrationNumber.label`)}
            error={!!errors.registrationNumber}
            helperText={errors.registrationNumber && t(`${context}.required`)}
          />
        )}
      />
      <Controller
        control={control}
        name="legalName"
        defaultValue=""
        render={({ field }) => (
          <StyledTextField
            {...field}
            required
            label={t(`${context}.legalName.label`)}
            inputMode="text"
            error={!!errors.legalName}
            helperText={errors.legalName && t(`${context}.required`)}
          />
        )}
      />
      <Controller
        control={control}
        name="name"
        defaultValue=""
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t(`${context}.name.label`)}
            inputMode="text"
            error={!!errors.name}
          />
        )}
      />
      <Controller
        control={control}
        name="stateSubscription"
        defaultValue=""
        render={({ field }) => (
          <StyledTextField
            {...field}
            imaskProps={{
              mask: Number,
            }}
            label={t(`${context}.stateSubscription.label`)}
            inputMode="numeric"
            error={!!errors.stateSubscription}
          />
        )}
      />
      <Controller
        control={control}
        name="citySubscription"
        defaultValue=""
        render={({ field }) => (
          <StyledTextField
            {...field}
            imaskProps={{
              mask: Number,
            }}
            label={t(`${context}.citySubscription.label`)}
            inputMode="numeric"
            error={!!errors.citySubscription}
          />
        )}
      />
      <Controller
        name="lineOfBusiness"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <SelectField
            required
            onSelectChange={(_, option) => onChange(option?.value)}
            value={value}
            options={lineOfBusiness}
            label={t(`${context}.lineOfBusiness.label`)}
            searchable
            error={!!errors.lineOfBusiness}
            helperText={errors.lineOfBusiness && t(`${context}.required`)}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        name="email"
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={t(`${context}.vendorEmail.label`)}
            inputMode="email"
            error={!!errors.email}
          />
        )}
      />

      <Controller
        control={control}
        name="phone"
        defaultValue=""
        render={({ field }) => (
          <PhoneField
            {...field}
            label={t(`${context}.vendorPhone.label`)}
            error={!!errors.phone}
          />
        )}
      />

      <CustomContainer>
        <Controller
          name="active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Toggle onChange={onChange} checked={value} />
          )}
        />
        <div>
          <Typography variant="headline8">
            {t(`pages.vendorForm.toggle.title`)}
          </Typography>
          <Typography variant="body4">
            {t(`pages.vendorForm.toggle.helperText`)}
          </Typography>
        </div>
      </CustomContainer>

      <RowContainer>
        <Divider />
        <Container>
          <Typography variant="headline8">
            {t(`pages.vendorForm.companyContacts`)}
          </Typography>
          <Tooltip
            title={t(`pages.vendorForm.tips.vendorContact`)}
            placement="top"
          >
            <button style={{ cursor: "default" }}>
              <Icons name="IconInfoCircle" fill="transparent" />
            </button>
          </Tooltip>
        </Container>
      </RowContainer>
      <ContactForm accessor="contactData" />
      <RowContainer>
        <Divider />
        <Typography variant="headline8">
          {t(`pages.vendorForm.responsibleContacts`)}
        </Typography>
      </RowContainer>
      <RowContainer>
        <Controller
          name="responsibleEmployeeId"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <SelectEmployee
              onSelectChange={(_, option) => onChange(option?.value)}
              value={value}
              label={t(`${context}.responsible.label`)}
              error={!!errors.responsibleEmployeeId}
              helperText={
                errors.responsibleEmployeeId && t(`${context}.required`)
              }
            />
          )}
        />
      </RowContainer>
    </StyledFormGrid>
  );
};
