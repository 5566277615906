import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacings.xs};
`;

export const PaymentBox = styled.div<{ selected: boolean; isError: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme, selected, isError }) =>
    selected
      ? theme.colors.secondary70
      : isError
      ? theme.colors.error70
      : theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: ${({ theme, selected, isError }) =>
    selected
      ? theme.colors.secondary99
      : isError
      ? theme.colors.error90
      : theme.colors.neutral100};
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const RowBox = styled.div`
  display: grid;
  grid-template-rows: 5fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const LinkBox = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xl4}`};
  margin-top: ${({ theme }) => `-${theme.spacings.s}`};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xl4} 0 ${theme.spacings.xl4}`};
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const TypographyWarning = styled(StyledTypography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const SubTitle = styled(StyledTypography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;
