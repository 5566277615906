import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";

export type batchActionResponse = {
  id: string;
  status: "fulfilled" | "rejected";
};

export type Action = "activate" | "deactivate";

export function useToggleVendor(data?: Vendor[]) {
  const { t } = useTranslation();
  const toggleVendor = trpc.vendor.toggle.useMutation();

  const context = "components.vendorGrid.messages";

  const onToggleSuccess = (accessor: string) => {
    dispatchToast({
      type: "success",
      content: `${t(`${context}.${accessor}`)}`,
    });
  };

  const onToggleError = (accessor: string) => {
    dispatchToast({
      type: "error",
      content: `${t(`${context}.${accessor}`)}`,
      description: `${t(`${context}.subtitle`)}`,
    });
  };

  const toggleVendorMutation = (action: Action, ids: string[]) => {
    const vendors = ids.filter((id) =>
      data?.some((_) => _.id === id && _.active === (action === "deactivate"))
    );

    const onSuccessMessage =
      action === "activate" ? "toggleActiveSuccess" : "toggleDeactivateSuccess";
    const onErrorMessage =
      action === "activate" ? "toggleActiveError" : "toggleDeactivateError";

    toggleVendor.mutate(data ? vendors : ids, {
      onSuccess: (response: batchActionResponse[]) => {
        if (response.every((_) => _.status === "fulfilled")) {
          onToggleSuccess(onSuccessMessage);
        }
      },
      onError: () => {
        onToggleError(onErrorMessage);
      },
    });
  };

  return {
    toggleVendorMutation,
  };
}
