import {
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import useUserCards from "./hooks/useUserCards";

export const SelectCard = (props: SelectFieldProps) => {
  const { loading, options } = useUserCards();

  return (
    <SelectField
      {...props}
      searchable
      options={options}
      inputMode="text"
      noOptionsText={loading ? "Buscando..." : undefined}
    />
  );
};
