import { Breadcrumbs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Link } from "react-router-dom";
import { BreadcrumbContainer, BreadcrumbTypography } from "./styled";

export interface IBreadcrumbItem {
  to?: string;
  label: string;
}

interface IProps {
  items: IBreadcrumbItem[];
}

export const PageBreadcrumb = ({ items }: IProps) => {
  return (
    <BreadcrumbContainer>
      <Breadcrumbs
        breadcrumbs={items.map((item, i) => (
          <div key={i}>
            {item.to ? (
              <Link to={item.to}>
                <BreadcrumbTypography>{item.label}</BreadcrumbTypography>
              </Link>
            ) : (
              <BreadcrumbTypography>{item.label}</BreadcrumbTypography>
            )}
          </div>
        ))}
        separator={<Icons name="IconChevronRight" fill={"transparent"} />}
      ></Breadcrumbs>
    </BreadcrumbContainer>
  );
};
