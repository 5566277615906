import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { formatDateTime } from "@utils/dateFormater";
import { toCurrency } from "@utils/toCurrency";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Container, GridContainer, StyledDataGrid } from "./styled";

export function TransactionList({
  itemActions,
  rows,
  isLoading,
}: {
  itemActions: "select" | "menuAction" | "none";
  rows: Record<string, ReactNode>[] | undefined;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();
  const context = "components.transactionList.grid";

  const columns = [
    {
      accessor: "select",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { select = null },
        },
      }) => {
        return select;
      },
    },
    {
      Header: t(`${context}.description`),
      accessor: "description",
      Cell: ({
        row: {
          original: { description = "", card = "" },
        },
      }) => {
        return (
          <Container>
            <ShapeIcon
              name="IconCreditCard"
              variant="default"
              stroke="default"
              size={40}
            />
            <div>
              <Typography variant="body3">{description}</Typography>
              <Typography variant="body4">{card && `––${card}`}</Typography>
            </div>
          </Container>
        );
      },
    },
    {
      Header: t(`${context}.value`),
      accessor: "value",
      Cell: ({
        row: {
          original: { value = 0 },
        },
      }) => {
        return <Typography variant="body3">{toCurrency(value)}</Typography>;
      },
    },
    {
      Header: t(`${context}.date`),
      accessor: "date",
      Cell: ({
        row: {
          original: { date = "" },
        },
      }) => {
        const value = new Date(date);
        return (
          <Container>
            <div>
              <Typography variant="body3">
                {formatDateTime(value, "date")}
              </Typography>
              <Typography variant="body4">
                {formatDateTime(value, "time")}
              </Typography>
            </div>
          </Container>
        );
      },
    },
    {
      Header: t(`${context}.action`),
      accessor: "actions",
      sticky: "right",
      size: 200,
      disableSortBy: true,
      Cell: ({
        row: {
          original: { actions = null },
        },
      }) => {
        return actions;
      },
    },
  ];

  const gridColumns = {
    select: columns.filter((_) => _.accessor !== "actions"),
    menuAction: columns.filter((_) => _.accessor !== "select"),
    none: columns.filter(
      (_) => _.accessor !== "actions" && _.accessor !== "select"
    ),
  };

  return (
    <GridContainer>
      <StyledDataGrid
        hasPagination={rows && rows.length > 1}
        pageSizeOptions={[
          { value: 5, label: "5 itens" },
          { value: 10, label: "10 itens" },
          { value: 20, label: "20 itens" },
        ]}
        loading={isLoading}
        columns={gridColumns[itemActions]}
        data={rows}
      />
    </GridContainer>
  );
}
