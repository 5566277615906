import { ReactNode, createContext, useContext, useState } from "react";

type VendorActionModalProps = {
  type?: "deactivate" | "delete";
  open: boolean;
  onConfirm(): void;
};

interface VendorActionModalContextProps {
  vendorActionModal: VendorActionModalProps;
  setVendorActionModal(newValue: VendorActionModalProps): void;
  closeModal(): void;
}

const initialState: VendorActionModalProps = {
  open: false,
  onConfirm: () => {
    return undefined;
  },
};

export const VendorActionModalContext =
  createContext<VendorActionModalContextProps>({
    vendorActionModal: initialState,
    setVendorActionModal: () => {
      return undefined;
    },
    closeModal: () => {
      return undefined;
    },
  });

type VendorActionModalProviderProps = {
  children: ReactNode;
};

export const VendorActionModalProvider = ({
  children,
}: VendorActionModalProviderProps) => {
  const [vendorActionModal, setVendorActionModal] =
    useState<VendorActionModalProps>(initialState);

  return (
    <VendorActionModalContext.Provider
      value={{
        vendorActionModal,
        setVendorActionModal,
        closeModal: () => {
          setVendorActionModal(initialState);
        },
      }}
    >
      {children}
    </VendorActionModalContext.Provider>
  );
};

export const useVendorActionModal = () => useContext(VendorActionModalContext);
