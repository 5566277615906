import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import {
  Container,
  Footer,
  FooterContainer,
  RowContainer,
  StyledGrid,
  StyledTypography,
} from "./styled";

type PageContentProps = {
  title?: string;
  subtitle?: string;
  children: JSX.Element;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  leftChildren?: JSX.Element;
};

export const PageContent = ({
  title,
  subtitle,
  children,
  leftButton,
  rightButton,
  leftChildren,
}: PageContentProps) => {
  return (
    <StyledGrid>
      <RowContainer>
        {title && (
          <StyledTypography
            variant="headline7"
            weight={700}
            variantColor="secondary"
          >
            {title}
          </StyledTypography>
        )}
        <Typography variant="body3">{subtitle}</Typography>
        {leftChildren}
      </RowContainer>
      {children}
      {(leftButton || rightButton) && (
        <Footer>
          <Divider style={{ width: "100%" }} />
          <FooterContainer>
            <Container>{leftButton || ""}</Container>
            <Container>{rightButton || ""}</Container>
          </FooterContainer>
        </Footer>
      )}
    </StyledGrid>
  );
};
