import { TagDateFilter, TagFilter } from "@flash-tecnologia/hros-web-ui-v2";
import { BillStatus } from "@organisms/bills-list/hooks/useBillsRows";
import useSelectCategory from "@organisms/category-select/hooks/useSelectCategory";
import useSearchVendor from "@organisms/select-vendor/hooks/useSearchVendor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useGetEmployee from "./useOptionsEmployee";

export type BillFilters = {
  vendorId?: string[];
  status?: BillStatus[];
  categoryId?: string[];
  dueDate?: { from?: Date; to?: Date };
  createdBy?: string[];
};

export function useFilters() {
  const [filters, setFilters] = useState<BillFilters | undefined>({
    status: ["AWAIT_APPROVAL"],
  });
  const { loading, options } = useSearchVendor(true);
  const employee = useGetEmployee();
  const { t } = useTranslation();
  const { loading: categoryIsLoading, options: categories } =
    useSelectCategory();

  const context = "components.approvalsList.filters";
  const filtersComponents = (
    <>
      <TagFilter
        hasLeftIcon={false}
        onApply={(values: BillStatus[]) => {
          setFilters({ ...filters, status: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.status;
          setFilters(newFilters);
        }}
        options={[
          {
            label: t(`${context}.status.approvePending`),
            value: "AWAIT_APPROVAL",
          },
          { label: t(`${context}.status.accountingPending`), value: "PENDING" },
          { label: t(`${context}.status.accounted`), value: "APPROVED" },
          { label: t(`${context}.status.rejected`), value: "REJECTED" },
        ]}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.status`)}
        selectedOptions={filters?.status}
      />
      <TagDateFilter
        variant="tertiary"
        filterLabel={t(`${context}.labels.dueDate`)}
        hasLeftIcon={false}
        options={[]}
        onSubmit={(dates) => {
          const { from, to } = dates;
          setFilters({ ...filters, dueDate: { from, to } });
        }}
      />
      <TagFilter
        hasLeftIcon={false}
        loading={employee.loading}
        onApply={(values: string[]) => {
          setFilters({ ...filters, createdBy: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.createdBy;
          setFilters(newFilters);
        }}
        options={employee.options}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.requester`)}
      />
      <TagFilter
        hasLeftIcon={false}
        onApply={(values: string[]) => {
          setFilters({ ...filters, categoryId: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.categoryId;
          setFilters(newFilters);
        }}
        loading={categoryIsLoading}
        options={categories}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.category`)}
      />

      <TagFilter
        hasLeftIcon={false}
        loading={loading}
        onApply={(values: string[]) => {
          setFilters({ ...filters, vendorId: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.vendorId;
          setFilters(newFilters);
        }}
        options={options}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.vendor`)}
      />
    </>
  );

  const formattedFilter =
    filters &&
    Object.entries(filters).map((entry) => ({
      id: entry[0],
      value: entry[1],
    }));

  return {
    formattedFilter,
    filtersComponents,
  };
}
