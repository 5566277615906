import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root, {
  AccountingTab,
  ApprovalsTab,
  BillConfigurationTab,
  BillTab,
  CategoryConfigurationTab,
  PayablesBanner,
  PayablesConfigurationTab,
  TransactionTab,
} from "./root.component";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <div>ERRO</div>;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

//This components are used by the expense microfrontend
//Name changes should be reflected on expense imports
export const CompanyPayables = {
  TransactionTab,
  BillTab,
  AccountingTab,
  PayablesConfigurationTab,
  BillConfigurationTab,
  CategoryConfigurationTab,
  PayablesBanner,
  ApprovalsTab,
};
