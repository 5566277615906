import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { Container } from "../styled";

export function ApprovalTypeWarning() {
  const { t } = useTranslation();
  const pageContext = "components.approvalFlowForm";

  return (
    <div>
      <Typography variant="body3" weight={700}>
        {t(`${pageContext}.warning.title`)}
      </Typography>
      <Container>
        <ul>
          <li>{t(`${pageContext}.warning.one`)}</li>
          <li>{t(`${pageContext}.warning.two`)}</li>
          <li>{t(`${pageContext}.warning.three`)}</li>
        </ul>
      </Container>
    </div>
  );
}
