import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const AttachmentPreviewContainer = styled(Container)`
  justify-content: space-between;
  width: 100%;
  border: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.secondary[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs2};
`;

export const AttachmentImage = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const ImagePreview = styled.img`
  object-fit: contain;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  width: ${({ theme }) => theme.spacings.s};
  height: ${({ theme }) => theme.spacings.s};

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
export const StyledIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledTypography = styled(Typography)`
  padding: ${({ theme }) => theme.spacings.s};
  border-bottom: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  margin-top: -${({ theme }) => theme.spacings.m};
`;

export const StyledContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacings.xs};
  justify-content: center;
`;
