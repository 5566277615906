import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacings.m};
  min-width: ${({ theme }) => theme.spacings.m};
  height: ${({ theme }) => theme.spacings.m};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.palette.secondary.light};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const StyledBadgeText = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
`;
