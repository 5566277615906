import IMask from "imask";

function formatWithMask(value: string, maskOptions: any) {
  const mask = IMask.createMask(maskOptions);
  return mask.resolve(value);
}

export function formatPhone(value: string) {
  const mask =
    value.length > 10
      ? { mask: "(00) 00000-0000" }
      : { mask: "(00) 0000-0000" };

  return formatWithMask(value, mask);
}

export function formatDocumentNumber(value: string) {
  const mask =
    value.length > 11
      ? { mask: "00.000.000/0000-00" }
      : { mask: "000.000.000-00" };
  return formatWithMask(value, mask);
}

export function formatPostalCode(value: string) {
  return formatWithMask(value, { mask: "00000-000" });
}
