import { transformToRecord } from "@utils/dataRecord";

export const banks = [
  {
    value: 1,
    label: "Banco Do Brasil S.A",
  },
  {
    value: 237,
    label: "Bradesco S.A.",
  },
  {
    value: 335,
    label: "Banco Digio S.A.",
  },
  {
    value: 260,
    label: "Nu Pagamentos S.A",
  },
  {
    value: 290,
    label: "PagSeguro Internet S.A.",
  },
  {
    value: 323,
    label: "Mercado Pago - Conta Do Mercado Livre",
  },
  {
    value: 237,
    label: "Next",
  },
  {
    value: 637,
    label: "Banco Sofisa S.A",
  },
  {
    value: 77,
    label: "Banco Inter S.A.",
  },
  {
    value: 341,
    label: "Itaú Unibanco S.A.",
  },
  {
    value: 104,
    label: "Caixa Econômica Federal",
  },
  {
    value: 33,
    label: "Banco Santander Brasil S.A.",
  },
  {
    value: 212,
    label: "Banco Original S.A.",
  },
  {
    value: 756,
    label: "Bancoob - Banco Cooperativo Do Brasil S.A.",
  },
  {
    value: 655,
    label: "Banco Votorantim S.A.",
  },
  {
    value: 536,
    label: "Neon Pagamentos S.A",
  },
  {
    value: 41,
    label: "Banrisul - Banco Do Estado Do Rio Grande Do Sul S.A.",
  },
  {
    value: 389,
    label: "Banco Mercantil Do Brasil S.A.",
  },
  {
    value: 422,
    label: "Banco Safra S.A.",
  },
  {
    value: 70,
    label: "BRB - Banco De Brasília S.A.",
  },
  {
    value: 136,
    label: "Unicred Cooperativa LTDA",
  },
  {
    value: 741,
    label: "Banco Ribeirão Preto S.A.",
  },
  {
    value: 739,
    label: "Banco Cetelem S.A.",
  },
  {
    value: 743,
    label: "Banco Semear S.A.",
  },
  {
    value: 100,
    label: "Planner Corretora De Valores S.A.",
  },
  {
    value: 96,
    label: "Banco B3 S.A.",
  },
  {
    value: 747,
    label: "Banco Rabolabel Internacional Do Brasil S.A.",
  },
  {
    value: 748,
    label: "Banco Cooperativa Sicredi S.A.",
  },
  {
    value: 752,
    label: "Banco BNP Paribas Brasil S.A.",
  },
  {
    value: 91,
    label: "Unicred Central Rs",
  },
  {
    value: 399,
    label: "Kirton label S.A. - Banco Múltiplo",
  },
  {
    value: 108,
    label: "Portocred S.A.",
  },
  {
    value: 757,
    label: "Banco Keb Hana Do Brasil S.A.",
  },
  {
    value: 102,
    label: "XP Investimentos S.A.",
  },
  {
    value: 348,
    label: "Banco XP S/A",
  },
  {
    value: 340,
    label: "Super Pagamentos S/A",
  },
  {
    value: 84,
    label: "Uniprime Norte Do Paraná",
  },
  {
    value: 180,
    label: "Cm Capital Markets Cctvm Ltda",
  },
  {
    value: 66,
    label: "Banco Morgan Stanley S.A.",
  },
  {
    value: 15,
    label: "UBS Brasil Cctvm S.A.",
  },
  {
    value: 143,
    label: "Treviso Cc S.A.",
  },
  {
    value: 62,
    label: "Hipercard Banco Múltiplo S.A.",
  },
  {
    value: 74,
    label: "Banco J. Safra S.A.",
  },
  {
    value: 99,
    label: "Uniprime Central Ccc Ltda",
  },
  {
    value: 25,
    label: "Banco Alfa S.A.",
  },
  {
    value: 75,
    label: "Bco Abn Amro S.A.",
  },
  {
    value: 40,
    label: "Banco Cargill S.A.",
  },
  {
    value: 190,
    label: "Servicoop",
  },
  {
    value: 63,
    label: "Banco Bradescard",
  },
  {
    value: 191,
    label: "Nova Futura Ctvm Ltda",
  },
  {
    value: 64,
    label: "Goldman Sachs Do Brasil Bm S.A.",
  },
  {
    value: 97,
    label: "Ccc Noroeste Brasileiro Ltda",
  },
  {
    value: 16,
    label: "Ccm Desp Trâns Sc E Rs",
  },
  {
    value: 12,
    label: "Banco Inbursa",
  },
  {
    value: 3,
    label: "Banco Da Amazônia S.A.",
  },
  {
    value: 60,
    label: "Confidence Cc S.A.",
  },
  {
    value: 37,
    label: "Banco Do Estado Do Pará S.A.",
  },
  {
    value: 159,
    label: "Casa do Crédito S.A.",
  },
  {
    value: 172,
    label: "Albatross Ccv S.A.",
  },
  {
    value: 85,
    label: "Cooperativa Central de Créditos - Ailos",
  },
  {
    value: 114,
    label: "Central Cooperativa De Crédito no Estado do Espírito Santo",
  },
  {
    value: 36,
    label: "Banco Bradesco BBI S.A.",
  },
  {
    value: 394,
    label: "Banco Bradesco Financiamentos S.A.",
  },
  {
    value: 4,
    label: "Banco Do Nordeste Do Brasil S.A.",
  },
  {
    value: 320,
    label: "China Construction label - Ccb Brasil S.A.",
  },
  {
    value: 189,
    label: "Hs Financeira",
  },
  {
    value: 105,
    label: "Lecca Cfi S.A.",
  },
  {
    value: 76,
    label: "Banco KDB Brasil S.A.",
  },
  {
    value: 82,
    label: "Banco Topázio S.A.",
  },
  {
    value: 286,
    label: "Cooperativa de Crédito Rural de De Ouro",
  },
  {
    value: 93,
    label: "PóloCred Scmepp Ltda",
  },
  {
    value: 273,
    label: "Ccr De São Miguel Do Oeste",
  },
  {
    value: 157,
    label: "Icap Do Brasil Ctvm Ltda",
  },
  {
    value: 183,
    label: "Socred S.A.",
  },
  {
    value: 14,
    label: "Natixis Brasil S.A.",
  },
  {
    value: 130,
    label: "Caruana Scfi",
  },
  {
    value: 127,
    label: "valuepe Cvc S.A.",
  },
  {
    value: 79,
    label: "Banco Original Do Agronegócio S.A.",
  },
  {
    value: 81,
    label: "Bbn Banco Brasileiro De Negocios S.A.",
  },
  {
    value: 118,
    label: "Standard Chartered Bi S.A.",
  },
  {
    value: 133,
    label: "Cresol Confederação",
  },
  {
    value: 121,
    label: "Banco Agilabel S.A.",
  },
  {
    value: 83,
    label: "Banco Da China Brasil S.A.",
  },
  {
    value: 138,
    label: "Get Money Cc Ltda",
  },
  {
    value: 24,
    label: "Banco Bandepe S.A.",
  },
  {
    value: 95,
    label: "Banco Confidence De Câmbio S.A.",
  },
  {
    value: 94,
    label: "Banco Finaxis",
  },
  {
    value: 276,
    label: "Senff S.A.",
  },
  {
    value: 137,
    label: "Multimoney Cc Ltda",
  },
  {
    value: 92,
    label: "BRK S.A.",
  },
  {
    value: 47,
    label: "Banco do Estado de Sergipe S.A.",
  },
  {
    value: 144,
    label: "Bexs Banco De Cambio S.A.",
  },
  {
    value: 126,
    label: "BR Partners Banco de Investimento S.A.",
  },
  {
    value: 301,
    label: "BPP Instituição De Pagamentos S.A.",
  },
  {
    value: 173,
    label: "BRL Trust Dtvm Sa",
  },
  {
    value: 119,
    label: "Banco Western Union do Brasil S.A.",
  },
  {
    value: 254,
    label: "Paraná Banco S.A.",
  },
  {
    value: 268,
    label: "Barigui Companhia Hipotecária",
  },
  {
    value: 107,
    label: "Banco Bocom BBM S.A.",
  },
  {
    value: 412,
    label: "Banco Capital S.A.",
  },
  {
    value: 124,
    label: "Banco Woori label Do Brasil S.A.",
  },
  {
    value: 149,
    label: "Facta S.A. Cfi",
  },
  {
    value: 197,
    label: "Stone Pagamentos S.A.",
  },
  {
    value: 142,
    label: "Broker Brasil Cc Ltda",
  },
  {
    value: 389,
    label: "Banco Mercantil Do Brasil S.A.",
  },
  {
    value: 184,
    label: "Banco Itaú BBA S.A.",
  },
  {
    value: 634,
    label: "Banco Triangulo S.A",
  },
  {
    value: 545,
    label: "Senso Ccvm S.A.",
  },
  {
    value: 132,
    label: "ICBC do Brasil Bm S.A.",
  },
  {
    value: 298,
    label: "Vip’s Cc Ltda",
  },
  {
    value: 129,
    label: "UBS Brasil Bi S.A.",
  },
  {
    value: 128,
    label: "Ms label S.A Banco De Câmbio",
  },
  {
    value: 194,
    label: "Parmetal Dtvm Ltda",
  },
  {
    value: 310,
    label: "VORTX Dtvm Ltda",
  },
  {
    value: 163,
    label: "Commerzlabel Brasil S.A Banco Múltiplo",
  },
  {
    value: 280,
    label: "Avista S.A.",
  },
  {
    value: 146,
    label: "Guitta Cc Ltda",
  },
  {
    value: 279,
    label: "Ccr De Primavera Do Leste",
  },
  {
    value: 182,
    label: "Dacasa Financeira S/A",
  },
  {
    value: 278,
    label: "Genial Investimentos Cvm S.A.",
  },
  {
    value: 271,
    label: "Ib Cctvm Ltda",
  },
  {
    value: 21,
    label: "Banco Banestes S.A.",
  },
  {
    value: 246,
    label: "Banco Abc Brasil S.A.",
  },
  {
    value: 751,
    label: "Scotialabel Brasil",
  },
  {
    value: 208,
    label: "Banco BTG Pactual S.A.",
  },
  {
    value: 746,
    label: "Banco Modal S.A.",
  },
  {
    value: 241,
    label: "Banco Classico S.A.",
  },
  {
    value: 612,
    label: "Banco Guanabara S.A.",
  },
  {
    value: 604,
    label: "Banco Industrial Do Brasil S.A.",
  },
  {
    value: 505,
    label: "Banco Credit Suisse S.A.",
  },
  {
    value: 196,
    label: "Banco Fair Cc S.A.",
  },
  {
    value: 300,
    label: "Banco La Nacion Argentina",
  },
  {
    value: 477,
    label: "Citilabel N.A.",
  },
  {
    value: 266,
    label: "Banco Cedula S.A.",
  },
  {
    value: 122,
    label: "Banco Bradesco BERJ S.A.",
  },
  {
    value: 376,
    label: "Banco J.P. Morgan S.A.",
  },
  {
    value: 473,
    label: "Banco Caixa Geral Brasil S.A.",
  },
  {
    value: 745,
    label: "Banco Citilabel S.A.",
  },
  {
    value: 120,
    label: "Banco Rodobens S.A.",
  },
  {
    value: 265,
    label: "Banco Fator S.A.",
  },
  {
    value: 7,
    label: "BNDES (Banco Nacional Do Desenvolvimento Social)",
  },
  {
    value: 188,
    label: "Ativa S.A Investimentos",
  },
  {
    value: 134,
    label: "BGC Liquidez Dtvm Ltda",
  },
  {
    value: 641,
    label: "Banco Alvorada S.A.",
  },
  {
    value: 29,
    label: "Banco Itaú Consignado S.A.",
  },
  {
    value: 243,
    label: "Banco Máxima S.A.",
  },
  {
    value: 78,
    label: "Haitong Bi Do Brasil S.A.",
  },
  {
    value: 111,
    label: "Banco Oliveira Trust Dtvm S.A.",
  },
  {
    value: 17,
    label: "Bny Mellon Banco S.A.",
  },
  {
    value: 174,
    label: "Pernambucanas Financ S.A.",
  },
  {
    value: 495,
    label: "La Provincia Buenos Aires Banco",
  },
  {
    value: 125,
    label: "Brasil Plural S.A Banco",
  },
  {
    value: 488,
    label: "Jpmorgan Chase label",
  },
  {
    value: 65,
    label: "Banco Andlabel S.A.",
  },
  {
    value: 492,
    label: "Ing label N.V.",
  },
  {
    value: 250,
    label: "Banco Bcv",
  },
  {
    value: 145,
    label: "Levycam Ccv Ltda",
  },
  {
    value: 494,
    label: "Banco Rep Oriental Uruguay",
  },
  {
    value: 253,
    label: "Bexs Cc S.A.",
  },
  {
    value: 269,
    label: "Hsbc Banco De Investimento",
  },
  {
    value: 213,
    label: "Bco Arbi S.A.",
  },
  {
    value: 139,
    label: "Intesa Sanpaolo Brasil S.A.",
  },
  {
    value: 18,
    label: "Banco Tricury S.A.",
  },
  {
    value: 630,
    label: "Banco Intercap S.A.",
  },
  {
    value: 224,
    label: "Banco Fibra S.A.",
  },
  {
    value: 600,
    label: "Banco Luso Brasileiro S.A.",
  },
  {
    value: 623,
    label: "Banco Pan S.A.",
  },
  {
    value: 204,
    label: "Banco Bradesco Cartoes S.A.",
  },
  {
    value: 479,
    label: "Banco Itaulabel S.A.",
  },
  {
    value: 456,
    label: "Banco MUFG Brasil S.A.",
  },
  {
    value: 464,
    label: "Banco Sumitomo Mitsui Brasil S.A.",
  },
  {
    value: 613,
    label: "Omni Banco S.A.",
  },
  {
    value: 652,
    label: "Itaú Unibanco Holding Bm S.A.",
  },
  {
    value: 653,
    label: "Banco Indusval S.A.",
  },
  {
    value: 69,
    label: "Banco Crefisa S.A.",
  },
  {
    value: 370,
    label: "Banco Mizuho S.A.",
  },
  {
    value: 249,
    label: "Banco Investcred Unibanco S.A.",
  },
  {
    value: 318,
    label: "Banco BMG S.A.",
  },
  {
    value: 626,
    label: "Banco Ficsa S.A.",
  },
  {
    value: 270,
    label: "Sagitur Cc Ltda",
  },
  {
    value: 366,
    label: "Banco Societe Generale Brasil",
  },
  {
    value: 113,
    label: "Magliano S.A.",
  },
  {
    value: 131,
    label: "Tullett Prebon Brasil Cvc Ltda",
  },
  {
    value: 11,
    label: "C.Suisse Hedging-Griffo Cv S.A",
  },
  {
    value: 611,
    label: "Banco Paulista",
  },
  {
    value: 755,
    label: "Bofa Merrill Lynch Bm S.A.",
  },
  {
    value: 89,
    label: "Ccr Reg Mogiana",
  },
  {
    value: 643,
    label: "Banco Pine S.A.",
  },
  {
    value: 140,
    label: "Easynvest - Título Cv S.A.",
  },
  {
    value: 707,
    label: "Banco Daycoval S.A.",
  },
  {
    value: 288,
    label: "Carol Dtvm Ltda",
  },
  {
    value: 101,
    label: "Renascença Dtvm Ltda",
  },
  {
    value: 487,
    label: "Deutsche label S.A",
  },
  {
    value: 233,
    label: "Banco Cifra S.A.",
  },
  {
    value: 177,
    label: "Guide Investimentos S.A. Corretora de Valores",
  },
  {
    value: 633,
    label: "Banco Rendimento S.A.",
  },
  {
    value: 218,
    label: "Banco Bs2 S.A.",
  },
  {
    value: 292,
    label: "BS2 Distribuidora De Títulos E Investimentos",
  },
  {
    value: 169,
    label: "Banco Olé Bonsucesso Consignado S.A.",
  },
  {
    value: 293,
    label: "Lastro Rdv Dtvm Ltda",
  },
  {
    value: 285,
    label: "Frente Cc Ltda",
  },
  {
    value: 80,
    label: "B&T Cc Ltda",
  },
  {
    value: 753,
    label: "Novo Banco Continental S.A Bm",
  },
  {
    value: 222,
    label: "Banco Crédit Agricole Br S.A.",
  },
  {
    value: 754,
    label: "Banco Sistema S.A.",
  },
  {
    value: 98,
    label: "Credialiança Ccr",
  },
  {
    value: 610,
    label: "Banco VR S.A.",
  },
  {
    value: 712,
    label: "Banco Ourinvest S.A.",
  },
  {
    value: 10,
    label: "CREDICOAMO CRÉDITO RURAL COOPERATIVA",
  },
  {
    value: 283,
    label: "RB Capital Investimentos Dtvm Ltda",
  },
  {
    value: 217,
    label: "Banco John Deere S.A.",
  },
  {
    value: 117,
    label: "Advanced Cc Ltda",
  },
  {
    value: 336,
    label: "Banco C6 S.A - C6 label",
  },
  {
    value: 654,
    label: "Banco A.J. Renner S.A.",
  },
  {
    value: 272,
    label: "AGK Corretora de Câmbio S.A.",
  },
  {
    value: 330,
    label: "Banco Bari de Investimentos e Financiamentos S.A.",
  },
  {
    value: 362,
    label: "CIELO S.A.",
  },
  {
    value: 322,
    label: "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz",
  },
  {
    value: 350,
    label:
      "Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce",
  },
  {
    value: 91,
    label:
      "Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S",
  },
  {
    value: 379,
    label:
      "COOPERFORTE - Cooperativa De Economia E Crédito Mútuo Dos Funcionários De Instit",
  },
  {
    value: 378,
    label: "BBC LEASING S.A. - Arrendamento Mercantil",
  },
  {
    value: 360,
    label: "TRINUS Capital Distribuidora De Títulos E Valores Mobiliários S.A.",
  },
  {
    value: 84,
    label: "UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA",
  },
  {
    value: 387,
    label: "Banco Toyota do Brasil S.A.",
  },
  {
    value: 326,
    label: "PARATI - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    value: 315,
    label: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    value: 307,
    label:
      "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    value: 296,
    label: "VISION S.A. CORRETORA DE CAMBIO",
  },
  {
    value: 382,
    label:
      "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
  },
  {
    value: 97,
    label: "Credisis - Central de Cooperativas de Crédito Ltda.",
  },
  {
    value: 16,
    label:
      "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
  },
  {
    value: 299,
    label: "SOROCRED CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    value: 359,
    label: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
  },
  {
    value: 391,
    label: "COOPERATIVA DE CRÉDITO RURAL DE IBIAM - SULCREDI/IBIAM",
  },
  {
    value: 368,
    label: "Banco CSF S.A.",
  },
  {
    value: 259,
    label: "MONEYCORP BANCO DE CÂMBIO S.A.",
  },
  {
    value: 364,
    label: "GERENCIANET S.A.",
  },
  {
    value: 14,
    label: "STATE STREET BRASIL S.A. - BANCO COMERCIAL",
  },
  {
    value: 81,
    label: "Banco Seguro S.A.",
  },
  {
    value: 384,
    label:
      "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
  },
  {
    value: 88,
    label: "BANCO RANDON S.A.",
  },
  {
    value: 319,
    label: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: 274,
    label:
      "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
  },
  {
    value: 95,
    label: "Travelex Banco de Câmbio S.A.",
  },
  {
    value: 332,
    label: "Acesso Soluções de Pagamento S.A.",
  },
  {
    value: 325,
    label: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    value: 331,
    label: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    value: 396,
    label: "HUB PAGAMENTOS S.A.",
  },
  {
    value: 309,
    label: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
  },
  {
    value: 313,
    label: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
  },
  {
    value: 377,
    label:
      "MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA",
  },
  {
    value: 321,
    label:
      "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
  },
  {
    value: 383,
    label: "BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
  },
  {
    value: 324,
    label: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 380,
    label: "PICPAY SERVICOS S.A.",
  },
  {
    value: 343,
    label:
      "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
  },
  {
    value: 349,
    label: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    value: 374,
    label: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    value: 352,
    label: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    value: 329,
    label: "QI Sociedade de Crédito Direto S.A.",
  },
  {
    value: 342,
    label: "Creditas Sociedade de Crédito Direto S.A.",
  },
  {
    value: 397,
    label: "LISTO SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 355,
    label: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 367,
    label: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    value: 373,
    label: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
  },
  {
    value: 408,
    label: "BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 404,
    label: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 403,
    label: "CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    value: 306,
    label: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    value: 174,
    label: "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    value: 354,
    label:
      "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
  },
  {
    value: 630,
    label: "Banco Smartlabel S.A.",
  },
  {
    value: 393,
    label: "Banco Volkswagen S.A.",
  },
  {
    value: 390,
    label: "BANCO GM S.A.",
  },
  {
    value: 381,
    label: "BANCO MERCEDES-BENZ DO BRASIL S.A.",
  },
  {
    value: 626,
    label: "BANCO C6 CONSIGNADO S.A.",
  },
  {
    value: 755,
    label: "label of America Merrill Lynch Banco Múltiplo S.A.",
  },
  {
    value: 89,
    label: "CREDISAN COOPERATIVA DE CRÉDITO",
  },
  {
    value: 363,
    label: "SOCOPA SOCIEDADE CORRETORA PAULISTA S.A.",
  },
  {
    value: 365,
    label: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
  },
  {
    value: 281,
    label: "Cooperativa de Crédito Rural Coopavel",
  },
  {
    value: 654,
    label: "BANCO DIGIMAIS S.A.",
  },
  {
    value: 371,
    label: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
  },
  {
    value: 289,
    label: "DECYSEO CORRETORA DE CAMBIO LTDA.",
  },
];

export const banksRecord = transformToRecord(banks);
