import { BasePage } from "@organisms/BasePage/BasePage";
import { Accounting } from "@organisms/accounting";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { useTranslation } from "react-i18next";

export const PageBillAccount = () => {
  const { t } = useTranslation();
  const pageContext = "pages.accounting";

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.title`),
      }}
      hasFooter
    >
      <BillActionModalProvider>
        <Accounting />
      </BillActionModalProvider>
    </BasePage>
  );
};
