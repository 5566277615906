export const lineOfBusiness = [
  { value: "INDUSTRIAL", label: "Industrial" },
  { value: "RETAIL_COMMERCIAL", label: "Comercial (Varejista)" },
  { value: "CIVIL_CONSTRUCTION", label: "Construção Civil" },
  { value: "WHOLESALE_COMMERCIAL", label: "Comercial (Atacadista)" },
  { value: "EXPORT_COMMERCIAL", label: "Comercial (Exportador)" },
  { value: "RETAIL_IMPORTER", label: "Importador (Varejista)" },
  { value: "WHOLESALE_IMPORTER", label: "Importador (Atacadista)" },
  { value: "ASSOCIATION", label: "Associação" },
  { value: "PUBLIC_ORGANIZATION", label: "Órgão Público" },
  {
    value: "PUBLIC_SERVICES",
    label: "Serviços Público (Água, Energia, etc.)",
  },
  { value: "RESTAURANT_SERVICES", label: "Serviços de Restaurante" },
  { value: "REAL_ESTATE_SERVICES", label: "Serviços Imobiliários" },
  { value: "LOGISTICS_SERVICES", label: "Serviços Logísticos" },
  { value: "MAINTENANCE_SERVICES", label: "Serviços de Manutenção" },
  { value: "ADMINISTRATIVE_SERVICES", label: "Serviços Administrativos" },
  { value: "MARKETING_SERVICES", label: "Serviços de Marketing" },
  { value: "BEAUTY_SERVICES", label: "Serviços de Beleza" },
  {
    value: "CORPORATE_TRAVEL_SERVICES",
    label: "Serviços de Viagem Corporativo",
  },
  {
    value: "HR_SERVICES_RECRUITMENT",
    label: "Serviços de RH (recrutamento, etc.)",
  },
  { value: "AUDITING_SERVICES", label: "Serviços de Auditoria" },
  { value: "ACCOUNTING_SERVICES", label: "Serviços Contábeis" },
  { value: "BANKING_SERVICES", label: "Serviços Bancários" },
  { value: "LEGAL_SERVICES", label: "Serviços de Advocacia" },
  { value: "OTHER_SERVICES", label: "Outros Serviços" },
  { value: "SOFTWARE_PROVIDER", label: "Provedor de Software (saas)" },
  {
    value: "CLOUD_COMPUTING_PROVIDER",
    label: "Provedor de Computação em Nuvem",
  },
];
