import { trpc } from "@client";
import { useTracking } from "@common/hooks/useTracking";
import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { BillForm } from "@organisms/bill-form";
import { PageContent } from "@organisms/page-content";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export function BillEdit() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = trpc.bill.getById.useQuery(id || "", {
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const event = useTracking();

  const context = "pages.billForm";

  return (
    <PageContent
      subtitle={t(`${context}.editSubtitle`)}
      leftButton={
        <LinkButton
          variant="secondary"
          onClick={() => {
            event("payables_bill_cancel_clicked");
            navigate("/expenseManagement/expenses");
          }}
        >
          Cancelar
        </LinkButton>
      }
      rightButton={
        <Button
          id={"SAVE"}
          loading={isSaving}
          size="medium"
          variant="primary"
          type="submit"
          form="bill-form"
          onClick={() => {
            event("payables_bill_save_clicked");
          }}
        >
          Salvar
        </Button>
      }
    >
      {data ? (
        <BillForm
          bill={data}
          setLoading={setIsSaving}
          billIsLoading={isLoading}
        />
      ) : (
        <div />
      )}
    </PageContent>
  );
}
