import { trpc } from "@client";

export default function useSelectCategory() {
  const { data, isLoading } = trpc.category.getAll.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const options = data?.categories
    ?.filter((_) => _.active)
    .map((_) => ({
      value: _.id,
      label: _.name,
    }));

  return {
    loading: isLoading,
    options: options || [],
  };
}
