import { ReactNode, createContext, useContext, useState } from "react";

type BillActionModalProps = {
  type:
    | "account"
    | "reject"
    | "delete"
    | "reclassification"
    | "approve"
    | "approvalReject";
  open: boolean;
  onConfirm(value?: string): void;
  quantity?: number;
};

const initialState: BillActionModalProps = {
  open: false,
  type: "account",
  onConfirm: () => undefined,
};

type BillActionModalProviderProps = {
  children: ReactNode;
};

type ContextValue = {
  billActionModal: BillActionModalProps;
  setBillActionModal: React.Dispatch<
    React.SetStateAction<BillActionModalProps>
  >;
  closeModal: () => void;
  description?: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const BillActionModalContext = createContext<ContextValue | undefined>(
  undefined
);

export const BillActionModalProvider = ({
  children,
}: BillActionModalProviderProps) => {
  const [billActionModal, setBillActionModal] = useState(initialState);
  const [description, setDescription] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BillActionModalContext.Provider
      value={{
        billActionModal,
        setBillActionModal,
        setDescription,
        description,
        isLoading,
        setIsLoading,
        closeModal: () => setBillActionModal(initialState),
      }}
    >
      {children}
    </BillActionModalContext.Provider>
  );
};

export const useBillActionModal = () => {
  const context = useContext(BillActionModalContext);
  if (!context) {
    throw new Error(
      "useBillActionModal must be used within a BillActionModalProvider"
    );
  }
  return context;
};
