type Options = "dateTime" | "date" | "time";

export function formatDateTime(date: Date, type: Options = "dateTime") {
  const dateOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  } as const;
  const timeOptions = { hour: "2-digit", minute: "2-digit" } as const;

  const formattedDate = new Intl.DateTimeFormat("default", dateOptions).format(
    date
  );
  const formattedTime = new Intl.DateTimeFormat("default", timeOptions).format(
    date
  );

  switch (type) {
    case "dateTime":
      return `${formattedDate} - ${formattedTime}`;
    case "date":
      return formattedDate;
    case "time":
      return formattedTime;
  }
}
