import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xl}`};
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xl4}`};
  margin-top: ${({ theme }) => `-${theme.spacings.l}`};
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;

export const StyledIcon = styled(ShapeIcon)`
  stroke: ${({ theme }) => theme.palette.primary.main};
  width: ${({ theme }) => theme.icons.sizes.xl};
  height: ${({ theme }) => theme.icons.sizes.xl};
  stroke-width: 1px;
`;

export const TypographyPrimary = styled(StyledTypography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ImagePreview = styled.img``;
