import { trpc } from "@client";
import { useTracking } from "@common/hooks/useTracking";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { BillsList } from "@organisms/bills-list";
import { SelectedRowsProvider } from "@organisms/select-rows-context";
import { toCurrency } from "@utils/toCurrency";
import { useState } from "react";
import {
  BigNumberContainer,
  Container,
  RowContainer,
  SkeletonContainer,
} from "./styled";

export function Accounting() {
  const [pendingFilter, setPendingFilter] = useState(false);
  const [paidFilter, setPaidFilter] = useState(false);
  const event = useTracking();

  const { data, isLoading, isFetching } = trpc.bill.summary.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  );

  event("payables_bill_account_tab");

  return (
    <Container>
      {isLoading || isFetching ? (
        <RowContainer>
          <BigNumberSkeleton />
          <BigNumberSkeleton />
        </RowContainer>
      ) : (
        <>
          {data && (
            <RowContainer>
              <BigNumberContainer
                disabled={paidFilter}
                icon="IconBox"
                iconColors={{ primary: "#015FCB", secondary: "#D4E8FF" }}
                orientation="horizontal"
                title={`${data.pending?.qty || 0} ${
                  data?.pending?.qty > 1
                    ? "Contabilizações pendentes"
                    : "Contabilização pendente"
                }`}
                value={toCurrency(data.pending?.total || 0)}
                buttonLabel={pendingFilter ? "Remover filtro" : "Filtrar"}
                buttonVariant="link"
                onSelect={() => {
                  setPendingFilter(!pendingFilter);
                }}
              />
              <BigNumberContainer
                disabled={pendingFilter}
                icon="IconBox"
                iconColors={{ primary: "#21A38A", secondary: "#D7F9F3" }}
                orientation="horizontal"
                title={`${data.paid?.qty || 0} ${
                  data.paid?.qty > 1 ? "Contabilizados" : "Contabilizado"
                } `}
                value={toCurrency(data.paid?.total || 0)}
                buttonLabel={paidFilter ? "Remover filtro" : "Filtrar"}
                buttonVariant="link"
                onSelect={() => {
                  setPaidFilter(!paidFilter);
                }}
              />
            </RowContainer>
          )}
        </>
      )}
      <SelectedRowsProvider>
        <BillsList
          listContext="company"
          externalFilters={{ paidFilter, pendingFilter }}
          isAccounting
        />
      </SelectedRowsProvider>
    </Container>
  );
}

function BigNumberSkeleton() {
  return (
    <SkeletonContainer>
      <Skeleton
        animation="pulse"
        variant="rounded"
        width={"65%"}
        height={"15px"}
      />
      <Skeleton
        animation="pulse"
        variant="rounded"
        width={"80%"}
        height={"40px"}
      />
      <Skeleton
        animation="pulse"
        variant="rounded"
        width={"40%"}
        height={"15px"}
      />
    </SkeletonContainer>
  );
}
