import { RowCheckBox } from "@molecules/checkbox/rowcheckBox";
import { GridActions } from "@organisms/grid-actions";
import { useVendorActions } from "./useVendorActions";
import { VendorFilters, useVendorData } from "./useVendorData";

export function UseVendorRows(filters: VendorFilters) {
  const { data, isLoading } = useVendorData(filters);
  const { buildVendorActions } = useVendorActions();

  return {
    data,
    isLoading,
    rows:
      data?.map((_, i) => {
        return {
          id: _.id,
          name: _.name || "",
          legalName: _.legalName,
          vendorCode: _.code || "",
          paymentMethod:
            _.bankData?.find((_) => _.favorite)?.paymentMethod ||
            _.bankData?.at(-1)?.paymentMethod ||
            "",
          active: _.active,
          lineOfBusiness: _.lineOfBusiness,
          responsibleName: _.responsibleEmployee?.name,
          responsibleEmail: _.responsibleEmployee?.email,
          select: <RowCheckBox id={_.id} />,

          actions: (
            <GridActions actions={buildVendorActions(_.id, "grid", _.active)} />
          ),
        };
      }) || [],
  };
}
