import { ExpenseFeatures } from "@common/expense-features";
import { getFromLS, useSelectedCompany } from "@flash-hros/utility";

export function usePlanFeatures() {
  const { selectedCompany } = useSelectedCompany();
  const features =
    getFromLS(`expensesPlanFeatures-${selectedCompany.id}`) || [];

  function validateFeature(feature: ExpenseFeatures) {
    return feature.includes(feature);
  }

  function isBasicPlan() {
    return !validateFeature("INTERMEDIATE_APPROVALS");
  }

  return {
    features,
    validateFeature,
    isBasicPlan,
  };
}
