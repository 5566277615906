import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import TableClient from "@organisms/TableClient";
import { useTranslation } from "react-i18next";
import { ApprovalFlowGrid } from "./useApprovalFlowData";

export const useApprovalFlowColumns = () => {
  const { t } = useTranslation();
  const context = "components.approvalFlowGrid.columns";

  type Columns = React.ComponentProps<
    typeof TableClient<ApprovalFlowGrid>
  >["columns"];

  const columns = [
    {
      header: t(`${context}.priority`),
      accessorKey: "priority",
      cell: ({
        row: {
          original: { priority = "" },
        },
      }) => {
        return <Typography variant="body3">{priority}</Typography>;
      },
    },
    {
      header: t(`${context}.name`),
      accessorKey: "name",
      cell: ({
        row: {
          original: { name = "" },
        },
      }) => {
        return <Typography variant="body3">{name}</Typography>;
      },
    },
    {
      header: t(`${context}.description`),
      accessorKey: "description",
      cell: ({
        row: {
          original: { description = "" },
        },
      }) => {
        return <Typography variant="body3">{description}</Typography>;
      },
    },
    {
      header: t(`${context}.approvalType`),
      accessorKey: "approvalType",
      filterFn: "customIncludes",
      cell: ({
        row: {
          original: { approvalType = null },
        },
      }) => {
        return (
          <Typography variant="body3">
            {approvalType === "AUTO"
              ? t(`${context}.automatic`)
              : t(`${context}.custom`)}
          </Typography>
        );
      },
    },
    // column used only for filter
    {
      header: "",
      accessorKey: "status",
      isHidden: true,
      filterFn: "customIncludes",
    },
    {
      header: t(`${context}.status`),
      accessorKey: "statusToggle",
      isSticky: true,
      enableGlobalFilter: false,
      cell: ({
        row: {
          original: { statusToggle },
        },
      }) => {
        return statusToggle;
      },
    },
    {
      header: t(`${context}.actions`),
      accessorKey: "actions",
      isSticky: true,
      cell: ({
        row: {
          original: { actions = null },
        },
      }) => {
        return actions;
      },
    },
  ] satisfies Columns;
  return columns;
};
