import { formatDateTime } from "@utils/dateFormater";
import { toCurrency } from "@utils/toCurrency";
import { ApprovalRow } from "./useApprovalRow";

export function useExportList() {
  const columnsLabel = [
    "Fornecedor",
    "Categoria",
    "Data de Vencimento",
    "Valor",
    "Pagamento",
    "Status do pagamento",
  ];

  function resolver(rows: ApprovalRow[]) {
    {
      return rows.map(
        ({
          vendorLegalName,
          category,
          dueDate,
          amount,
          paymentType,
          status,
        }) => {
          return [
            vendorLegalName,
            category,
            dueDate ? formatDateTime(dueDate) : "",
            toCurrency(amount),
            paymentType,
            status,
          ];
        }
      );
    }
  }

  return {
    resolver,
    columnsLabel,
  };
}
