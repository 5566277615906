import { useCategories } from "@common/hooks/useCategory";
import { useCostCenters } from "@common/hooks/useCostCenter";
import { paymentMethod } from "@common/payment-methods";
import { RowCheckBox } from "@molecules/checkbox/rowcheckBox";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import React from "react";
import { BillActions } from "../billActions";
import { useBillData } from "./useBillData";

export type BillRowsContext = "company" | "employee";
export type BillStatus =
  | "OPEN"
  | "REJECTED"
  | "PROCESSING_APPROVAL"
  | "ERROR_SUBMITTING_APPROVAL"
  | "AWAIT_APPROVAL"
  | "PENDING"
  | "PAID"
  | "CANCELED";

export type BillRow = {
  id: string;
  status: BillStatus;
  category: string;
  categoryId: string;
  vendorId: string;
  dueDate?: Date;
  amount: number;
  paymentType: string;
  costCenter: string;
  vendorLegalName: string;
  vendorCode: string;
  select: React.ReactNode;
  actions: React.ReactNode;
};

export function UseBillsRows(context: BillRowsContext) {
  const { bills, isLoading: billIsLoading, amountRange } = useBillData(context);

  // Retrieve category and cost center data
  const { costCenters, isLoading: costCenterLoading } = useCostCenters();
  const { categories, isLoading: categoryLoading } = useCategories();

  const isLoading = billIsLoading || categoryLoading || costCenterLoading;

  const rows = bills?.map((bill) => {
    return {
      id: bill.id,
      status: bill.status,
      category: categories?.find((c) => c.id === bill.categoryId)?.name || "",
      dueDate: bill.dueAt ? new Date(bill.dueAt) : undefined,
      amount: bill.amount,
      paymentType:
        paymentMethod.find((p) => p.value === bill.paymentTerms?.paymentMethod)
          ?.label || "",
      costCenter:
        costCenters?.find((c) => c.id === bill.costCenterId)?.name || "",
      vendorLegalName: bill.vendor.legalName,
      vendorCode: bill.vendor.code,
      vendorId: bill.vendor.id,
      categoryId: bill.categoryId || "",
      select: <RowCheckBox id={bill.id} />,
      actions: (
        <BillActionModalProvider>
          <BillActions bill={bill} context={context} />
        </BillActionModalProvider>
      ),
    } satisfies BillRow;
  });

  return {
    isLoading: isLoading,
    rows,
    amountRange,
  };
}
