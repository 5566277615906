import { DataGrid } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const GridContainer = styled.div`
  position: relative;
`;

export const StyledDataGrid = styled(DataGrid)`
  & .data-grid-custom-select {
    background-color: green;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
`;
