import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FlexContainer = styled.div<{ expand?: boolean }>`
  ${(props) => props.expand && "grid-column-start: 1;"}
  ${(props) => props.expand && "grid-column-end: 3;"}
  display: flex;
  flex-direction: column;
`;

export const StyledTag = styled(Tag)`
  cursor: auto;
  pointer-events: none;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;
