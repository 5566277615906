import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DataDisplay } from "@molecules/Data-display";
import { Divider } from "@mui/material";
import { AttachmentPreview } from "@organisms/attachment-preview";
import { VendorFields } from "@organisms/vendor-details/hooks/useVendorFields";
import { useTranslation } from "react-i18next";
import { Vendor } from "server/src/services/company-payables-service/company-payables.types";
import {
  Container,
  CustomContainer,
  FlexContainer,
  GapContainer,
  RowContainer,
  SpanContainer,
  StyledIcon,
  SubTitle,
  TitleContainer,
} from "./styled";

export function VendorInformation({
  fields,
  vendor,
}: {
  fields: VendorFields;
  vendor: Vendor;
}) {
  const { t } = useTranslation();
  const context = "components.vendorDetails.detailsTab.sections";

  return (
    <Container>
      <GapContainer>
        <SectionContainer title={t(`${context}.baseData`)}>
          {fields.baseData.map((field, i) => {
            return <DataDisplay key={i} {...field} />;
          })}
          {!!fields.contactData.length && (
            <>
              <SubtitleContainer title={t(`${context}.contactData`)} />
              {fields.contactData.map((field, i) => {
                return <DataDisplay key={i} {...field} />;
              })}
            </>
          )}
          {!!fields.EmployeeData.length && (
            <>
              <SubtitleContainer title={t(`${context}.responsible`)} />
              {fields.EmployeeData.map((field, i) => {
                return <DataDisplay key={i} {...field} />;
              })}
            </>
          )}
        </SectionContainer>
        {vendor.attachments && (
          <SectionContainer title={t(`${context}.attachment`)}>
            <FlexContainer expand>
              {vendor.attachments?.map((attachment) => {
                return (
                  <AttachmentPreview
                    src={attachment.presignedURL as string}
                    title={attachment.fileName}
                  />
                );
              })}
            </FlexContainer>
          </SectionContainer>
        )}
      </GapContainer>
      <GapContainer>
        <SectionContainer title={t(`${context}.address`)}>
          {fields.addressData.map((field, i) => {
            return <DataDisplay key={i} {...field} />;
          })}
        </SectionContainer>
        <SectionContainer title={t(`${context}.payment`)}>
          {fields.paymentData.map((data, i) => {
            return (
              <>
                {!!i && (
                  <Divider
                    style={{
                      width: "100%",
                      gridColumnStart: 1,
                      gridColumnEnd: 3,
                    }}
                  />
                )}
                <RowContainer>
                  <Typography variant="headline8">{data?.type}</Typography>
                  {data.favorite && (
                    <StyledIcon name="IconStarFilled" size={24} />
                  )}
                </RowContainer>

                {data.fields?.map((field, i) => {
                  return <DataDisplay key={i} {...field} />;
                })}
              </>
            );
          })}
        </SectionContainer>
      </GapContainer>
    </Container>
  );
}

function SectionContainer({
  title,
  children,
}: {
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <CustomContainer>
      <TitleContainer>
        <Typography variant="headline8" weight={700}>
          {title}
        </Typography>
      </TitleContainer>
      {children}
    </CustomContainer>
  );
}

function SubtitleContainer({ title }: { title: string }) {
  return (
    <SpanContainer>
      <Divider style={{ width: "100%" }} />
      <SubTitle>
        <Typography variant="headline9" weight={700}>
          {title}
        </Typography>
      </SubTitle>
    </SpanContainer>
  );
}
