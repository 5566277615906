import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import { SummaryBox, SummaryContainer } from "./styled";

type summaryProps = {
  title: string;
  children: React.ReactNode;
};
export function Summary({ title, children }: summaryProps) {
  return (
    <SummaryContainer>
      <SummaryBox>
        <Typography variant="headline8" weight={700}>
          {title}
        </Typography>
      </SummaryBox>
      <Divider style={{ width: "100%" }} />
      <SummaryBox>{children}</SummaryBox>
    </SummaryContainer>
  );
}
