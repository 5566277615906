import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const SelectContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacings.m};
  padding-left: ${(props) => props.theme.spacings.xs};
  gap: ${(props) => props.theme.spacings.xs};
  background-color: ${(props) => props.theme.colors.neutral[95]};
  position: absolute;
  z-index: 15;
  left: 1px;
  top: 1px;
  right: 1px;
  height: 50px;
  border-top-right-radius: ${(props) => props.theme.borders.radius.m};
  border-top-left-radius: ${(props) => props.theme.borders.radius.m};
`;
