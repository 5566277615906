import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { GridActions } from "@organisms/grid-actions";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import { useApproveBill } from "./hooks/useApproveBill";
import { Container } from "./styled";

type ApprovalGridAction = {
  bill: Bill;
};
export function ApprovalGridActions({ bill }: ApprovalGridAction) {
  const navigate = useNavigate();
  const { setBillActionModal } = useBillActionModal();
  const { approve, canApprove } = useApproveBill();
  const { t } = useTranslation();

  const context = "components.approvalsList.actions";

  switch (bill.status) {
    case "OPEN":
    case "PAID":
    case "REJECTED":
      return (
        <Container>
          <Button
            size="small"
            buttonType="secondary"
            variantType="neutral"
            onClick={() =>
              navigate(`/expense/company-payables/bills/details/${bill.id}`)
            }
          >
            {t(`${context}.details`)}
          </Button>
        </Container>
      );
    case "AWAIT_APPROVAL":
      return (
        <Container>
          {canApprove(bill) && (
            <>
              <Button
                size="small"
                buttonType="secondary"
                variantType="success"
                onClick={() => {
                  setBillActionModal({
                    open: true,
                    type: "approve",
                    onConfirm: () => {
                      approve({ id: bill.id, action: "APPROVED" });
                    },
                  });
                }}
              >
                {t(`${context}.aprove`)}
              </Button>
              <Button
                size="small"
                buttonType="secondary"
                variantType="error"
                onClick={() =>
                  setBillActionModal({
                    open: true,
                    type: "approvalReject",
                    onConfirm: (value) => {
                      if (value) {
                        approve({
                          id: bill.id,
                          action: "REJECTED",
                          comment: value,
                        });
                      } else {
                        dispatchToast({
                          type: "error",
                          content:
                            "É necessário informar um motivo para a reprovação",
                        });
                      }
                    },
                  })
                }
              >
                {t(`${context}.reject`)}
              </Button>
            </>
          )}

          <GridActions
            actions={[
              {
                description: t(`${context}.details`),
                icon: "IconFileDescription",
                onclick: () => {
                  navigate(
                    `/expense/company-payables/bills/details/${bill.id}`
                  );
                },
                visible: true,
              },
            ]}
          />
        </Container>
      );
    default:
      return (
        <Container>
          <Button
            size="small"
            variant="secondary"
            variantType="neutral"
            onClick={() =>
              navigate(`/expense/company-payables/bills/details/${bill.id}`)
            }
          >
            {t(`${context}.details`)}
          </Button>
        </Container>
      );
  }
}
