import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
  .MuiBreadcrumbs-li:first-child {
    position: relative;

    &::before {
      border-bottom: 2px solid ${(props) => props.theme.colors.neutral[50]};
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      transition: all 0.2s ease-in;
    }

    &:hover {
      &::before {
        width: 65%;
      }
    }
  }
`;

export const BreadcrumbTypography = styled(Typography).attrs({
  variant: "body4",
})`
  font-weight: 700 !important;
`;
