import { AccountGridAction } from "@organisms/account-grid-actions";
import { BillActionModal } from "@organisms/bill-action-modal/billActionModal";
import { GridActions } from "@organisms/grid-actions";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import { useBillActions } from "./hooks/useBillActions";
import { BillRowsContext } from "./hooks/useBillsRows";

export function BillActions({
  context,
  bill,
}: {
  context: BillRowsContext;
  bill: Bill;
}) {
  const { buildActions } = useBillActions();

  if (context === "employee") {
    return (
      <>
        <BillActionModal />
        <GridActions
          actions={buildActions(
            bill.id,
            "grid",
            bill.status,
            bill.createdBy,
            bill.transaction?.id
          )}
        />
      </>
    );
  }

  return (
    <>
      <BillActionModal />
      <AccountGridAction bill={bill} />
    </>
  );
}
