import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
`;

export const FlexRowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const DropContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledDropZone = styled(Dropzone)<{ span: number }>`
  display: grid;
  grid-template-columns: ${({ span }) => (span ? "1fr 2fr" : "1fr")};
  gap: ${({ theme }) => theme.spacings.xs3};

  .dropzone {
    grid-row: 1 / span ${({ span }) => span + 1};
  }

  .dropzone-file-item {
    margin-top: 0 !important;
  }
`;
