import { transformToRecord } from "@utils/dataRecord";

export const comparisonList = [
  { value: "EQUAL", label: "é igual" },
  { value: "GREATER_THAN", label: "é maior que" },
  {
    value: "GREATER_THAN_OR_EQUAL",
    label: "é maior ou igual que",
  },
  { value: "LESS_THAN", label: "é menor que" },
  {
    value: "LESS_THAN_OR_EQUAL",
    label: "é menor ou igual que",
  },
];

export const comparisonListRecord = transformToRecord(comparisonList);
