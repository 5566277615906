import {
  IconButton,
  Icons,
  SelectField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Badge } from "@molecules/bedge/bedge";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApprovalFlow } from "../approval-flow-schema";
import { SelectApprover } from "../select-approver/selectApprover";
import { Box, Grid } from "../styled";

type ApprovalSelectorProps = {
  index: number;
};
export function ApprovalSelect({ index }: ApprovalSelectorProps) {
  const { control, watch, setValue } = useFormContext<ApprovalFlow>();
  const { t } = useTranslation();
  const context = "components.approvalFlowForm.stepTwo.condition.fields";

  const type = watch(`condition.${index}.conditionType`);

  function deleteCondition() {
    setValue(
      "condition",
      watch("condition").filter((_, conditionIndex) => conditionIndex !== index)
    );
  }

  return (
    <Grid template={"auto repeat(2, 1fr) 50px"}>
      <Badge value={`${index + 1}`} />
      <Controller
        control={control}
        name={`condition.${index}.conditionType`}
        render={({ field: { value, onChange }, fieldState }) => (
          <SelectField
            onSelectChange={(_, option) => onChange(option?.value)}
            label={`${t(`${context}.criteria`)}`}
            value={value}
            error={!!fieldState.error}
            helperText={!!fieldState.error && "Campo obrigatório"}
            options={[
              { value: "MANUAL", label: "Escolher aprovador manual" },
              { value: "HIERARCHY", label: "Aprovação por hierarquia" },
              {
                value: "COST_CENTER_OWNER",
                label: "Responsável pelo centro de custo",
              },
            ]}
          />
        )}
      />
      {type === "HIERARCHY" && (
        <Controller
          control={control}
          name={`condition.${index}.value`}
          render={({ field: { value, onChange } }) => (
            <SelectField
              value={value}
              onSelectChange={(_, option) => onChange(option?.value)}
              label={`${t(`${context}.hierarchyLevel`)}`}
              options={[
                { value: "1", label: "nível 1" },
                { value: "2", label: "nível 2" },
                { value: "3", label: "nível 3" },
                { value: "4", label: "nível 4" },
                { value: "5", label: "nível 5" },
              ]}
            />
          )}
        />
      )}
      {type === "MANUAL" && (
        <Controller
          control={control}
          name={`condition.${index}.value`}
          defaultValue={""}
          render={({ field: { value, onChange }, fieldState }) => (
            <SelectApprover
              error={!!fieldState.error}
              helperText={!!fieldState.error && "Campo obrigatório"}
              value={value}
              onSelectChange={(_, option) => {
                setValue(`condition.${index}.approver`, option?.label);
                onChange(option?.value);
              }}
              label={`${t(`${context}.person`)}`}
            />
          )}
        />
      )}

      <Box columnStart={4}>
        <IconButton variant="line" size="medium">
          <Icons name="IconTrash" onClick={deleteCondition} />
        </IconButton>
      </Box>
    </Grid>
  );
}
