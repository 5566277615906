import { PDFViewer, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DataDisplay } from "@molecules/Data-display";
import { Tag } from "@molecules/tag";
import { AttachmentPreview } from "@organisms/attachment-preview";
import { useBillFields } from "@organisms/bill-details-tab/hooks/useBillFields";
import { TransactionList } from "@organisms/transaction-list";
import { useTranslation } from "react-i18next";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import {
  ApproverBox,
  ApproverRow,
  BaseGrid,
  Content,
  DataBox,
  GridPadding,
  StyledIcon,
  StyledTypography,
} from "./styled";

type DetailBillTabProps = {
  bill: Bill;
  category?: string;
};

const statusTag = {
  APPROVED: "green",
  REJECTED: "error",
  PENDING: "gray",
} as const;
export function DetailTab({ bill }: DetailBillTabProps) {
  const { fields, paymentFields } = useBillFields(bill);
  const { t } = useTranslation();
  const context = "components.billDetailsTab";

  return (
    <>
      <Content>
        <StyledTypography variant="headline8">
          {t(`${context}.document`)}
        </StyledTypography>
        <GridPadding gridTemplate="1.5fr 1fr">
          {bill.document.presignedURL ? (
            <PDFViewer
              style={{ width: "100%" }}
              height={"850px"}
              options={{ fit: "height", showToolbar: true }}
              src={bill.document.presignedURL}
            />
          ) : (
            <div />
          )}
          <div>
            <BaseGrid gridTemplate="1fr 1fr">
              {fields.map((field, i) => {
                return <DataDisplay key={i} {...field} />;
              })}
            </BaseGrid>
            {bill.approvalWorkflow?.approvers && (
              <ApproverBox>
                <Typography variant="body3">
                  {t(`${context}.approvers.label`)}
                </Typography>
                {bill.approvalWorkflow?.approvers?.map((approver) => {
                  return (
                    <ApproverRow key={approver.approverId}>
                      <Tag variant={statusTag[approver.status]}>
                        {approver.status === "APPROVED" && (
                          <StyledIcon name="IconCheck" size={16} />
                        )}
                        {approver.status === "REJECTED" && (
                          <StyledIcon name="IconX" size={16} />
                        )}
                        {approver.status === "PENDING" && approver.level}
                        {` ${approver.approverName}`}
                      </Tag>
                      <Typography variant="body3">
                        {t(`${context}.approvers.status.${approver.status}`)}
                      </Typography>
                    </ApproverRow>
                  );
                })}
              </ApproverBox>
            )}
          </div>
        </GridPadding>
      </Content>
      <BaseGrid gridTemplate="1fr 1fr">
        <Content>
          <StyledTypography variant="headline8">
            {t(`${context}.paymentData`)}
          </StyledTypography>
          {bill.transaction ? (
            <GridPadding gridTemplate="1fr">
              <Typography variant="body3" weight={700}>
                {t(`${context}.transaction`)}
              </Typography>
              <TransactionList
                rows={[
                  {
                    description: bill.transaction?.description,
                    value: bill.amount,
                    date: bill.issuedAt,
                    card: bill.transaction?.cardNumberLastFour,
                  },
                ]}
                itemActions="none"
              />
            </GridPadding>
          ) : (
            <DataBox>
              {paymentFields.map((field, i) => {
                return <DataDisplay key={i} {...field} />;
              })}
            </DataBox>
          )}
        </Content>
        {bill.attachments && (
          <div>
            <Content>
              <StyledTypography variant="headline8">
                {t(`${context}.attachments`)}
              </StyledTypography>
              <GridPadding gridTemplate="1fr">
                {bill.attachments?.map((attachment) => {
                  return (
                    <AttachmentPreview
                      src={attachment.presignedURL as string}
                      title={attachment.fileName}
                    />
                  );
                })}
              </GridPadding>
            </Content>
          </div>
        )}
      </BaseGrid>
    </>
  );
}
