import { ReactNode, createContext, useContext, useState } from "react";

type ApprovalActionModalProps = {
  type?: "delete" | "inactive";
  open: boolean;
  onConfirm?(): void;
  loading?: boolean;
};

const initialState: ApprovalActionModalProps = {
  open: false,
  loading: false,
};

type ApprovalActionModalProviderProps = {
  children: ReactNode;
};

type ContextValue = {
  billActionModal: ApprovalActionModalProps;
  setBillActionModal: React.Dispatch<
    React.SetStateAction<ApprovalActionModalProps>
  >;
  closeModal: () => void;
};

export const ApprovalActionModalContext = createContext<
  ContextValue | undefined
>(undefined);

export const ApprovalFlowActionModalProvider = ({
  children,
}: ApprovalActionModalProviderProps) => {
  const [approvalFlowActionModal, setApprovalFlowActionModal] =
    useState(initialState);

  return (
    <ApprovalActionModalContext.Provider
      value={{
        billActionModal: approvalFlowActionModal,
        setBillActionModal: setApprovalFlowActionModal,
        closeModal: () => setApprovalFlowActionModal(initialState),
      }}
    >
      {children}
    </ApprovalActionModalContext.Provider>
  );
};

export const useApprovalFlowActionModal = () => {
  const context = useContext(ApprovalActionModalContext);
  if (!context) {
    throw new Error(
      "useApprovalFlowActionModal must be used within a ApprovalFlowActionModalProvider"
    );
  }
  return context;
};
