import {
  LinkButton,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApprovalFlow } from "../approval-flow-schema";
import { FormBox, FormContainer, RowContainer } from "../styled";
import { ApprovalSelect } from "./approval-select";

export function StepTwo() {
  const { setValue, watch, resetField } = useFormContext<ApprovalFlow>();
  const { t } = useTranslation();
  const context = "components.approvalFlowForm.stepTwo";

  const approvalType = watch("approvalType");
  const conditions = watch("condition");

  const setApprovalType = (type: "CUSTOM" | "AUTO") => {
    setValue("approvalType", type);
    resetField("condition", {});
  };

  return (
    <FormBox>
      <FormContainer template="1fr">
        <RowContainer>
          <Radio
            checked={approvalType === "CUSTOM"}
            onChange={() => setApprovalType("CUSTOM")}
          />
          <Typography variant="headline8" weight={700}>
            {t(`${context}.byLevel.title`)}
            <Typography variant="body4">
              {t(`${context}.byLevel.subtitle`)}
            </Typography>
          </Typography>
        </RowContainer>
        {approvalType === "CUSTOM" && (
          <>
            {conditions.map((_, index) => (
              <ApprovalSelect key={index} index={index} />
            ))}
            <RowContainer>
              <LinkButton
                variant="primary"
                onClick={() =>
                  setValue("condition", [...conditions, {} as any])
                }
              >
                {t(`${context}.condition.addCondition`)}
              </LinkButton>
            </RowContainer>
          </>
        )}
      </FormContainer>
      <FormContainer template="1fr">
        <RowContainer>
          <Radio
            checked={approvalType === "AUTO"}
            onChange={() => setApprovalType("AUTO")}
          />
          <Typography variant="headline8" weight={700}>
            {t(`${context}.automatic.title`)}
            <Typography variant="body4">
              {t(`${context}.automatic.subtitle`)}
            </Typography>
          </Typography>
        </RowContainer>
      </FormContainer>
    </FormBox>
  );
}
