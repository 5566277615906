import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedRowsContext } from "@organisms/select-rows-context";

type BillListCheckboxProps = {
  id: string;
};

export function RowCheckBox({ id }: BillListCheckboxProps) {
  const { selectedRows, setSelectedRows } = useSelectedRowsContext();

  const onSelectItemClick = (value: string) => {
    const selected = selectedRows.includes(value);
    if (selected) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== value));
    } else {
      setSelectedRows([...selectedRows, value]);
    }
  };

  return (
    <Checkbox
      size="small"
      onChange={() => onSelectItemClick(id)}
      checked={selectedRows.includes(id)}
    />
  );
}
