import { useTranslation } from "react-i18next";
import {
  ContentContainer,
  StyledIcon,
  StyledTypography,
  TypographyPrimary,
} from "./styled";

export function Finishing() {
  const { t } = useTranslation();
  const context = "components.onboarding.finishing";

  return (
    <ContentContainer>
      <>
        <StyledIcon name="IconCheck" variant="default" fill="transparent" />

        <TypographyPrimary variant="body3">
          {t(`${context}.allRight`)}
        </TypographyPrimary>
        <StyledTypography variant="headline6" weight={700}>
          {t(`${context}.title`)}
        </StyledTypography>
        <StyledTypography variant="body3">
          {t(`${context}.subtitle`)}
        </StyledTypography>
      </>
    </ContentContainer>
  );
}
