import { Modal, PDFViewer, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AttachmentImage,
  AttachmentPreviewContainer,
  Container,
  IconContainer,
  ImagePreview,
  StyledContainer,
  StyledIcon,
  StyledTypography,
} from "./styled";

export type Attachment = {
  src: string;
  title?: string;
};
export const AttachmentPreview = ({ src, title }: Attachment) => {
  const [isOpen, setIsOpen] = useState(false);
  const isPDF = title?.includes(".pdf");
  const isImage = [".jpeg", ".jpg", ".png", ".gif"].some((ext) =>
    title?.includes(ext)
  );
  const { t } = useTranslation();

  return (
    <AttachmentPreviewContainer>
      <Container>
        {isImage ? (
          <AttachmentImage src={src} />
        ) : (
          <StyledIcon name="IconFile" size={50} />
        )}
        <Typography variant="body3" weight={700}>
          {title}
        </Typography>
      </Container>
      <IconContainer>
        {isImage || isPDF ? (
          <StyledIcon
            name="IconArrowsDiagonal"
            fill="transparent"
            size={16}
            onClick={() => setIsOpen(true)}
          />
        ) : (
          <StyledIcon
            name="IconCloudDownload"
            fill="transparent"
            size={16}
            onClick={() => window.open(src, "_blank")}
          />
        )}
      </IconContainer>
      <Modal.Root open={isOpen} onClose={() => setIsOpen(false)} size="md">
        <>
          <StyledTypography variant="headline6">
            {t("components.attachmentPreview.title")}
          </StyledTypography>
          <StyledContainer>
            {isPDF ? (
              <PDFViewer
                style={{ width: "100%" }}
                height={"642px"}
                options={{ fit: "height", showToolbar: true }}
                src={src}
              />
            ) : (
              <ImagePreview src={src} />
            )}
          </StyledContainer>
        </>
      </Modal.Root>
    </AttachmentPreviewContainer>
  );
};
