import { lineOfBusiness as businessLine } from "@common/line-of-business";
import { paymentMethod as payments } from "@common/payment-methods";
import { GridCheckbox } from "@molecules/checkbox/gridCheckbox";
import { VendorColumn } from "@molecules/vendor-column";
import { useTranslation } from "react-i18next";
import {
  StyledFlexRow,
  StyledTableText,
  StyledTableTextSecondary,
  StyledTag,
} from "./styled";

export const VendorColumns = (ids: string[]) => {
  const { t } = useTranslation();
  const context = "components.vendorGrid.columns";

  return [
    {
      Header: <GridCheckbox ids={ids} />,
      accessor: "select",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { select = null },
        },
      }) => {
        return select;
      },
    },
    {
      Header: t(`${context}.vendor`),
      accessor: "name",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { name = "", legalName = "" },
        },
      }) => {
        return <VendorColumn name={name} legalName={legalName} />;
      },
    },
    {
      Header: t(`${context}.vendorCode`),
      accessor: "vendorCode",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { vendorCode = "" },
        },
      }) => {
        return (
          <StyledTableText style={{ minWidth: "80px" }} variant="body3">
            {vendorCode}
          </StyledTableText>
        );
      },
    },
    {
      Header: t(`${context}.status`),
      accessor: "active",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { active = "" },
        },
      }) => {
        return (
          <StyledFlexRow>
            {active ? (
              <StyledTag hasLeftDotIcon={true} variant="success">
                Ativo
              </StyledTag>
            ) : (
              <StyledTag hasLeftDotIcon={true} variant="yellow">
                Inativo
              </StyledTag>
            )}
          </StyledFlexRow>
        );
      },
    },
    {
      Header: t(`${context}.paymentMethods`),
      accessor: "paymentMethod",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { paymentMethod = null },
        },
      }) => {
        return (
          paymentMethod && (
            <StyledTag
              style={{ minWidth: "200px" }}
              hasLeftDotIcon={true}
              variant="gray"
            >
              {payments.find((_) => _.value === paymentMethod)?.label}
            </StyledTag>
          )
        );
      },
    },
    {
      Header: t(`${context}.pendingPayments`),
      accessor: "pendingPayments",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { pendingPayments = null },
        },
      }) => {
        return (
          <StyledTableText variant="body3">{pendingPayments}</StyledTableText>
        );
      },
    },
    {
      Header: t(`${context}.businessLine`),
      accessor: "lineOfBusiness",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { lineOfBusiness = null },
        },
      }) => {
        return (
          <StyledTableText variant="body3">
            {businessLine.find((_) => _.value === lineOfBusiness)?.label}
          </StyledTableText>
        );
      },
    },
    {
      Header: t(`${context}.responsible`),
      accessor: "responsible",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { responsibleName = null, responsibleEmail = null },
        },
      }) => {
        return (
          <>
            <StyledTableText variant="body3">{responsibleName}</StyledTableText>
            <StyledTableTextSecondary variant="body4">
              {responsibleEmail}
            </StyledTableTextSecondary>
          </>
        );
      },
    },
    {
      Header: t(`${context}.actions`),
      accessor: "actions",
      disableSortBy: true,
      sticky: "right",
      Cell: ({
        row: {
          original: { actions = null },
        },
      }) => {
        return actions;
      },
    },
  ];
};
