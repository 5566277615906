import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
  background-color: ${({ theme }) => theme.colors.secondary[99]};
  border: ${({ theme }) =>
    `${theme.borders.width.xs2} solid ${theme.colors.primary}`};
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
  width: 100%;
`;

export const StyledIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.secondary[30]};
  align-self: flex-start;
  cursor: pointer;
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;
