import { zodResolver } from "@hookform/resolvers/zod";
import { Transaction } from "@organisms/bill-workflow/hooks/UseTransactionRows";
import { toCurrency } from "@utils/toCurrency";
import { useForm } from "react-hook-form";
import { Bill } from "server/src/services/company-payables-service/company-payables.types";
import { z } from "zod";
import { useBillSchema } from "./useBillSchema";

export enum PaymentMethod {
  BILLET = "BILLET",
  TRANSFER = "TRANSFER",
  FLASH_CARD = "FLASH_CARD",
  CARD = "CARD",
  PIX = "PIX",
}

export function useBillForm(transaction?: Transaction, bill?: Bill) {
  const { billSchema, rules, isLoading } = useBillSchema();
  let defaultValues = {};
  if (bill) {
    defaultValues = {
      id: bill.id,
      amount: toCurrency(bill.amount, true),
      currency: bill.currency,
      issuedAt: bill.issuedAt && new Date(bill.issuedAt),
      payment: {
        paymentMethod: bill.paymentTerms?.paymentMethod,
        id: bill.paymentTerms?.paymentId,
        transactionId: bill.transaction?.id,
        description: bill.transaction?.description,
        cardNumberLastFour: bill.transaction?.cardNumberLastFour,
        bank: bill.paymentTerms?.bank,
        accountType: bill.paymentTerms?.accountType,
        agency: bill.paymentTerms?.agency,
        account: bill.paymentTerms?.account,
        pixKey: bill.paymentTerms?.pixKey,
        pixType: bill.paymentTerms?.pixType,
        cardId: bill.paymentTerms?.cardId,
      },
      description: bill.description,
      document: {
        number: bill.document.number,
        amount: bill.document.amount,
        s3Path: bill.document.s3Path,
      },
      dueAt: bill.dueAt && new Date(bill.dueAt),
      vendorId: bill.vendor.id,
      categoryId: bill.categoryId,
      costCenterId: bill.costCenterId,
      documentFile: { previewUrl: bill.document.presignedURL },
      attachments: bill.attachments?.map((att) => {
        return { fileName: att.fileName, s3Path: att.s3Path };
      }),
      attachmentFiles: bill.attachments?.map((att) => {
        const initialFile = new File([""], att.fileName);
        Object.defineProperty(initialFile, "size", { value: 0 });
        return { previewUrl: att.presignedURL, file: initialFile };
      }),
    };
  }
  if (!bill && transaction) {
    defaultValues = {
      amount: toCurrency(transaction.amount, true),
      currency: transaction.currencyString,
      issuedAt: new Date(transaction.createdAt),
      payment: {
        paymentMethod: PaymentMethod.FLASH_CARD,
        transactionId: transaction._id,
        description: transaction.description,
        cardNumberLastFour: transaction.cardLastDigits,
        id: "",
      },
    };
  }
  type BillRequest = z.infer<typeof billSchema>;
  const methods = useForm<BillRequest>({
    resolver: zodResolver(billSchema, undefined, { raw: true }),
    defaultValues,
  });

  return { methods, isLoading, rules };
}
