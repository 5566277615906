import { accountTypes } from "@common/account-type";
import { banks } from "@common/banks";
import { SelectPaymentMethod, paymentMethod } from "@common/payment-methods";
import { pixKeyOptions } from "@common/pix-key-options";
import {
  Button,
  IconButton,
  SelectField,
  TextField,
  TextFieldProps,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PhoneField } from "@molecules/phone-input/phone-field";
import { RegistrationNumberField } from "@molecules/registration-number-field/registration-number-field";
import { StarButton } from "@molecules/star-button";
import { Divider } from "@mui/material";
import { PaymentMethod } from "@organisms/bill-form/hook/useBillSchema";
import { SelectCard } from "@organisms/select-card";
import { PixType, VendorRequest } from "@organisms/vendor-form/vendor-schema";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledFormGrid } from "../vendor-form/styled";
import { Box, Container, DynamicBox, RowContainer } from "./styled";

type FieldsProps = {
  data: VendorRequest["bankData"][number];
  index: number;
  onDelete: (index: number) => void;
  clearFavorite: () => void;
};

export const BankForm = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<VendorRequest>();

  const values = watch("bankData");

  const deleteBankData = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValue("bankData", newValues);
  };

  const clearFavorite = () => {
    const newValues = values.map((data) => {
      data.favorite = false;
      return data;
    });
    setValue("bankData", newValues);
  };

  return (
    <StyledFormGrid>
      <RowContainer>
        <Typography variant="headline8">
          {t(`pages.vendorForm.bankData`)}
          <Typography variant="body4">
            {t(`pages.vendorForm.bankDataSubtitle`)}
          </Typography>
        </Typography>
      </RowContainer>
      {values?.map((methods, index) => {
        return (
          <Fields
            data={methods}
            index={index}
            key={index}
            onDelete={deleteBankData}
            clearFavorite={clearFavorite}
          />
        );
      })}
      <RowContainer>
        <Button
          style={{ width: "100%" }}
          variant="secondary"
          size="small"
          onClick={() => {
            setValue("bankData", [
              ...values,
              { paymentMethod: PaymentMethod.BILLET, favorite: false },
            ]);
          }}
        >
          Adicionar +
        </Button>
      </RowContainer>
    </StyledFormGrid>
  );
};

function Fields({ data, index, onDelete, clearFavorite }: FieldsProps) {
  const {
    setValue,
    register,
    control,
    formState: { errors },
  } = useFormContext<VendorRequest>();
  const { t } = useTranslation();
  const context = "pages.vendorForm.fields";

  const onCardChange = (id: string, number: string) => {
    setValue(`bankData.${index}.cardId`, id);
    setValue(`bankData.${index}.cardNumber`, number);
  };

  return (
    <>
      <RowContainer>
        {!!index && <Divider style={{ width: "100%" }} />}
        <Container>
          <Typography variant="body3" style={{ fontWeight: "700" }}>
            {paymentMethod.find((_) => _.value === data?.paymentMethod)?.label}
          </Typography>
          <Box>
            <Controller
              control={control}
              name={`bankData.${index}.favorite`}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <StarButton
                  checked={value}
                  onClick={() => {
                    clearFavorite();
                    onChange(!value);
                  }}
                />
              )}
            />
            <IconButton
              icon="IconTrash"
              size="medium"
              variant="line"
              onClick={() => onDelete(index)}
            />
          </Box>
        </Container>
      </RowContainer>
      <DynamicBox
        expend={
          !data?.paymentMethod || data?.paymentMethod === PaymentMethod.BILLET
        }
      >
        <Controller
          control={control}
          name={`bankData.${index}.paymentMethod`}
          render={({ field: { onChange, value } }) => (
            <SelectField
              onSelectChange={(_, option) => onChange(option?.value)}
              value={value}
              options={SelectPaymentMethod}
              label={t(`${context}.paymentMethod.label`)}
              error={errors.bankData && !!errors.bankData[index]?.paymentMethod}
              helperText={
                errors.bankData &&
                !!errors.bankData[index]?.paymentMethod &&
                t(`pages.vendorForm.required`)
              }
            />
          )}
        />
      </DynamicBox>
      {data?.paymentMethod === PaymentMethod.TRANSFER && (
        <>
          <Controller
            control={control}
            name={`bankData.${index}.bank`}
            render={({ field: { onChange, value } }) => (
              <SelectField
                onSelectChange={(_, option) => onChange(option?.value)}
                value={value}
                options={banks}
                label={t(`${context}.bank.label`)}
                searchable
                error={errors.bankData && !!errors.bankData[index]?.bank}
              />
            )}
          />
          <Controller
            control={control}
            name={`bankData.${index}.accountType`}
            render={({ field: { onChange, value } }) => (
              <SelectField
                onSelectChange={(_, option) => onChange(option?.value)}
                value={value}
                options={accountTypes}
                label={t(`${context}.accountType.label`)}
                error={errors.bankData && !!errors.bankData[index]?.accountType}
              />
            )}
          />
          <TextField
            {...register(`bankData.${index}.agency`)}
            label={t(`${context}.bankAgency.label`)}
            inputMode="email"
            error={errors.bankData && !!errors.bankData[index]?.agency}
          />
          <TextField
            {...register(`bankData.${index}.account`)}
            label={t(`${context}.bankAccount.label`)}
            inputMode="email"
            error={errors.bankData && !!errors.bankData[index]?.account}
          />
        </>
      )}
      {data?.paymentMethod === PaymentMethod.PIX && (
        <>
          <Controller
            control={control}
            name={`bankData.${index}.pixType`}
            defaultValue={PixType.CPF_CNPJ}
            render={({ field: { onChange, value } }) => (
              <SelectField
                onSelectChange={(_, option) => {
                  onChange(option?.value);
                }}
                value={value}
                options={pixKeyOptions}
                label={t(`${context}.pixKeyType.label`)}
              />
            )}
          />

          <Controller
            control={control}
            name={`bankData.${index}.pixKey`}
            render={({ field }) => (
              <PixKeyField
                type={data.pixType}
                {...field}
                label={
                  pixKeyOptions.find((item) => item.value === data.pixType)
                    ?.label
                }
                error={errors.bankData && !!errors.bankData[index]?.pixKey}
              />
            )}
          />
        </>
      )}

      {data.paymentMethod === PaymentMethod.CARD && (
        <Controller
          control={control}
          name={`bankData.${index}.cardId`}
          render={({ field: { value } }) => (
            <SelectCard
              onSelectChange={(_, option) => {
                onCardChange(option?.value, option?.cardNumber);
              }}
              value={value}
              options={pixKeyOptions}
              label={t(`${context}.card.label`)}
            />
          )}
        />
      )}
    </>
  );
}

function PixKeyField(props: TextFieldProps & { type?: string }) {
  switch (props.type) {
    case "CPF_CNPJ":
      return <RegistrationNumberField {...props} />;
    case "EMAIL":
      return <TextField {...props} />;
    case "PHONE":
      return <PhoneField {...props} />;
    default:
      return <TextField {...props} />;
  }
}
