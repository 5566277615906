import { trpc } from "@client";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { dispatchToast } from "@utils/dispatchToast";

export function useBillBatchActions() {
  const { closeModal } = useBillActionModal();
  const { mutate: deleteBill, isLoading: deleteIsLoading } =
    trpc.bill.delete.useMutation({
      onSuccess: (response) => {
        closeModal();
        if (response.every((_) => _.status === "fulfilled"))
          dispatchToast({
            type: "success",
            content: "Despesas excluídas com sucesso",
          });
        else if (response.every((_) => _.status === "rejected"))
          dispatchToast({
            type: "error",
            content: "Erro ao excluir despesas",
            description: "Tente novamente em alguns instantes",
          });
        else
          dispatchToast({
            type: "warning",
            content: "Erro ao excluir algumas despesas",
            description: "Tente novamente em alguns instantes",
          });
      },
      onError: () => {
        dispatchToast({
          type: "warning",
          content: "Erro ao excluir despesas",
          description: "Tente novamente em alguns instantes",
        });
      },
    });
  const { mutate: approve, isLoading: approveIsLoading } =
    trpc.bill.accountBatch.useMutation({
      onSuccess: (response) => {
        closeModal();
        if (response.every((_) => _.status === "fulfilled"))
          dispatchToast({
            type: "success",
            content: "Despesas contabilizadas com sucesso",
          });
        else if (response.every((_) => _.status === "rejected"))
          dispatchToast({
            type: "error",
            content: "Erro ao contabilizar despesas",
            description: "Tente novamente em alguns instantes",
          });
        else
          dispatchToast({
            type: "warning",
            content: "Erro ao contabilizar algumas despesas",
            description: "Tente novamente em alguns instantes",
          });
      },
      onError: () => {
        dispatchToast({
          type: "warning",
          content: "Erro ao contabilizar algumas despesas",
          description: "Tente novamente em alguns instantes",
        });
      },
    });

  return {
    isLoading: deleteIsLoading || approveIsLoading,
    deleteBill,
    approve,
  };
}
