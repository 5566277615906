import { currency } from "@common/currency";
import {
  DatePicker,
  SelectField,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useBillFormContext } from "@organisms/bill-form/hook/useBillFormContext";
import { PaymentMethod } from "@organisms/bill-form/hook/useBillSchema";
import { SelectCategory } from "@organisms/category-select";
import { SelectCostCenter } from "@organisms/cost-center-select";
import { SelectVendor } from "@organisms/select-vendor/selectVendor";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, RowContainer, StyledLinkButton } from "./styled";

export const BillFormFields = () => {
  const {
    methods: {
      formState: { errors },
      control,
      watch,
    },
    rules,
  } = useBillFormContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const paymentMethod = watch("payment.paymentMethod");
  console.log(errors);

  const isFlahCardTransaction = () => {
    return paymentMethod === PaymentMethod.FLASH_CARD;
  };

  const context = "pages.billForm.fields";
  return (
    <Container>
      <RowContainer>
        {rules.currency?.visible && (
          <Controller
            control={control}
            name="currency"
            defaultValue="BRL"
            render={({ field: { value, onChange } }) => (
              <SelectField
                value={value}
                required={rules.currency?.required}
                label={t(`${context}.currency.label`)}
                searchable
                options={currency}
                inputMode="text"
                onSelectChange={(_, option) => onChange(option?.value)}
                error={!!errors.currency}
                disabled={isFlahCardTransaction()}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="amount"
          defaultValue="0,00"
          render={({ field }) => (
            <TextField
              {...field}
              imaskProps={{
                mask: Number,
                thousandsSeparator: ".",
                padFractionalZeros: true,
                scale: 2,
                radix: ",",
              }}
              disabled={isFlahCardTransaction()}
              label={t(`${context}.amount.label`)}
              inputMode="numeric"
              error={!!errors.amount}
            />
          )}
        />
      </RowContainer>
      <Controller
        control={control}
        name="vendorId"
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <SelectVendor
            required
            value={value}
            onSelectChange={(_, option) => onChange(option?.value)}
            label={t(`${context}.vendor.label`)}
            inputMode="text"
            error={!!errors.vendorId}
            helperText={
              <StyledLinkButton
                variant="primary"
                onClick={() => {
                  navigate("/expense/company-payables/vendors/form");
                }}
              >
                Adicionar novo +
              </StyledLinkButton>
            }
          />
        )}
      />
      {rules.issuedAt?.visible && (
        <Controller
          control={control}
          name="issuedAt"
          defaultValue={new Date()}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              value={value}
              disabled={isFlahCardTransaction()}
              required={rules.issuedAt?.required}
              onDateChange={(e) => onChange(e?.toDate())}
              label={t(`${context}.issueAt.label`)}
              inputMode="text"
              error={!!errors.issuedAt}
            />
          )}
        />
      )}
      {rules.dueAt?.visible && (
        <Controller
          control={control}
          name="dueAt"
          defaultValue={new Date()}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              required={rules.dueAt?.required}
              value={value}
              onDateChange={(e) => onChange(e?.toDate())}
              label={t(`${context}.dueDate.label`)}
              inputMode="text"
              error={!!errors.dueAt}
            />
          )}
        />
      )}
      {rules["document.number"]?.visible && (
        <Controller
          control={control}
          name="document.number"
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              required={rules["document.number"]?.required}
              imaskProps={{ mask: Number }}
              label={t(`${context}.documentNumber.label`)}
              inputMode="text"
              error={!!errors.document?.number}
            />
          )}
        />
      )}
      {rules.categoryId?.visible && (
        <Controller
          control={control}
          name="categoryId"
          defaultValue={undefined}
          render={({ field: { value, onChange } }) => (
            <SelectCategory
              value={value}
              required={rules.categoryId?.required}
              onSelectChange={(_, option) => onChange(option?.value)}
              options={[]}
              label={t(`${context}.category.label`)}
              inputMode="text"
              error={!!errors.categoryId}
            />
          )}
        />
      )}
      {rules.costCenterId?.visible && (
        <Controller
          control={control}
          name="costCenterId"
          defaultValue={undefined}
          render={({ field: { value, onChange } }) => (
            <SelectCostCenter
              value={value}
              required={rules.costCenterId?.required}
              onSelectChange={(_, option) => onChange(option?.value)}
              options={[]}
              label={t(`${context}.costCenter.label`)}
              inputMode="text"
              error={!!errors.costCenterId}
            />
          )}
        />
      )}
      {rules.description?.visible && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                required={rules.description?.required}
                rows={4}
                label={t(`${context}.description.label`)}
                inputMode="text"
                helperText={
                  <>
                    <span>limite 100 caracteres</span>
                    <span style={{ flexGrow: 1, textAlign: "end" }}>
                      {`${field.value?.length || 0}/100`}
                    </span>
                  </>
                }
                error={!!errors.description}
              />
            )}
          />
        </div>
      )}
    </Container>
  );
};
