import {
  IconTypes,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import React, { useState } from "react";
import { Box, Container, StyledIcon, StyledTypography } from "./styled";

type notificationCardProps = {
  Title: string;
  content: string | React.ReactNode;
  iconName: IconTypes;
};
export function NotificationCard({
  Title,
  content,
  iconName,
}: notificationCardProps) {
  const [closed, setClosed] = useState(false);
  if (closed) return null;

  return (
    <Box>
      <ShapeIcon name={iconName} variant="default" size={48} stroke="default" />
      <Container>
        <StyledTypography variant="body3" weight={700} variantColor="default">
          {Title}
        </StyledTypography>
        <Typography variant="body4">{content}</Typography>
      </Container>
      <StyledIcon
        name="IconX"
        stroke="default"
        onClick={() => setClosed(true)}
      />
    </Box>
  );
}
