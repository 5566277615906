import { trpc } from "@client";
import { Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { useBillFormContext } from "@organisms/bill-form/hook/useBillFormContext";
import { dispatchToast } from "@utils/dispatchToast";
import { useState } from "react";

export function useCardTransaction(cardId: string) {
  const { data, isLoading } =
    trpc.expenseGateway.cardTransactions.useQuery(cardId);
  const {
    methods: { setValue, watch },
  } = useBillFormContext();
  const [selected, setSelected] = useState<string>();

  const onConfirm = () => {
    const payment = data?.find((_) => _._id === selected);
    const amount = parseInt(watch("amount").replace(/[,.]/, ""));

    if (payment?.amount !== amount) {
      dispatchToast({
        type: "warning",
        description:
          "Caso esteja errado, ajuste o valor da despesa ou altere a transação.",
        content:
          "O valor da transação é diferente do valor informado para a despesa ",
      });
      return;
    }
    setValue("payment.transactionId", payment?._id);
    setValue(
      "payment.description",
      payment?.description || payment?.merchantName
    );
  };

  const rows = data?.map((transaction) => {
    return {
      select: (
        <Radio
          checked={selected === transaction._id}
          onChange={() => setSelected(transaction._id)}
        />
      ),
      description: transaction.description || transaction.merchantName,
      value: transaction.localAmount || transaction.amount,
      date: transaction.createdAt,
      card: transaction.cardNumber,
    };
  });

  return {
    isLoading,
    rows,
    onConfirm,
  };
}
