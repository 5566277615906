import {
  IconButton,
  Loader,
  Pagination,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { GridDownload } from "@organisms/grid-download";
import * as ReactTable from "@tanstack/react-table";
import React from "react";
import EmptyFilteredTableSVG from "../../../assets/table/empty_filtered_table.svg";
import EmptyTableSVG from "../../../assets/table/empty_table.svg";
import TagPaginationSelect from "../TagPaginationSelect";
import customFilters from "./customFilters";
import * as SC from "./styles";

type Props<Columns> = {
  /** Columns setup */
  columns: Array<
    ReactTable.TableOptions<Columns>["columns"][number] & {
      isSticky?: true;
      isHidden?: boolean;
    }
  >;
  /** Displayed data */
  data: ReactTable.TableOptions<Columns>["data"];
  /** Messages presented when there are no results available */
  emptyState: {
    emptyText: string;
    filteredEmptyText: string;
    action?: React.ReactNode;
  };
  /** Fetching data */
  loading: boolean;
  /** Array of column filters */
  filter?: ReactTable.ColumnFiltersState;
  /** String used as global filter */
  searchFilter?: string;
  /** Tag filters' component */
  TableFilters?: React.ReactNode;
  /** Text input's component */
  TableSearchFilter?: React.ReactNode;
  /** Enables the table pagination */
  enablePagination?: boolean;
  /** Pagination's page size options */
  pageSizeOptions?: { value: number; label: string }[];
  selectOption?: React.ReactNode;
  downloadOptions?: {
    columns: string[];
    resolver(value: ReactTable.Row<Columns>[]): string[][];
    fileName: string;
  };
};

export default function TableClient<Columns>(props: Props<Columns>) {
  const table = ReactTable.useReactTable({
    columns: props.columns,
    data: props.data,
    state: {
      columnFilters: props.filter,
      globalFilter: props.searchFilter,
    },
    getCoreRowModel: ReactTable.getCoreRowModel(),
    getPaginationRowModel: props.enablePagination
      ? ReactTable.getPaginationRowModel()
      : undefined,
    getFilteredRowModel: ReactTable.getFilteredRowModel(),
    getSortedRowModel: ReactTable.getSortedRowModel(),
    enableFilters: true,
    filterFns: customFilters,
  });

  const tableState = table.getState();
  const tableRows = table.getRowModel().rows;

  const RenderedTable = () => {
    // Loading
    if (props.loading)
      return (
        <SC.EmptyTable>
          <Loader variant="primary" size="medium" />
        </SC.EmptyTable>
      );

    // Empty
    if (!tableRows.length)
      return (
        <SC.EmptyTable>
          {tableState.columnFilters.length || tableState.globalFilter ? (
            <EmptyFilteredTableSVG />
          ) : (
            <EmptyTableSVG />
          )}
          <Typography variant="body3">
            {tableState.columnFilters.length || tableState.globalFilter
              ? props.emptyState.filteredEmptyText
              : props.emptyState.emptyText}
          </Typography>
          {props.emptyState.action}
        </SC.EmptyTable>
      );

    let allHeaderElements: Array<number> = [];
    return (
      <SC.Table>
        <SC.TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers
                .filter((h: any) => !h.column.columnDef["isHidden"])
                .map((header: any, index) => (
                  <SC.Th
                    className={
                      header.column.columnDef["isSticky"] ? "sticky" : ""
                    }
                    sticky={header.column.columnDef["isSticky"]}
                    ref={(element: HTMLTableHeaderCellElement) => {
                      if (element) {
                        const { headers } = headerGroup;
                        if (allHeaderElements.length === 0) {
                          const elements: HTMLTableHeaderCellElement[] =
                            Array.prototype.slice.call(
                              element.parentElement!.children
                            );
                          allHeaderElements = elements.map(
                            ({ clientWidth }) => clientWidth
                          );
                        }

                        if (
                          index <= headers.length / 2 - 1 &&
                          header.column.columnDef["isSticky"]
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= headers.length / 2 + 1 &&
                          header.column.columnDef["isSticky"]
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <SC.HeaderContainer>
                        {ReactTable.flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <IconButton
                            icon={mapSortingIcon(header.column.getIsSorted())}
                            size="small"
                            variant="filled"
                            onClick={header.column.getToggleSortingHandler()}
                          />
                        )}
                      </SC.HeaderContainer>
                    )}
                  </SC.Th>
                ))}
            </tr>
          ))}
        </SC.TableHeader>
        <tbody>
          {tableRows.map((row) => (
            <tr key={row.id}>
              {row
                .getVisibleCells()
                .filter((r: any) => !r.column.columnDef["isHidden"])
                .map((cell: any, index) => (
                  <SC.TableCell
                    className={
                      cell.column.columnDef["isSticky"] ? "sticky" : ""
                    }
                    sticky={cell.column.columnDef["isSticky"]}
                    ref={(element: HTMLTableDataCellElement) => {
                      if (element) {
                        const rowsLength = row.getVisibleCells().length;
                        if (
                          index <= rowsLength / 2 - 1 &&
                          cell.column.columnDef["isSticky"]
                        ) {
                          element.style.left = `${element.offsetLeft}px`;
                        }
                        if (
                          index >= rowsLength / 2 + 1 &&
                          cell.column.columnDef["isSticky"]
                        ) {
                          element.style.right = `${allHeaderElements
                            .slice(index + 1)
                            .reverse()
                            .reduce((offset, width) => offset + width, 0)}px`;
                        }
                      }
                    }}
                    key={cell.id}
                  >
                    {ReactTable.flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </SC.TableCell>
                ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers
                .filter((h: any) => !h.column.columnDef["isHidden"])
                .map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : ReactTable.flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
            </tr>
          ))}
        </tfoot>
      </SC.Table>
    );
  };

  return (
    <SC.Box>
      {!!props.TableSearchFilter && <>{props.TableSearchFilter}</>}
      {!!props.TableFilters && (
        <>
          <SC.TableFilterContainer>
            <SC.Container>
              <SC.FilterText variant="body3">Filtrar por</SC.FilterText>
              {props.TableFilters}
            </SC.Container>
            {props.downloadOptions && !!tableRows.length && (
              <GridDownload
                data={tableRows}
                columns={props.downloadOptions?.columns}
                transformer={props.downloadOptions?.resolver}
                name={props.downloadOptions.fileName}
              />
            )}
          </SC.TableFilterContainer>
        </>
      )}
      <SC.TableContainer>
        {props.selectOption && props.selectOption}
        <RenderedTable />
      </SC.TableContainer>
      {props.enablePagination && (
        <SC.PaginationContainer>
          <TagPaginationSelect
            selectedValue={tableState.pagination.pageSize}
            options={props.pageSizeOptions ?? defaultPaginationOptions}
            onChange={(newValue) => {
              table.setPagination({ pageIndex: 0, pageSize: newValue });
            }}
          />
          <Pagination
            page={tableState.pagination.pageIndex + 1}
            onChange={(_event, page) => {
              table.setPageIndex(page - 1);
            }}
            count={table.getPageCount()}
          />
        </SC.PaginationContainer>
      )}
    </SC.Box>
  );
}

const defaultPaginationOptions = [
  { label: "10 itens", value: 10 },
  { label: "25 itens", value: 25 },
  { label: "50 itens", value: 50 },
  { label: "100 itens", value: 100 },
  { label: "250 itens", value: 250 },
];

function mapSortingIcon(
  sorting: "asc" | "desc" | false
): React.ComponentProps<typeof IconButton>["icon"] {
  if (sorting === "asc") {
    return "IconArrowDown";
  }
  if (sorting === "desc") {
    return "IconArrowUp";
  }
  return "IconArrowsSort";
}
