import { trpc } from "@client";
import {
  Loader,
  SelectField,
  SelectFieldProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import useSearchEmployee from "./hooks/useSearchEmployee";
import { Container } from "./styled";

export const SelectEmployee = (props: SelectFieldProps) => {
  const [selectedEmployee, setSelectedEmployee] =
    useState<{ value: string; label: string; caption?: string }[]>();
  const { loading, options, searchEmployee } = useSearchEmployee();
  const { data } = trpc.employee.getById.useQuery(props.value, {
    enabled: !!props.value && !selectedEmployee,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setSelectedEmployee([
        { value: data.id, label: data.name, caption: data.email || "" },
      ]);
    }
  }, [data]);
  return (
    <Container>
      <SelectField
        {...props}
        searchable
        options={options || selectedEmployee}
        onChange={(e) => searchEmployee(e.target.value)}
        inputMode="text"
      />

      {loading && <Loader size="small" variant="primary" />}
    </Container>
  );
};
