import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => `${theme.spacings.m} ${theme.spacings.xl4}`};
  margin-top: ${({ theme }) => `-${theme.spacings.s}`};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xl4} 0 ${theme.spacings.xl4}`};
`;

export const StyledIcon = styled(ShapeIcon)`
  stroke: ${({ theme }) => theme.palette.warning.main};
  width: ${({ theme }) => theme.icons.sizes.xl};
  height: ${({ theme }) => theme.icons.sizes.xl};
  stroke-width: 1px;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const TypographyWarning = styled(StyledTypography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const SubTitle = styled(StyledTypography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;
