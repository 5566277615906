import styled from "styled-components";
import { PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 25px;
  z-index: 3;
`;

export const StyledPDFViewer = styled(PDFViewer)`
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.secondary[90]};
`;
