import { trpc } from "@client";
import { useBillActionModal } from "@organisms/bill-action-modal/billModalContext";
import { dispatchToast } from "@utils/dispatchToast";
import { useTranslation } from "react-i18next";

export function useRejectBill() {
  const { closeModal } = useBillActionModal();
  const { t } = useTranslation();
  const context = "components.billActionModal.toast.reject";
  const { mutate, isLoading, isSuccess } = trpc.bill.reject.useMutation({
    onSuccess: () => {
      closeModal();
      dispatchToast({
        type: "success",
        content: t(`${context}.success.title`),
      });
    },
    onError: () => {
      dispatchToast({
        type: "error",
        title: t(`${context}.error.title`),
        content: t(`${context}.error.message`),
      });
    },
  });
  return {
    isSuccess,
    isLoading,
    rejectBill: mutate,
  };
}
