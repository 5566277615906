import { Button, LinkButton, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import { TransactionList } from "@organisms/transaction-list";
import { useCardTransaction } from "./hooks/useCardTransaction";
import {
  ButtonContainer,
  ContentContainer,
  ModalContainer,
  StyledDivider,
} from "./styled";
type TransactionModalProps = {
  open: boolean;
  onClose(): void;
  cardId: string;
};
export function TransactionModal({
  open,
  cardId,
  onClose,
}: TransactionModalProps) {
  const { rows, onConfirm, isLoading } = useCardTransaction(cardId);
  return (
    <Modal.Root open={open} onClose={onClose} size="md">
      <ModalContainer>
        <ContentContainer>
          <TransactionList
            itemActions="select"
            rows={rows}
            isLoading={isLoading}
          />
        </ContentContainer>
        <StyledDivider />
        <ButtonContainer>
          <div>
            <LinkButton variant="secondary" onClick={onClose}>
              Cancelar
            </LinkButton>
          </div>
          <Button
            size="medium"
            buttonType="primary"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Selecionar
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal.Root>
  );
}
