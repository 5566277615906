import { trpc } from "@client";
import { dispatchToast } from "@utils/dispatchToast";

export function useReleaseTransaction() {
  const { mutate, isLoading } = trpc.bill.releaseTransaction.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: "success",
        content: "Despesa alterada para corporativo",
      });
    },
    onError: () => {
      dispatchToast({
        type: "success",
        content: "Erro ao alterar despesa",
      });
    },
  });

  return {
    releaseTransaction: mutate,
    isLoading,
  };
}
