import { trpc } from "@client";
import {
  Button,
  IconButton,
  Loader,
  Skeleton,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@mui/material";
import { AlertModal } from "@organisms/AlertModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouterOutputs } from "server/src/router";
import { ConfigurationEditModal } from "./edit-modal";
import { UseCategoryMutation } from "./hooks/useCategoryMutation";
import {
  Box,
  CategoryBox,
  Container,
  ContainerColumn,
  GridBox,
  PaddingBox,
  RowContainer,
} from "./styled";
export type Category =
  RouterOutputs["category"]["getAll"]["categories"][number];

export function CategoryConfiguration() {
  const { t } = useTranslation();
  const { data, isLoading } = trpc.category.getAll.useQuery();
  const {
    toggleCategory,
    deleteCategory,
    toggleAllCategories,
    isLoading: updateIsLoading,
    toggleAllLoading,
  } = UseCategoryMutation();
  const [toggledId, setToggledId] = useState<string>();
  const [modal, setModal] = useState<{
    open: boolean;
    id?: string;
    type?: "disable" | "delete";
  }>({
    open: false,
    id: undefined,
  });
  const [editModal, setEditModal] = useState<{
    open: boolean;
    category?: Category;
  }>({ open: false });

  const handleDisable = () => {
    const category = data?.categories.find((c) => c.id === modal.id);
    if (!category || !modal.id) {
      return;
    }
    toggleCategory({ ...category, active: !category.active }, () =>
      setModal({ open: false })
    );
  };
  const handleDelete = () => {
    if (modal.id) {
      deleteCategory(modal.id, () => setModal({ open: false }));
    }
  };

  const allActive = data?.categories.every((c) => c.active);

  const context = "components.categoryConfiguration";
  return (
    <Box>
      <AlertModal
        open={modal.open}
        onClose={() => setModal({ open: false })}
        confirmLabel={
          modal.type === "disable"
            ? t(`${context}.alertModal.confirm`)
            : t(`${context}.alertModal.deleteConfirm`)
        }
        title={
          modal.type === "disable"
            ? t(`${context}.alertModal.title`)
            : t(`${context}.alertModal.deleteTitle`)
        }
        subtitle={
          modal.type === "disable"
            ? t(`${context}.alertModal.subtitle`)
            : t(`${context}.alertModal.deleteSubtitle`)
        }
        loading={updateIsLoading}
        onConfirm={modal.type === "disable" ? handleDisable : handleDelete}
      />
      <ConfigurationEditModal
        open={editModal.open}
        onClose={() => setEditModal({ open: false })}
        category={editModal.category}
      />
      <PaddingBox>
        <ContainerColumn>
          <Container>
            <ContainerColumn>
              <Typography variant="headline8">
                {t(`${context}.title`)}
              </Typography>
              <Typography variant="body4">
                {t(`${context}.subtitle`)}
              </Typography>
            </ContainerColumn>
          </Container>
        </ContainerColumn>
      </PaddingBox>
      <Divider style={{ width: "100%" }} />
      {isLoading ? (
        <Skeleton width={"100%"} height={"85px"} />
      ) : (
        <GridBox>
          <RowContainer>
            <Container>
              <Button
                variant="primary"
                size="medium"
                onClick={() => {
                  setEditModal({ open: true });
                }}
              >
                + {t(`${context}.add`)}
              </Button>
            </Container>
            <Container style={{ justifyContent: "end" }}>
              <Toggle
                checked={allActive}
                onChange={() => toggleAllCategories(!allActive)}
              />
              <Typography variant="body3">
                {allActive
                  ? t(`${context}.deactivatedAll`)
                  : t(`${context}.activatedAll`)}
              </Typography>
              {toggleAllLoading && <Loader size="small" variant="primary" />}
            </Container>
          </RowContainer>
          {data?.categories.map((category, i) => {
            return (
              <CategoryBox key={i}>
                <Container>
                  <Toggle
                    checked={category.active}
                    onChange={() => {
                      if (!category.active) {
                        setToggledId(category.id);
                        toggleCategory(
                          {
                            ...category,
                            active: !category.active,
                          },
                          () => setToggledId(undefined)
                        );
                        return;
                      }
                      setModal({
                        open: true,
                        id: category.id,
                        type: "disable",
                      });
                    }}
                  />
                  <Typography variant="body3">{category.name}</Typography>
                  {toggledId === category.id && (
                    <Loader size="small" variant="primary" />
                  )}
                </Container>
                <IconButton
                  icon="IconPencil"
                  size="medium"
                  variant="line"
                  onClick={() => setEditModal({ open: true, category })}
                />
                {!category.isSystemDefault && (
                  <IconButton
                    icon="IconTrash"
                    size="medium"
                    variant="line"
                    onClick={() =>
                      setModal({
                        open: true,
                        id: category.id,
                        type: "delete",
                      })
                    }
                  />
                )}
              </CategoryBox>
            );
          })}
        </GridBox>
      )}
    </Box>
  );
}
