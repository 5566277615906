import { Stepper, StepperProps } from "@flash-tecnologia/hros-web-ui-v2";
import {
  IBreadcrumbItem,
  PageBreadcrumb,
} from "../BreadcrumbPage/pageBreadcrumb";
import {
  Body,
  Container,
  Content,
  FlexContainer,
  Header,
  Subtitle,
  Title,
} from "./styled";

type HeaderPageProps = {
  title?: string;
  subTitle?: string;
  breadcrumbItems?: IBreadcrumbItem[];
  action?: React.ReactNode;
};
type Props = {
  children?: React.ReactNode;
  header?: HeaderPageProps;
  hasFooter?: boolean;
  stepProps?: StepperProps;
};

export const BasePage = ({ header, children, hasFooter, stepProps }: Props) => {
  return (
    <Container>
      <Content hasFooter={hasFooter}>
        {(header?.breadcrumbItems || stepProps) && (
          <FlexContainer>
            <PageBreadcrumb items={header?.breadcrumbItems || []} />
            {stepProps && <Stepper {...stepProps} />}
          </FlexContainer>
        )}
        {(header?.title || header?.subTitle) && (
          <Header>
            <Container>
              <Title variant="headline6">{header?.title}</Title>
              <Subtitle variant="body3">{header?.subTitle}</Subtitle>
            </Container>
            <div>{header?.action && header.action}</div>
          </Header>
        )}
        <Body>{children}</Body>
      </Content>
    </Container>
  );
};
