import { SelectField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { type DefaultTheme } from "styled-components";

export const TableFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => p.theme.spacings.xs3};
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => p.theme.spacings.xs3};
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacings.xs};
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(p) => p.theme.spacings.xs3};
`;

export const FilterText = styled(Typography)`
  color: ${(p) => p.theme.colors.neutral[40]};
  width: 100px;
`;

export const TableContainer = styled.div`
  font-family: "Nunito";
  color: ${(p) => p.theme.colors.neutral[40]};
  border: 1px solid ${(p) => p.theme.colors.neutral[90]};
  border-radius: 12px;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const EmptyTable = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Table = styled.table`
  width: 100%;
  .sticky:not(.sticky ~ .sticky) {
    &:after {
      top: 0;
      left: -30px;
      width: 30px;
      height: 100%;
      content: "";
      position: absolute;
      box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
    }
  }
`;

export const TableHeader = styled.thead`
  width: 100%;
`;

type CellProps = {
  sticky?: boolean;
};

export const Th = styled.th<CellProps>`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  padding: 20px;
  position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
  text-align: left;
  white-space: nowrap;
`;

export const TableCell = styled.td<CellProps>`
  background-color: ${(p) => p.theme.colors.neutral[100]};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral[90]};
  padding: 20px;
  position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
  text-align: left;
`;

export const PaginationSelectField = styled(SelectField)`
  z-index: 1 !important;
  width: 150px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiFilledInput-root {
    border-radius: 200px !important;
    border: 1px solid ${(p) => p.theme.colors.neutral[90]} !important;
  }

  .MuiSelect-select {
    padding: 9px 8px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-neutral-dark4);

    &:focus {
      background-color: transparent;
    }
  }
`;

type SpacerProps = {
  x?: keyof DefaultTheme["spacings"];
  y?: keyof DefaultTheme["spacings"];
  useMargin?: boolean;
};

export const Spacer = styled.div<SpacerProps>`
  ${(p) => (!p.useMargin ? "width" : "margin-right")}: ${(p) =>
    p.x ? p.theme.spacings[p.x] : 0};
  ${(p) => (!p.useMargin ? "height" : "margin-top")}: ${(p) =>
    p.y ? p.theme.spacings[p.y] : 0};
`;
