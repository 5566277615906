import styled from "styled-components";

export const RowContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs};
`;

export const DynamicBox = styled.div<{ expend?: boolean }>`
  ${({ expend }) => expend && "  grid-column-start: 1; grid-column-end: 3;"}
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs};
  justify-content: space-between;
  align-items: center;
`;
export const Box = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs2};
`;
