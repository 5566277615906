import {
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBillActionModal } from "./billModalContext";
import { rejectOptions } from "./reject-options";
import { FormContainer } from "./styled";

export function RejectReason() {
  const { t } = useTranslation();
  const { description, setDescription, billActionModal } = useBillActionModal();
  const context = "components.billActionModal";

  const { type } = billActionModal;
  const [reason, setReason] = useState();

  switch (type) {
    case "reject":
      return (
        <FormContainer>
          <Typography variant="body3" weight={700}>
            {t(`${context}.${type}.label`)}
          </Typography>
          <TextField
            style={{ width: "100%" }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            multiline
            rows={4}
            label={t(`${context}.${type}.placeholder`)}
            inputMode="text"
          />
        </FormContainer>
      );
    case "approvalReject":
      return (
        <FormContainer>
          <Typography variant="body3" weight={700}>
            {t(`${context}.${type}.reason`)}
          </Typography>
          <div style={{ width: "100%" }}>
            <SelectField
              value={reason}
              onSelectChange={(_, option) => {
                if (option.value === 6) {
                  setReason(option.value);
                  setDescription("");
                } else {
                  setReason(option.value);
                  setDescription(option.label);
                }
              }}
              label={t(`${context}.${type}.label`)}
              options={rejectOptions}
            />
          </div>
          {reason === 6 && (
            <TextField
              style={{ width: "100%" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              multiline
              rows={4}
              label={t(`${context}.${type}.placeholder`)}
              inputMode="text"
            />
          )}
        </FormContainer>
      );

    default:
      return null;
  }
}
