export const countries = [
  {
    value: "Afeganistão",
    label: "Afeganistão",
    code: "AF",
  },
  {
    value: "África do Sul",
    label: "África do Sul",
    code: "ZA",
  },
  {
    value: "Albânia",
    label: "Albânia",
    code: "AL",
  },
  {
    value: "Alemanha",
    label: "Alemanha",
    code: "DE",
  },
  {
    value: "Andorra",
    label: "Andorra",
    code: "AD",
  },
  {
    value: "Angola",
    label: "Angola",
    code: "AO",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
    code: "AI",
  },
  {
    value: "Antártida",
    label: "Antártida",
    code: "AQ",
  },
  {
    value: "Antígua e Barbuda",
    label: "Antígua e Barbuda",
    code: "AG",
  },
  {
    value: "Antilhas Holandesas",
    label: "Antilhas Holandesas",
    code: "AN",
  },
  {
    value: "Arábia Saudita",
    label: "Arábia Saudita",
    code: "SA",
  },
  {
    value: "Argélia",
    label: "Argélia",
    code: "DZ",
  },
  {
    value: "Argentina",
    label: "Argentina",
    code: "AR",
  },
  {
    value: "Armênia",
    label: "Armênia",
    code: "AM",
  },
  {
    value: "Aruba",
    label: "Aruba",
    code: "AW",
  },
  {
    value: "Austrália",
    label: "Austrália",
    code: "AU",
  },
  {
    value: "Áustria",
    label: "Áustria",
    code: "AT",
  },
  {
    value: "Azerbaijão",
    label: "Azerbaijão",
    code: "AZ  ",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
    code: "BS",
  },
  {
    value: "Bahrein",
    label: "Bahrein",
    code: "BH",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
    code: "BD",
  },
  {
    value: "Barbados",
    label: "Barbados",
    code: "BB",
  },
  {
    value: "Belarus",
    label: "Belarus",
    code: "BY",
  },
  {
    value: "Bélgica",
    label: "Bélgica",
    code: "BE",
  },
  {
    value: "Belize",
    label: "Belize",
    code: "BZ",
  },
  {
    value: "Benin",
    label: "Benin",
    code: "BJ",
  },
  {
    value: "Bermudas",
    label: "Bermudas",
    code: "BM",
  },
  {
    value: "Bolívia",
    label: "Bolívia",
    code: "BO",
  },
  {
    value: "Bósnia-Herzegóvina",
    label: "Bósnia-Herzegóvina",
    code: "BA",
  },
  {
    value: "Botsuana",
    label: "Botsuana",
    code: "BW",
  },
  {
    value: "Brasil",
    label: "Brasil",
    code: "BR",
  },
  {
    value: "Brunei",
    label: "Brunei",
    code: "BN",
  },
  {
    value: "Bulgária",
    label: "Bulgária",
    code: "BG",
  },
  {
    value: "Burkina Fasso",
    label: "Burkina Fasso",
    code: "BF",
  },
  {
    value: "Burundi",
    label: "Burundi",
    code: "BI",
  },
  {
    value: "Butão",
    label: "Butão",
    code: "BT",
  },
  {
    value: "Cabo Verde",
    label: "Cabo Verde",
    code: "CV",
  },
  {
    value: "Camarões",
    label: "Camarões",
    code: "CM",
  },
  {
    value: "Camboja",
    label: "Camboja",
    code: "KH",
  },
  {
    value: "Canadá",
    label: "Canadá",
    code: "CA",
  },
  {
    value: "Cazaquistão",
    label: "Cazaquistão",
    code: "KZ",
  },
  {
    value: "Chade",
    label: "Chade",
    code: "TD",
  },
  {
    value: "Chile",
    label: "Chile",
    code: "CL",
  },
  {
    value: "China",
    label: "China",
    code: "CN",
  },
  {
    value: "Chipre",
    label: "Chipre",
    code: "CY",
  },
  {
    value: "Cingapura",
    label: "Cingapura",
    code: "SG",
  },
  {
    value: "Colômbia",
    label: "Colômbia",
    code: "CO",
  },
  {
    value: "Congo",
    label: "Congo",
    code: "CG",
  },
  {
    value: "Coréia do Norte",
    label: "Coréia do Norte",
    code: "KP",
  },
  {
    value: "Coréia do Sul",
    label: "Coréia do Sul",
    code: "KR",
  },
  {
    value: "Costa do Marfim",
    label: "Costa do Marfim",
    code: "CI",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
    code: "CR",
  },
  {
    value: "Croácia (Hrvatska)",
    label: "Croácia (Hrvatska)",
    code: "HR",
  },
  {
    value: "Cuba",
    label: "Cuba",
    code: "CU",
  },
  {
    value: "Dinamarca",
    label: "Dinamarca",
    code: "DK",
  },
  {
    value: "Djibuti",
    label: "Djibuti",
    code: "DJ",
  },
  {
    value: "Dominica",
    label: "Dominica",
    code: "DM",
  },
  {
    value: "Egito",
    label: "Egito",
    code: "EG",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
    code: "SV",
  },
  {
    value: "Emirados Árabes Unidos",
    label: "Emirados Árabes Unidos",
    code: "AE",
  },
  {
    value: "Equador",
    label: "Equador",
    code: "EC",
  },
  {
    value: "Eritréia",
    label: "Eritréia",
    code: "ER",
  },
  {
    value: "Eslováquia",
    label: "Eslováquia",
    code: "SK",
  },
  {
    value: "Eslovênia",
    label: "Eslovênia",
    code: "SI",
  },
  {
    value: "Espanha",
    label: "Espanha",
    code: "ES",
  },
  {
    value: "Estados Unidos",
    label: "Estados Unidos",
    code: "US",
  },
  {
    value: "Estônia",
    label: "Estônia",
    code: "EE",
  },
  {
    value: "Etiópia",
    label: "Etiópia",
    code: "ET",
  },
  {
    value: "Fiji",
    label: "Fiji",
    code: "FJ",
  },
  {
    value: "Filipinas",
    label: "Filipinas",
    code: "PH",
  },
  {
    value: "Finlândia",
    label: "Finlândia",
    code: "FI",
  },
  {
    value: "França",
    label: "França",
    code: "FR",
  },
  {
    value: "Gabão",
    label: "Gabão",
    code: "GA",
  },
  {
    value: "Gâmbia",
    label: "Gâmbia",
    code: "GM",
  },
  {
    value: "Gana",
    label: "Gana",
    code: "GH",
  },
  {
    value: "Geórgia",
    label: "Geórgia",
    code: "GE",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
    code: "GI",
  },
  {
    value: "Grã-Bretanha (Reino Unido, UK)",
    label: "Grã-Bretanha (Reino Unido, UK)",
    code: "GB",
  },
  {
    value: "Granada",
    label: "Granada",
    code: "GD",
  },
  {
    value: "Grécia",
    label: "Grécia",
    code: "GR",
  },
  {
    value: "Groelândia",
    label: "Groelândia",
    code: "GL",
  },
  {
    value: "Guadalupe",
    label: "Guadalupe",
    code: "GP",
  },
  {
    value: "Guam (Território dos Estados Unidos)",
    label: "Guam (Território dos Estados Unidos)",
    code: "GU",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
    code: "GT",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
    code: "G",
  },
  {
    value: "Guiana",
    label: "Guiana",
    code: "GY",
  },
  {
    value: "Guiana Francesa",
    label: "Guiana Francesa",
    code: "GF",
  },
  {
    value: "Guiné",
    label: "Guiné",
    code: "GN",
  },
  {
    value: "Guiné Equatorial",
    label: "Guiné Equatorial",
    code: "GQ",
  },
  {
    value: "Guiné-Bissau",
    label: "Guiné-Bissau",
    code: "GW",
  },
  {
    value: "Haiti",
    label: "Haiti",
    code: "HT",
  },
  {
    value: "Holanda",
    label: "Holanda",
    code: "NL",
  },
  {
    value: "Honduras",
    label: "Honduras",
    code: "HN",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
    code: "HK",
  },
  {
    value: "Hungria",
    label: "Hungria",
    code: "HU",
  },
  {
    value: "Iêmen",
    label: "Iêmen",
    code: "YE",
  },
  {
    value: "Ilha Bouvet (Território da Noruega)",
    label: "Ilha Bouvet (Território da Noruega)",
    code: "BV",
  },
  {
    value: "Ilha do Homem",
    label: "Ilha do Homem",
    code: "IM",
  },
  {
    value: "Ilha Natal",
    label: "Ilha Natal",
    code: "CX",
  },
  {
    value: "Ilha Pitcairn",
    label: "Ilha Pitcairn",
    code: "PN",
  },
  {
    value: "Ilha Reunião",
    label: "Ilha Reunião",
    code: "RE",
  },
  {
    value: "Ilhas Aland",
    label: "Ilhas Aland",
    code: "AX",
  },
  {
    value: "Ilhas Cayman",
    label: "Ilhas Cayman",
    code: "KY",
  },
  {
    value: "Ilhas Cocos",
    label: "Ilhas Cocos",
    code: "CC",
  },
  {
    value: "Ilhas Comores",
    label: "Ilhas Comores",
    code: "KM",
  },
  {
    value: "Ilhas Cook",
    label: "Ilhas Cook",
    code: "CK",
  },
  {
    value: "Ilhas Faroes",
    label: "Ilhas Faroes",
    code: "FO",
  },
  {
    value: "Ilhas Falkland (Malvinas)",
    label: "Ilhas Falkland (Malvinas)",
    code: "FK",
  },
  {
    value: "Ilhas Geórgia do Sul e Sandwich do Sul",
    label: "Ilhas Geórgia do Sul e Sandwich do Sul",
    code: "GS",
  },
  {
    value: "Ilhas Heard e McDonald (Território da Austrália)",
    label: "Ilhas Heard e McDonald (Território da Austrália)",
    code: "HM",
  },
  {
    value: "Ilhas Marianas do Norte",
    label: "Ilhas Marianas do Norte",
    code: "MP",
  },
  {
    value: "Ilhas Marshall",
    label: "Ilhas Marshall",
    code: "MH",
  },
  {
    value: "Ilhas Menores dos Estados Unidos",
    label: "Ilhas Menores dos Estados Unidos",
    code: "UM",
  },
  {
    value: "Ilhas Norfolk",
    label: "Ilhas Norfolk",
    code: "NF",
  },
  {
    value: "Ilhas Seychelles",
    label: "Ilhas Seychelles",
    code: "SC",
  },
  {
    value: "Ilhas Solomão",
    label: "Ilhas Solomão",
    code: "SB",
  },
  {
    value: "Ilhas Svalbard e Jan Mayen",
    label: "Ilhas Svalbard e Jan Mayen",
    code: "SJ",
  },
  {
    value: "Ilhas Tokelau",
    label: "Ilhas Tokelau",
    code: "TK",
  },
  {
    value: "Ilhas Turks e Caicos",
    label: "Ilhas Turks e Caicos",
    code: "TC",
  },
  {
    value: "Ilhas Virgens (Estados Unidos)",
    label: "Ilhas Virgens (Estados Unidos)",
    code: "VI",
  },
  {
    value: "Ilhas Virgens (Inglaterra)",
    label: "Ilhas Virgens (Inglaterra)",
    code: "VG",
  },
  {
    value: "Ilhas Wallis e Futuna",
    label: "Ilhas Wallis e Futuna",
    code: "WF",
  },
  {
    value: "índia",
    label: "índia",
    code: "IN",
  },
  {
    value: "Indonésia",
    label: "Indonésia",
    code: "ID",
  },
  {
    value: "Irã",
    label: "Irã",
    code: "IR",
  },
  {
    value: "Iraque",
    label: "Iraque",
    code: "IQ",
  },
  {
    value: "Irlanda",
    label: "Irlanda",
    code: "IE",
  },
  {
    value: "Islândia",
    label: "Islândia",
    code: "IS",
  },
  {
    value: "Israel",
    label: "Israel",
    code: "IL",
  },
  {
    value: "Itália",
    label: "Itália",
    code: "IT",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
    code: "JM",
  },
  {
    value: "Japão",
    label: "Japão",
    code: "JP",
  },
  {
    value: "Jersey",
    label: "Jersey",
    code: "JE",
  },
  {
    value: "Jordânia",
    label: "Jordânia",
    code: "JO",
  },
  {
    value: "Kênia",
    label: "Kênia",
    code: "KE",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
    code: "KI",
  },
  {
    value: "Kuait",
    label: "Kuait",
    code: "KW",
  },
  {
    value: "Laos",
    label: "Laos",
    code: "LA",
  },
  {
    value: "Látvia",
    label: "Látvia",
    code: "LV",
  },
  {
    value: "Lesoto",
    label: "Lesoto",
    code: "LS",
  },
  {
    value: "Líbano",
    label: "Líbano",
    code: "LB",
  },
  {
    value: "Libéria",
    label: "Libéria",
    code: "LR",
  },
  {
    value: "Líbia",
    label: "Líbia",
    code: "LY",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
    code: "LI",
  },
  {
    value: "Lituânia",
    label: "Lituânia",
    code: "LT",
  },
  {
    value: "Luxemburgo",
    label: "Luxemburgo",
    code: "LU",
  },
  {
    value: "Macau",
    label: "Macau",
    code: "MO",
  },
  {
    value: "Macedônia (República Yugoslava)",
    label: "Macedônia (República Yugoslava)",
    code: "MK",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
    code: "MG",
  },
  {
    value: "Malásia",
    label: "Malásia",
    code: "MY",
  },
  {
    value: "Malaui",
    label: "Malaui",
    code: "MW",
  },
  {
    value: "Maldivas",
    label: "Maldivas",
    code: "MV",
  },
  {
    value: "Mali",
    label: "Mali",
    code: "ML",
  },
  {
    value: "Malta",
    label: "Malta",
    code: "MT",
  },
  {
    value: "Marrocos",
    label: "Marrocos",
    code: "MA",
  },
  {
    value: "Martinica",
    label: "Martinica",
    code: "MQ",
  },
  {
    value: "Maurício",
    label: "Maurício",
    code: "MU",
  },
  {
    value: "Mauritânia",
    label: "Mauritânia",
    code: "MR",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
    code: "YT",
  },
  {
    value: "México",
    label: "México",
    code: "MX",
  },
  {
    value: "Micronésia",
    label: "Micronésia",
    code: "FM",
  },
  {
    value: "Moçambique",
    label: "Moçambique",
    code: "MZ",
  },
  {
    value: "Moldova",
    label: "Moldova",
    code: "MD",
  },
  {
    value: "Mônaco",
    label: "Mônaco",
    code: "MC",
  },
  {
    value: "Mongólia",
    label: "Mongólia",
    code: "MN",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
    code: "ME",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
    code: "MS",
  },
  {
    value: "Myanma",
    label: "Myanma",
    code: "MM",
  },
  {
    value: "Namíbia",
    label: "Namíbia",
    code: "NA",
  },
  {
    value: "Nauru",
    label: "Nauru",
    code: "NR",
  },
  {
    value: "Nepal",
    label: "Nepal",
    code: "NP",
  },
  {
    value: "Nicarágua",
    label: "Nicarágua",
    code: "NI",
  },
  {
    value: "Níger",
    label: "Níger",
    code: "NE",
  },
  {
    value: "Nigéria",
    label: "Nigéria",
    code: "NG",
  },
  {
    value: "Niue",
    label: "Niue",
    code: "NU",
  },
  {
    value: "Noruega",
    label: "Noruega",
    code: "NO",
  },
  {
    value: "Nova Caledônia",
    label: "Nova Caledônia",
    code: "NC",
  },
  {
    value: "Nova Zelândia",
    label: "Nova Zelândia",
    code: "NZ",
  },
  {
    value: "Omã",
    label: "Omã",
    code: "OM",
  },
  {
    value: "Palau",
    label: "Palau",
    code: "PW",
  },
  {
    value: "Panamá",
    label: "Panamá",
    code: "PA",
  },
  {
    value: "Papua-Nova Guiné",
    label: "Papua-Nova Guiné",
    code: "PG",
  },
  {
    value: "Paquistão",
    label: "Paquistão",
    code: "PK",
  },
  {
    value: "Paraguai",
    label: "Paraguai",
    code: "PY",
  },
  {
    value: "Peru",
    label: "Peru",
    code: "PE",
  },
  {
    value: "Polinésia Francesa",
    label: "Polinésia Francesa",
    code: "PF",
  },
  {
    value: "Polônia",
    label: "Polônia",
    code: "PL",
  },
  {
    value: "Porto Rico",
    label: "Porto Rico",
    code: "PR",
  },
  {
    value: "Portugal",
    label: "Portugal",
    code: "PT",
  },
  {
    value: "Qatar",
    label: "Qatar",
    code: "QA",
  },
  {
    value: "Quirguistão",
    label: "Quirguistão",
    code: "KG",
  },
  {
    value: "República Centro-Africana",
    label: "República Centro-Africana",
    code: "CF",
  },
  {
    value: "República Democrática do Congo",
    label: "República Democrática do Congo",
    code: "CD",
  },
  {
    value: "República Dominicana",
    label: "República Dominicana",
    code: "DO",
  },
  {
    value: "República Tcheca",
    label: "República Tcheca",
    code: "CZ",
  },
  {
    value: "Romênia",
    label: "Romênia",
    code: "RO",
  },
  {
    value: "Ruanda",
    label: "Ruanda",
    code: "RW",
  },
  {
    value: "Rússia (antiga URSS) - Federação Russa",
    label: "Rússia (antiga URSS) - Federação Russa",
    code: "RU",
  },
  {
    value: "Saara Ocidental",
    label: "Saara Ocidental",
    code: "EH",
  },
  {
    value: "Saint Vincente e Granadinas",
    label: "Saint Vincente e Granadinas",
    code: "VC",
  },
  {
    value: "Samoa Americana",
    label: "Samoa Americana",
    code: "AS",
  },
  {
    value: "Samoa Ocidental",
    label: "Samoa Ocidental",
    code: "WS",
  },
  {
    value: "San Marino",
    label: "San Marino",
    code: "SM",
  },
  {
    value: "Santa Helena",
    label: "Santa Helena",
    code: "SH",
  },
  {
    value: "Santa Lúcia",
    label: "Santa Lúcia",
    code: "LC",
  },
  {
    value: "São Bartolomeu",
    label: "São Bartolomeu",
    code: "BL",
  },
  {
    value: "São Cristóvão e Névis",
    label: "São Cristóvão e Névis",
    code: "KN",
  },
  {
    value: "São Martim",
    label: "São Martim",
    code: "MF",
  },
  {
    value: "São Tomé e Príncipe",
    label: "São Tomé e Príncipe",
    code: "ST",
  },
  {
    value: "Senegal",
    label: "Senegal",
    code: "SN",
  },
  {
    value: "Serra Leoa",
    label: "Serra Leoa",
    code: "SL",
  },
  {
    value: "Sérvia",
    label: "Sérvia",
    code: "RS",
  },
  {
    value: "Síria",
    label: "Síria",
    code: "SY",
  },
  {
    value: "Somália",
    label: "Somália",
    code: "SO",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
    code: "LK",
  },
  {
    value: "St. Pierre and Miquelon",
    label: "St. Pierre and Miquelon",
    code: "PM",
  },
  {
    value: "Suazilândia",
    label: "Suazilândia",
    code: "SZ",
  },
  {
    value: "Sudão",
    label: "Sudão",
    code: "SD",
  },
  {
    value: "Suécia",
    label: "Suécia",
    code: "SE",
  },
  {
    value: "Suíça",
    label: "Suíça",
    code: "CH",
  },
  {
    value: "Suriname",
    label: "Suriname",
    code: "SR",
  },
  {
    value: "Tadjiquistão",
    label: "Tadjiquistão",
    code: "TJ",
  },
  {
    value: "Tailândia",
    label: "Tailândia",
    code: "TH",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
    code: "TW",
  },
  {
    value: "Tanzânia",
    label: "Tanzânia",
    code: "TZ",
  },
  {
    value: "Território Britânico do Oceano índico",
    label: "Território Britânico do Oceano índico",
    code: "IO",
  },
  {
    value: "Territórios do Sul da França",
    label: "Territórios do Sul da França",
    code: "TF",
  },
  {
    value: "Territórios Palestinos Ocupados",
    label: "Territórios Palestinos Ocupados",
    code: "PS",
  },
  {
    value: "Timor Leste",
    label: "Timor Leste",
    code: "TP",
  },
  {
    value: "Togo",
    label: "Togo",
    code: "TG",
  },
  {
    value: "Tonga",
    label: "Tonga",
    code: "TO",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    code: "TT",
  },
  {
    value: "Tunísia",
    label: "Tunísia",
    code: "TN",
  },
  {
    value: "Turcomenistão",
    label: "Turcomenistão",
    code: "TM",
  },
  {
    value: "Turquia",
    label: "Turquia",
    code: "TR",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
    code: "TV",
  },
  {
    value: "Ucrânia",
    label: "Ucrânia",
    code: "UA",
  },
  {
    value: "Uganda",
    label: "Uganda",
    code: "UG",
  },
  {
    value: "Uruguai",
    label: "Uruguai",
    code: "UY",
  },
  {
    value: "Uzbequistão",
    label: "Uzbequistão",
    code: "UZ",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
    code: "VU",
  },
  {
    value: "Vaticano",
    label: "Vaticano",
    code: "VA",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
    code: "VE",
  },
  {
    value: "Vietnã",
    label: "Vietnã",
    code: "VN",
  },
  {
    value: "Zâmbia",
    label: "Zâmbia",
    code: "ZM",
  },
  {
    value: "Zimbábue",
    label: "Zimbábue",
    code: "ZW",
  },
];
