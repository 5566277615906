import { trpc } from "@client";

export default function useGetEmployee() {
  const { data, isFetching } = trpc.employee.get.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const options = data?.map((_) => ({
    value: _.id,
    label: _.name,
    caption: _.email || "",
  }));

  return {
    loading: isFetching,
    options,
  };
}
