import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { RowContainer, StyledIcon, StyledTypography } from "./styled";

type PriorityListProps = {
  approvalFlow: {
    priority: string;
    id: string;
    name: string;
    description: string;
  }[];
};
export function PriorityList({ approvalFlow }: PriorityListProps) {
  return (
    <div>
      <Droppable droppableId={"priority-grid"}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {approvalFlow.map((app, index) => {
              return (
                <Draggable key={app.id} draggableId={app.id} index={index}>
                  {(provided) => (
                    <RowContainer
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <Typography variant="body3" weight={600}>
                        {app.priority}
                      </Typography>
                      <StyledIcon name="IconGripVertical" />
                      <Typography variant="body3" weight={600}>
                        {app.name}
                      </Typography>
                      <Typography variant="body3" weight={600}>
                        {app.description && "|"}
                      </Typography>
                      <StyledTypography variant="body4">
                        {app.description}
                      </StyledTypography>
                    </RowContainer>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
