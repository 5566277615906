import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacings.xs2};
`;

export const ColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const RejectContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacings.xs2};
  border: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.error70};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs1};
  margin-bottom: ${({ theme }) => theme.spacings.xs1};
  min-width: 800px;
`;

export const BaseGrid = styled.div<{
  gridTemplate: string;
}>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const HeaderContainer = styled(Container)`
  justify-content: space-between;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const TabContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const StyledTypography = styled(Typography)`
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  border-bottom: ${({ theme }) => theme.borders.width.xs} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
`;
