import { TextFieldProps } from "@flash-tecnologia/hros-web-ui-v2";
import { StyledTextField } from "@molecules/text-field/text-field";

export const PhoneField = (props: TextFieldProps) => {
  return (
    <StyledTextField
      {...props}
      inputMode="text"
      imaskProps={{
        mask: [{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }],
      }}
    />
  );
};
