import { trpc } from "@client";
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { BasePage } from "@organisms/BasePage/BasePage";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { ApprovalFlowForm } from "../../containers/approval-flow/approval-flow-form";

const PageApprovalFlowForm = () => {
  const { t } = useTranslation();
  const pageContext = "pages.approvalFlowForm";
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const location = useLocation();
  const { data, isRefetching, isLoading } =
    trpc.approvalFlow.getApprovalFlowsById.getById.useQuery(id || "", {
      enabled: !!id,
    });
  const state = useMemo(() => {
    return location?.state as { isCopy?: boolean };
  }, [id]);

  return (
    <BasePage
      header={{
        title: t(`${pageContext}.${id ? "titleEdit" : "titleAdd"}`),
        breadcrumbItems: [
          {
            label: t(`${pageContext}.breadCrumbs.customization`),
            to: "/expenseManagement/customizations",
          },
          {
            label: t(`${pageContext}.breadCrumbs.addApprovalFlow`),
          },
        ],
      }}
      stepProps={{
        activeStep: step,
        setActiveStep: setStep,
        steps: [
          t(`${pageContext}.steps.one`),
          t(`${pageContext}.steps.two`),
          t(`${pageContext}.steps.three`),
        ],
      }}
      hasFooter
    >
      {(isRefetching || isLoading) && id ? (
        <Skeleton width={"100%"} height={"70px"} />
      ) : (
        <ApprovalFlowForm
          step={step}
          setStep={setStep}
          approvalFlow={data}
          isCopy={state?.isCopy}
        />
      )}
    </BasePage>
  );
};

export default PageApprovalFlowForm;
