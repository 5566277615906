import { accountTypes } from "@common/account-type";
import { banks } from "@common/banks";
import { lineOfBusiness } from "@common/line-of-business";
import { paymentMethod } from "@common/payment-methods";
import { pixKeyOptions } from "@common/pix-key-options";
import { FieldProps } from "@molecules/Data-display";
import {
  formatDocumentNumber,
  formatPhone,
  formatPostalCode,
} from "@utils/maskData";
import { useTranslation } from "react-i18next";
import {
  PaymentMethod,
  Vendor,
} from "server/src/services/company-payables-service/company-payables.types";

type PaymentMethodFieldProps = {
  fields?: FieldProps[];
  favorite?: boolean;
  type?: string | number;
};

export type VendorFields = {
  baseData: FieldProps[];
  contactData: FieldProps[];
  EmployeeData: FieldProps[];
  addressData: FieldProps[];
  paymentData: PaymentMethodFieldProps[];
};
export function UseVendorFields(vendor?: Vendor): VendorFields | null {
  const { t } = useTranslation();
  const context = "components.vendorDetails.detailsTab.fields";
  if (!vendor) return null;

  const getFieldValue = (value: string | undefined | number) => {
    return value || "-";
  };

  const getFormattedPhoneValue = (value: string | undefined) => {
    return value ? formatPhone(value) : "-";
  };

  const baseData: FieldProps[] = [
    {
      label: t(`${context}.status`),
      value: vendor.active ? "Ativo" : "Inativo",
      tagProps: {
        variant: vendor.active ? "success" : "error",
      },
    },
    {
      label: t(`${context}.code`),
      value: getFieldValue(vendor.code),
      visible: !!vendor.code,
    },
    {
      label: t(`${context}.registrationNumber`),
      value: formatDocumentNumber(vendor.registrationNumber),
    },
    {
      label: t(`${context}.legalName`),
      value: vendor.legalName,
    },
    {
      label: t(`${context}.name`),
      value: getFieldValue(vendor.name),
      visible: !!vendor.name,
    },
    {
      label: t(`${context}.citySubscription`),
      value: getFieldValue(vendor.citySubscription),
      visible: !!vendor.citySubscription,
    },
    {
      label: t(`${context}.stateSubscription`),
      value: getFieldValue(vendor.stateSubscription),
      visible: !!vendor.stateSubscription,
    },
    {
      label: t(`${context}.lineOfBusiness`),
      value: getFieldValue(
        lineOfBusiness.find((_) => vendor.lineOfBusiness === _.value)?.label
      ),
    },
    {
      label: t(`${context}.email`),
      value: getFieldValue(vendor.email),
      visible: !!vendor.email,
    },
    {
      label: t(`${context}.phone`),
      value: getFormattedPhoneValue(vendor.phone),
      visible: !!vendor.phone,
    },
  ];

  const contactData: FieldProps[] = [
    {
      label: t(`${context}.contactName`),
      value: getFieldValue(vendor.contactData?.name),
      expand: true,
      visible: !!vendor.contactData?.name,
    },
    {
      label: t(`${context}.email`),
      value: getFieldValue(vendor.contactData?.email),
      visible: !!vendor.contactData?.email,
    },
    {
      label: t(`${context}.phone`),
      value: getFormattedPhoneValue(vendor.contactData?.phone),
      visible: !!vendor.contactData?.phone,
    },
  ];

  const EmployeeData: FieldProps[] = [
    {
      label: t(`${context}.contactName`),
      value: getFieldValue(vendor.responsibleEmployee?.name),
      expand: true,
      visible: !!vendor.responsibleEmployee?.name,
    },
    {
      label: t(`${context}.email`),
      value: getFieldValue(vendor.responsibleEmployee?.email),
      visible: !!vendor.responsibleEmployee?.email,
    },
    {
      label: t(`${context}.phone`),
      value: getFormattedPhoneValue(vendor.responsibleEmployee?.phone),
      visible: !!vendor.responsibleEmployee?.phone,
    },
  ];
  const addressData: FieldProps[] = [
    {
      label: t(`${context}.country`),
      value: vendor.address.country,
    },
    {
      label: t(`${context}.postalCode`),
      value: formatPostalCode(vendor.address.postalCode),
    },
    {
      label: t(`${context}.street`),
      value: vendor.address.street,
      expand: true,
    },
    {
      label: t(`${context}.number`),
      value: `${vendor.address.number || "S/N"}`,
    },
    {
      label: t(`${context}.complement`),
      value: getFieldValue(vendor.address.complement),
      visible: !!vendor.address.complement,
    },
  ];

  const paymentData = vendor.bankData?.map((data) => {
    switch (data.paymentMethod) {
      case PaymentMethod.BILLET:
        return {
          type: getFieldValue(
            paymentMethod.find((_) => data?.paymentMethod === _.value)?.label
          ),
          favorite: data.favorite,
          fields: [
            {
              label: t(`${context}.paymentType`),
              value: getFieldValue(
                paymentMethod.find((_) => data?.paymentMethod === _.value)
                  ?.label
              ),
            },
          ],
        };
      case PaymentMethod.CARD:
        return {
          type: getFieldValue(
            paymentMethod.find((_) => data?.paymentMethod === _.value)?.label
          ),
          favorite: data.favorite,
          fields: [
            {
              label: t(`${context}.paymentType`),
              value: getFieldValue(
                paymentMethod.find((_) => data?.paymentMethod === _.value)
                  ?.label
              ),
            },
            {
              label: t(`${context}.number`),
              value: `${data.cardId}`,
            },
          ],
        };
      case PaymentMethod.TRANSFER:
        return {
          type: getFieldValue(
            paymentMethod.find((_) => data?.paymentMethod === _.value)?.label
          ),
          favorite: data.favorite,
          fields: [
            {
              label: t(`${context}.paymentType`),
              value: getFieldValue(
                paymentMethod.find((_) => data?.paymentMethod === _.value)
                  ?.label
              ),
            },
            {
              label: t(`${context}.bank`),
              value: `${data.bank} - ${getFieldValue(
                banks.find((b) => `${b.value}` === data.bank)?.label
              )}`,
            },
            {
              label: t(`${context}.accountType`),
              value: getFieldValue(
                accountTypes.find((b) => b.value === data.accountType)?.label
              ),
            },
            {
              label: t(`${context}.agency`),
              value: getFieldValue(data.agency),
            },
            {
              label: t(`${context}.account`),
              value: getFieldValue(data.account),
            },
          ],
        };
      case PaymentMethod.PIX:
        return {
          type: getFieldValue(
            paymentMethod.find((_) => data?.paymentMethod === _.value)?.label
          ),
          favorite: data.favorite,
          fields: [
            {
              label: t(`${context}.paymentType`),
              value: getFieldValue(
                paymentMethod.find((_) => data?.paymentMethod === _.value)
                  ?.label
              ),
            },
            {
              label: t(`${context}.pixType`),
              value:
                pixKeyOptions.find((option) => option.value === data.pixType)
                  ?.label || "",
            },
            {
              label:
                pixKeyOptions.find((option) => option.value === data.pixType)
                  ?.label || "",
              value: getFieldValue(data.pixKey),
            },
          ],
        };

      default:
        return {};
    }
  });

  return {
    baseData: baseData.filter((_) => _.visible !== false),
    contactData: contactData.filter((_) => _.visible !== false),
    EmployeeData: EmployeeData.filter((_) => _.visible !== false),
    addressData: addressData.filter((_) => _.visible !== false),
    paymentData: paymentData || [],
  };
}
