import { PayablesPermissions } from "@common/permissions.type";
import { usePermissions, useSelectedCompany } from "@flash-hros/utility";
export function usePlatformPermissions() {
  const permissionsData = usePermissions();
  const dataCompany = useSelectedCompany();
  const currencyCompanyPermissions = permissionsData?.companies?.find(
    (company: any) =>
      company?.registrationNumber ===
      dataCompany?.selectedCompany?.registrationNumber
  );
  const permissions: string[] = currencyCompanyPermissions?.permissions;

  function checkPermission(permission: PayablesPermissions): boolean {
    if (permissions?.includes("*")) {
      return true;
    }
    return permissions?.some(
      (currentPermission: string) => currentPermission === permission
    );
  }

  return {
    selectedCompany: currencyCompanyPermissions,
    permissions,
    checkPermission,
  };
}
