import {
  TagDateFilter,
  TagFilter,
  TagSliderRangeFilter,
} from "@flash-tecnologia/hros-web-ui-v2";
import useSelectCategory from "@organisms/category-select/hooks/useSelectCategory";
import useSearchVendor from "@organisms/select-vendor/hooks/useSearchVendor";
import { toCurrency } from "@utils/toCurrency";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type BillFilters = {
  vendorId?: string[];
  status?: string[];
  categoryId?: string[];
  amount?: { min: number; max: number };
  dueDate?: { from?: Date; to?: Date };
};

type AmountRange = {
  min: number;
  max: number;
};
export function useFilters(
  amountRange: AmountRange,
  statusFilter?: { paidFilter: boolean; pendingFilter: boolean }
) {
  const [filters, setFilters] = useState<BillFilters | undefined>({});
  const { loading, options } = useSearchVendor(true);
  const { t } = useTranslation();
  const { loading: categoryIsLoading, options: categories } =
    useSelectCategory();

  useEffect(() => {
    if (statusFilter?.paidFilter)
      setFilters({ ...filters, status: ["APPROVED"] });
    if (statusFilter?.pendingFilter)
      setFilters({ ...filters, status: ["PENDING"] });

    if (!statusFilter?.paidFilter && !statusFilter?.pendingFilter) {
      const newFilter = { ...filters };
      delete newFilter?.status;
      setFilters(newFilter);
    }
  }, [statusFilter?.paidFilter, statusFilter?.pendingFilter]);

  const context = "components.billGridFilters";
  const filtersComponents = (
    <>
      <TagFilter
        hasLeftIcon={false}
        loading={loading}
        onApply={(values: string[]) => {
          setFilters({ ...filters, vendorId: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.vendorId;
          setFilters(newFilters);
        }}
        options={options}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.vendor`)}
      />
      <TagFilter
        disabled={statusFilter?.paidFilter || statusFilter?.pendingFilter}
        hasLeftIcon={false}
        onApply={(values: string[]) => {
          setFilters({ ...filters, status: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.status;
          setFilters(newFilters);
        }}
        options={[
          { label: t(`${context}.status.open`), value: "OPEN" },
          { label: t(`${context}.status.pending`), value: "PENDING" },
          { label: t(`${context}.status.approved`), value: "APPROVED" },
          { label: t(`${context}.status.rejected`), value: "REJECTED" },
        ]}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.status`)}
      />
      <TagFilter
        hasLeftIcon={false}
        onApply={(values: string[]) => {
          setFilters({ ...filters, categoryId: values });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.categoryId;
          setFilters(newFilters);
        }}
        loading={categoryIsLoading}
        options={categories}
        optionIconType={"checkbox"}
        variant={"secondary"}
        filterLabel={t(`${context}.labels.category`)}
      />
      <TagSliderRangeFilter
        variant="tertiary"
        filterLabel={t(`${context}.labels.amount`)}
        max={amountRange.max}
        min={amountRange.min}
        step={100}
        fieldMask={toCurrency}
        onApply={(values: number[]) => {
          const [min, max] = values;
          setFilters({ ...filters, amount: { min, max } });
        }}
        onClear={() => {
          const newFilters = { ...filters };
          delete newFilters?.amount;
          setFilters(newFilters);
        }}
      />
      <TagDateFilter
        variant="tertiary"
        filterLabel={t(`${context}.labels.dueDate`)}
        hasLeftIcon={false}
        options={[]}
        onSubmit={(dates) => {
          const { from, to } = dates;
          setFilters({ ...filters, dueDate: { from, to } });
        }}
      />
    </>
  );

  const formattedFilter =
    filters &&
    Object.entries(filters).map((entry) => ({
      id: entry[0],
      value: entry[1],
    }));

  return {
    formattedFilter,
    filtersComponents,
  };
}
