import { trpc } from "@client";
import { Skeleton, Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Tag } from "@molecules/tag";
import { GridActions } from "@organisms/grid-actions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ApprovalFlowActionModal } from "../approval-flow-action-modal/approvalActionModal";
import { useApprovalFlowActions } from "../approval-flow-actions/hooks/useApprovalFlowActions";
import { ApprovalFlowDetailsTab } from "./detail-tab";
import { Container, HeaderContainer } from "./styled";

export function ApprovalFlowDetails() {
  const { id } = useParams();
  const { data, isLoading } =
    trpc.approvalFlow.getApprovalFlowsById.getById.useQuery(id || "");
  const { buildActions } = useApprovalFlowActions();
  const { t } = useTranslation();
  const context = "pages.approvalFlowDetails";

  if (isLoading) {
    return <Skeleton width={"100%"} height={"70px"} />;
  }
  return (
    <>
      <ApprovalFlowActionModal />
      <HeaderContainer>
        <Container>
          <Container style={{ width: "100%" }}>
            <div>
              <Typography variant="headline6">{data?.name}</Typography>
            </div>
            <div>
              <Tag variant={data?.status === "ACTIVE" ? "green" : "error"}>
                {data?.status === "ACTIVE" ? "Ativo" : "Inativo"}
              </Tag>
            </div>
          </Container>
        </Container>
        <Container>
          {data && (
            <GridActions
              actions={buildActions(data?.id, data.status, "details")}
            />
          )}
        </Container>
      </HeaderContainer>
      <Tab
        tabItens={[
          {
            component: data && <ApprovalFlowDetailsTab approvalFlow={data} />,
            label: t(`${context}.tabs.details`),
          },
        ]}
      ></Tab>
    </>
  );
}
