import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: ${({ theme }) => theme.borders.width.s} solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.s};
  gap: ${({ theme }) => theme.spacings.s};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacings.xs};
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.xs};
`;

export const StyledLinkButton = styled(LinkButton)`
  font-size: ${({ theme }) => theme.typography.caption.fontSize} !important;
`;
