import { trpc } from "@client";
import {
  LinkButton,
  ShapeIcon,
  Skeleton,
  Tab,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StatusTag } from "@molecules/status-tag";
import { DetailTab } from "@organisms/bill-details-tab";
import { useBillActions } from "@organisms/bills-list/hooks/useBillActions";
import { GridActions } from "@organisms/grid-actions";
import { PageContent } from "@organisms/page-content";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCategory } from "./hooks/useCategory";
import { RightButton } from "./rightButton";
import {
  ColumnContainer,
  Container,
  HeaderContainer,
  RejectContainer,
  TabContainer,
} from "./styled";

export function BillDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = trpc.bill.getById.useQuery(id || "", {
    refetchOnWindowFocus: false,
  });
  const { buildActions } = useBillActions();
  const { data: category, isLoading: categoryLoading } = useCategory(
    data?.categoryId
  );

  const context = "pages.billDetails";

  return (
    <PageContent
      rightButton={data && <RightButton bill={data} />}
      leftButton={
        <LinkButton variant="primary" onClick={() => navigate(-1)}>
          {t(`${context}.footer.back`)}
        </LinkButton>
      }
    >
      <>
        <HeaderContainer>
          {isLoading && (
            <>
              <Skeleton
                animation="pulse"
                variant="rounded"
                width={"100%"}
                height={"80px"}
              />
            </>
          )}
          {data && (
            <>
              <Container>
                <Container style={{ width: "100%" }}>
                  <div>
                    <Typography variant="headline6">
                      {data?.vendor.legalName}
                    </Typography>
                  </div>
                  <div>
                    <StatusTag status={data.status} />
                  </div>
                </Container>
                <ColumnContainer>
                  {categoryLoading ? (
                    <Skeleton
                      width={"150px"}
                      height={"10px"}
                      animation="wave"
                      variant="rounded"
                    />
                  ) : (
                    <Typography variant="body4">{category?.name}</Typography>
                  )}
                  {(data?.status === "REJECTED" ||
                    data.status === "ERROR_SUBMITTING_APPROVAL") && (
                    <RejectContainer>
                      <ShapeIcon
                        name="IconX"
                        variant="error"
                        stroke="error"
                        size={48}
                      />
                      <div>
                        <Typography variant="body3" weight={700}>
                          {t(
                            `${context}.${
                              data?.status === "REJECTED"
                                ? "rejectAlert"
                                : "errorAlert"
                            }`
                          )}
                        </Typography>
                        <Typography variant="body4">{data.comments}</Typography>
                      </div>
                    </RejectContainer>
                  )}
                </ColumnContainer>
              </Container>
              <Container>
                {data.status === "OPEN" && (
                  <GridActions
                    actions={buildActions(
                      data.id,
                      "page",
                      data.status,
                      data.createdBy,
                      data.transaction?.id
                    )}
                  />
                )}
              </Container>
            </>
          )}
        </HeaderContainer>
        <TabContainer>
          <Tab
            variant="primary"
            tabItens={[
              {
                label: t(`${context}.tabs.details`),
                component: data && <DetailTab bill={data} />,
              },
              // {
              //   label: t(`${context}.tabs.history`),
              //   component: <div />,
              // },
            ]}
          />
        </TabContainer>
      </>
    </PageContent>
  );
}
