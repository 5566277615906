import { accountTypesRecord } from "@common/account-type";
import { banksRecord } from "@common/banks";
import { paymentMethodRecord } from "@common/payment-methods";
import { pixKeyOptionsRecord } from "@common/pix-key-options";
import { TagProps } from "@flash-tecnologia/hros-web-ui-v2";
import { FieldProps } from "@molecules/Data-display";
import { useCategory } from "@organisms/bill-details/hooks/useCategory";
import { useCostCenter } from "@organisms/bill-details/hooks/useCostCenter";
import { formatDateTime } from "@utils/dateFormater";
import { toCurrency } from "@utils/toCurrency";
import { useTranslation } from "react-i18next";
import {
  Bill,
  PaymentMethod,
} from "server/src/services/company-payables-service/company-payables.types";

type TagStatus = {
  value: string;
  tagProps: {
    variant: TagProps["variant"];
  };
};
export function useBillFields(bill: Bill) {
  const { t } = useTranslation();
  const { data: category } = useCategory(bill?.categoryId);
  const { data: costCenter } = useCostCenter(bill?.costCenterId);

  const resolveTagStatus = (status: string): TagStatus => {
    switch (status) {
      case "OPEN":
        return {
          value: "Aberto",
          tagProps: {
            variant: "primary",
          },
        };
      case "PROCESSING_APPROVAL":
        return {
          value: "Processando aprovação",
          tagProps: {
            variant: "blue",
          },
        };
      case "AWAIT_APPROVAL":
        return {
          value: "Aprovação pendente",
          tagProps: {
            variant: "blue",
          },
        };
      case "PENDING":
        return {
          value: "Contabilização pendente",
          tagProps: {
            variant: "blue",
          },
        };
      case "PAID":
        return {
          value: "Contabilizado",
          tagProps: {
            variant: "success",
          },
        };
      case "REJECTED":
        return {
          value: "Reprovado",
          tagProps: {
            variant: "error",
          },
        };
      case "CANCELED":
        return {
          value: "Cancelado",
          tagProps: {
            variant: "error",
          },
        };
      case "ERROR_SUBMITTING_APPROVAL":
        return {
          value: "Erro ao submeter",
          tagProps: {
            variant: "error",
          },
        };
      default:
        return {
          value: "Aberto",
          tagProps: {
            variant: "primary",
          },
        };
    }
  };

  const context = "components.billDetailsTab.fields";

  const fields: FieldProps[] = [
    {
      label: t(`${context}.status`),
      ...resolveTagStatus(bill.status),
    },
    {
      label: t(`${context}.amount`),
      value: toCurrency(bill.amount),
    },
    {
      label: t(`${context}.vendor`),
      value: bill.vendor.legalName,
    },
    {
      label: t(`${context}.issueDate`),
      value: bill.issuedAt
        ? formatDateTime(new Date(bill.issuedAt), "date")
        : "",
    },
    {
      label: t(`${context}.documentNumber`),
      value: bill.document.number ? bill.document.number : "",
    },
    {
      label: t(`${context}.dueDate`),
      value: bill.dueAt ? formatDateTime(new Date(bill.dueAt), "date") : "",
    },
    {
      label: t(`${context}.category`),
      value: category?.name || "-",
    },
    {
      label: t(`${context}.costCenter`),
      value: costCenter?.name || "-",
    },
    {
      label: t(`${context}.description`),
      value: bill.description ? bill.description : "",
      expand: true,
    },
  ];

  const paymentFields: FieldProps[] = [];

  paymentFields.push({
    label: t(`${context}.paymentMethod`),
    value:
      bill.paymentTerms?.paymentMethod &&
      paymentMethodRecord[bill.paymentTerms?.paymentMethod].label,
  });

  switch (bill.paymentTerms?.paymentMethod) {
    case PaymentMethod.PIX:
      const pixFilds: FieldProps[] = [
        {
          label: t(`${context}.pixKeyType`),
          value:
            bill.paymentTerms.pixType &&
            pixKeyOptionsRecord[bill.paymentTerms.pixType].label,
        },
        {
          label:
            bill.paymentTerms.pixType &&
            pixKeyOptionsRecord[bill.paymentTerms.pixType].label,
          value: bill.paymentTerms.pixKey,
        },
      ];
      paymentFields.push(...pixFilds);
      break;
    case PaymentMethod.TRANSFER:
      const tranferFields: FieldProps[] = [
        {
          label: t(`${context}.bank`),
          value:
            bill.paymentTerms.bank && banksRecord[bill.paymentTerms.bank].label,
        },
        {
          label: t(`${context}.accountType`),
          value:
            bill.paymentTerms.accountType &&
            accountTypesRecord[bill.paymentTerms.accountType].label,
        },
        {
          label: t(`${context}.agency`),
          value: bill.paymentTerms.agency,
        },
        {
          label: t(`${context}.account`),
          value: bill.paymentTerms.account,
        },
      ];
      paymentFields.push(...tranferFields);
      break;
    default:
      break;
  }

  return { fields, paymentFields };
}
