export function transformToRecord(
  arr: Array<{ label: string; value: string | number }>
) {
  const record: Record<string, { label: string; value: string | number }> = {};
  return arr.reduce((obj, item) => {
    return {
      ...obj,
      [item.value]: item,
    };
  }, record);
}
