import { useTracking } from "@common/hooks/useTracking";
import { BillActionModalProvider } from "@organisms/bill-action-modal/billModalContext";
import { BillsList } from "@organisms/bills-list";
import { SelectedRowsProvider } from "@organisms/select-rows-context";

export function BillsTab() {
  const event = useTracking();

  event("payables_bill_tab");
  return (
    <BillActionModalProvider>
      <SelectedRowsProvider>
        <BillsList listContext="employee" />
      </SelectedRowsProvider>
    </BillActionModalProvider>
  );
}
